import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Socket, io } from 'socket.io-client';
import SearchInput from '../common/SearchInput';
import MultiRobotSkeleton from '../loading/skeleton/MultiRobotSkeleton';
import useRobotstore from '../../store/robotStore';
import useUserStore from '../../store/userStore';
import { multiRobotTaskType } from '../../_types';
import { SECOND_SOCKET_URL } from '../../_api/webSocket';
import { toast } from 'react-toastify';

const MultiRobotWork = () => {
  const { selectedUserAuthSite } = useUserStore();
  const {
    updateIsShowMultiRobotDetail,
    selectedTask,
    changeSelectedTaskDetailData,
    changeSelectedTask,
  } = useRobotstore();

  const [taskData, setTaskData] = useState<multiRobotTaskType[] | null>(null);
  const [selectedSite, setSelectedSite] = useState<string>('');

  const multiRobotTaskSocket = useRef<Socket | null>(null);
  const currentSite = useRef<string | null>(null);

  useEffect(() => {
    if (currentSite.current !== selectedSite) {
      setTaskData(null);
      currentSite.current = selectedSite;
    }

    multiRobotTaskSocket.current?.off('tasks');

    if (selectedSite) {
      multiRobotTaskSocket.current?.emit('sites', [selectedSite]);
    }
    if (selectedSite === '' && selectedUserAuthSite.length > 1) {
      multiRobotTaskSocket.current?.emit('sites', selectedUserAuthSite);
    }

    multiRobotTaskSocket.current?.on('tasks', (data: multiRobotTaskType[]) => {
      setTaskData(data);

      if (selectedTask) {
        changeSelectedTaskDetailData(
          data.filter((item) => item.taskId === selectedTask)[0],
        );
      }
    });
  }, [selectedSite, selectedTask, selectedUserAuthSite]);

  useEffect(() => {
    multiRobotTaskSocket.current = io(`${SECOND_SOCKET_URL}/tasks`, {
      transports: ['websocket'],
      upgrade: true,
    });

    multiRobotTaskSocket.current.on('connect', () => {
      console.log('task data connect');
    });

    multiRobotTaskSocket.current.on('connect_error', (err) => {
      console.error('socket connection error : ', err);
      toast.error('데이터 연결에 문제가 발생했습니다.');
    });

    return () => {
      multiRobotTaskSocket.current?.disconnect();
      multiRobotTaskSocket.current?.off('tasks');
    };
  }, []);

  return (
    <>
      <section
        className="flex items-center justify-between gap-5 pb-3"
        data-name="bg"
      >
        <SearchInput handleChangeSite={setSelectedSite} />

        <div className="flex justify-end w-full gap-2 pr-10 text-xs">
          <span>{t('last_update')} </span>
          <span>{dayjs(new Date()).format('MM-DD HH:mm:ss')}</span>
        </div>
      </section>

      <div className="pt-3 pb-7">
        <div className="text-center text-sm w-[98%]">
          <div className="flex items-center font-bold text-center shadow-sm bg-neutral-200 rounded-xl">
            <div className="py-3 w-[15%]">{t('task_name')}</div>
            <div className="py-3 w-[50%]">{t('Work_Detail')}</div>
            <div className="px-2 py-3 w-[15%]">{t('Work_Status')}</div>
            <div className="py-3 w-[20%]">{t('Put_Robot')}</div>
          </div>

          {taskData !== null ? (
            taskData.length > 0 ? (
              <div>
                {taskData?.map((item: multiRobotTaskType, idx: number) => (
                  <div
                    key={idx}
                    onClick={() => {
                      updateIsShowMultiRobotDetail(true);
                      changeSelectedTask(item.taskId);
                    }}
                    data-name="list"
                    className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] ${
                      localStorage.login === 'true' &&
                      localStorage.verified === 'true'
                        ? 'justify-center mt-1 rounded-xl shadow-sm'
                        : 'blur-sm '
                    }`}
                  >
                    <div className="py-3 w-[15%]">{item.taskName}</div>
                    <div className="py-3 w-[50%]">{item.description}</div>
                    <div className="py-3 w-[15%]">
                      <span
                        className={`inline-block min-w-[52px] h-[32px] px-3 leading-8 rounded-full font-bold ${item.taskMainState === 'TASK_POSSIBLE' ? 'bg-[#CBF2E5] text-[#26BFA1]' : item.taskMainState === 'TASK_IMPOSSIBLE' ? 'bg-[#FFEAE6] text-[#FF3029]' : 'bg-white text-[#26BFA1] border border-[#26BFA1]'}`}
                      >
                        {item.taskMainStateAlias}
                      </span>
                    </div>
                    <div className="py-3 w-[20%]">
                      {item.robotList.length > 0
                        ? item.robotList.map((robot) => robot.name).join(', ')
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  fontSize: '60px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontFamily: 'SUIT',
                  height: '400px',
                  lineHeight: 5,
                  color: '#e0e0e0',
                }}
              >
                NO DATA
              </div>
            )
          ) : (
            <MultiRobotSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default MultiRobotWork;
