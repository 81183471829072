import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
// import { EngineGifWrapper, EngineModalBadge, EngineModalDesc, EngineModalDescArea, GifWrapper, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper, PlatformImg, PlatformImgArea, PlatformImgSecond, PlatformModalBadge, PlatformModalBottom, PlatformModalDesc, PlatformModalDescArea } from './styles';
import { FaArrowLeft, FaYoutube } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import engine_page1 from '../../../assets/introduce/Image/engine_page1.gif'
import engine_page2 from '../../../assets/introduce/Image/engine_page2.gif'
import { Icon } from '../../../pages/IntroducePage';
import enginePage1_title from '../../../assets/introduce/Image/Modal/Engine/enginePage1_title.png'
import enginePage1_desc from '../../../assets/introduce/Image/Modal/Engine/enginePage1_desc.png'
import enginePage2_title from '../../../assets/introduce/Image/Modal/Engine/enginePage2_title.png'
import enginePage2_desc from '../../../assets/introduce/Image/Modal/Engine/enginePage2_desc.png'
import enginePage2_subTitle from '../../../assets/introduce/Image/Modal/Engine/enginePage2_subTitle.png'
import engineModalBg from '../../../assets/introduce/Image/engineModalBg.png'
import builderModalBg from '../../assets/introduce/Image/builderModalBg.png'
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {MobileEngineModal} from "./Mobile/MobileEngineModal";

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ModalBackBtn className="next" onClick={onClick}>
        <FaChevronRight className={'CFaChevronRight'} />
      </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ModalBackBtn className="prev" onClick={onClick}>
        <FaChevronLeft className={'CFaChevronLeft'} />
      </ModalBackBtn>
  );
}

export const EngineModal = ({ setClicked }: {setClicked: Function}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
      <>
        {isMobile ?
            <MobileEngineModal setClicked={setClicked} />
            :
            <ModalWrapper className="slider-container">
              <ModalProgressBar
                  onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
                  value={slideIndex}
                  type="range"
                  min={0}
                  max={1}
              />
              <LinkerModalContent className='engine'>
                <ModalHeader>
                  <ModalBackBtn onClick={() => setClicked('')}>
                    <FaArrowLeft className={'CFaArrowLeft'}/>
                  </ModalBackBtn>
                </ModalHeader>
                <Slider
                    ref={sliderRef}
                    {...settings}
                >
                  <LinkerModalDisplay>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='engine_page1'>
                        <EngineModalBadge>Workflow Engine</EngineModalBadge>
                        <EngineTitleImg src={enginePage1_title} />
                      </LinkerModalTitle>
                      <LinkerModalDesc className='engine_page1'>
                        <EngineDescImg src={enginePage1_desc} />
                      </LinkerModalDesc>
                    </LinkerModalTitleArea>
                    <EngineGifWrapper className='engine_page1' style={{bottom: 0, right: 0}}>
                      <EngineGIF src={engine_page1} />
                    </EngineGifWrapper>
                  </LinkerModalDisplay>

                  <LinkerModalDisplay>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='engine_page2'>
                        <EngineModalBadge>Workflow Engine</EngineModalBadge>
                        <EngineTitleTwoImg src={enginePage2_title} />
                      </LinkerModalTitle>
                      <LinkerModalDesc className='engine_page2'>
                        <EngineDescTwoImg src={enginePage2_desc} />
                      </LinkerModalDesc>
                    </LinkerModalTitleArea>
                    <EngineModalDescArea>
                      <EngineSubTitleTwoImg src={enginePage2_subTitle} />
                    </EngineModalDescArea>
                    <EngineGifWrapper className='engine_page2'>
                      <EngineTwoGIF src={engine_page2} />
                      <a href='https://www.youtube.com/watch?v=u5c78VVjCEc' target='blank'><Icon><FaYoutube /></Icon>Workflow Engine 기능 상세보기</a>
                    </EngineGifWrapper>
                  </LinkerModalDisplay>
                </Slider>
              </LinkerModalContent>
            </ModalWrapper>
        }
      </>

  );
}

export default React.memo(EngineModal)

const EngineTitleImg = styled.img`
  margin-top: 30px;
  margin-bottom: 46px;
  width: 807px;

  @media (max-width: 1920px) {
    margin-top: 1.56vw;
    margin-bottom: 2.40vw;
    width: 42.03vw;
  }
`

const EngineDescImg = styled.img`
  width: 697px;

  @media (max-width: 1920px) {
    width: 36.35vw;
  }
`

const EngineGIF = styled.img`
  width: 790px;
  position: relative;
  top: -93.63px;

  @media (max-width: 1920px) {
    width: 41.14vw;
    top: -4.87vw;
  }
`


const EngineTitleTwoImg = styled.img`
  margin-top: 30px;
  margin-bottom: 46px;
  width: 582px;

  @media (max-width: 1920px) {
    margin-top: 1.56vw;
    margin-bottom: 2.40vw;
    width: 30.31vw;

  }
`

const EngineDescTwoImg = styled.img`
  width: 1334px;
  margin-bottom: 46px;
  
  @media (max-width: 1920px) {
    width: 69.79vw;
    margin-bottom: 2.40vw;
  }
`

const EngineSubTitleTwoImg = styled.img`
  width: 544px;
  @media (max-width: 1920px) {
    width: 28.33vw;
  }
`

const EngineTwoGIF = styled.img`
  width: 790px;

  @media (max-width: 1920px) {
    width: 41.14vw;
  }
`


const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 66px;
  right: 200px;
  z-index: 9999;
  width: 100px;
  height: 17px;

  @media (max-width: 1920px) {
    top: 3.44vw;
    right: 10.42vw;
    width: 5.21vw;
    height: 0.89vw;
  }
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;

  @media (max-width: 1920px) {
    width: 2.604vw;
    height: 2.604vw;
  }

  &.next {
    top: -43px;
    right: 0;

    @media (max-width: 1920px) {
      top: -2.2396vw;
    }
  }

  &.prev {
    top: -43px;
    right: 60px;

    @media (max-width: 1920px) {
      top: -2.2396vw;
      right: 3.125vw;
    }
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 50px 68px;
  display: flex !important;
  flex-direction: column;
  gap: 45px;
  background-color: #000;

  @media (max-width: 1920px) {
    gap: 2.34vw;
    padding: 2.604vw 3.542vw;
  }

  &.engine {
    background-image: url(${engineModalBg});
    background-color: #000;
  }
`

const LinkerModalDisplay = styled.div`
  //display: flex !important;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: space-between;
  //height: calc(100vh - 170px);
  //padding: 0px 80px;
  //padding-bottom: 128px;
  //position: relative;
  //@media (max-width: 1250px) {
  //  padding: 0px 20px;
  //}
  //@media (max-width: 450px) {
  //  padding: 0px;
  //}
`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-left: 80.33px;
  margin-top: 33.85px;

  @media (max-width: 1920px) {
    margin-left: 4.1802vw;
    margin-top: 3.1296vh;
  }
`

const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }
`


const EngineModalBadge = styled.div`
  width: 239px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.56px;

  @media (max-width: 1920px) {
    width: 12.45vw;
    height: 2.29vw;
    border: 0.03vw solid var(rgba(255, 255, 255, 0.20));
    text-shadow: 0px 0px 2.08vw #FFF;
    font-size: 1.46vw;
    letter-spacing: -0.03vw;

  }
`

const EngineGifWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 20px;
  position: relative;

  @media (max-width: 1920px) {
    padding-right: 1.04vw;
  }
  
  &.engine_page2{
    top: -110px;

    @media (max-width: 1920px) {
      top: -5.73vw;
    }
  }
 
  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    line-height: 140%;
    

    @media (max-width: 1920px) {
      gap: 0.31vw;
      font-size: 0.94vw;
    }
  }
  
  
`
const EngineModalDescArea = styled.div`
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }
 
`
