import styled, { keyframes } from "styled-components";

export const ExpansionWrapper = styled.div`
  width: 100%;
  /* height: 1318px; */
  background-color: #fff;
  min-width: 350px;
  overflow: hidden;
`
export const ExpansionDisplay = styled.div`
  width: 100%;
  background-color: #18A98E;
  border-radius: 240px 0 240px 0;
  padding-top: 173px;
  padding-bottom: 173px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 112px;
  margin-top: 238px;
  @media (max-width: 1400px) {
    border-radius: 200px 0 200px 0;
  }
  @media (max-width: 1200px) {
    border-radius: 180px 0 180px 0;
    padding-top: 123px;
  }
  @media (max-width: 1024px) {
    border-radius: 150px 0 150px 0;
    margin-top: 128px;
  }
  @media (max-width: 888px) {
    border-radius: 120px 0 120px 0;
  }
  @media (max-width: 650px) {
    gap: 72px;
    margin-top: 78px;
  }
  @media (max-width: 500px) {
    gap: 32px;
    border-radius: 100px 0 100px 0;
  }
`
export const ExpansionTxt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  font-family: Pretendard;
  p {
    font-family: 'Pretendard';
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1200px) {
    font-size: 38px;
  }
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 888px) {
    font-size: 28px;
  }
  @media (max-width: 650px) {
    font-size: 24px;
  }
  @media (max-width: 550px) {
    text-align: center;
  }
`
export const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  position: relative;
  @media (max-width: 650px) {
    gap: 34px;
  }
`
export const Graph = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 115px;
  width: 954px;
  @media (max-width: 1200px) {
    width: 854px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`
export const GraphTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  gap: 8px;
  width: 260px;
  height: 100%;
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.20);
  span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    width: 220px;
    font-size: 28px;
  }
  @media (max-width: 950px) {
    font-size: 24px;
    span {
      font-size: 15px;
    }
  }
  @media (max-width: 888px) {
    font-size: 22px;
  }
  @media (max-width: 800px) {
    text-align: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    gap: 2px;
    span {
      font-size: 7.8px;
    }
  }
`
export const GraphContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const animationShort = keyframes`
  from {
    width: 664px;
  }
  to {
    width: 152px;
  }
`;
const animationShort950 = keyframes`
  from {
    width: 464px;
  }
  to {
    width: 132px;
  }
`;
const animationShort500 = keyframes`
  from {
    width: 320px;
  }
  to {
    width: 73px;
  }
`;
const animationLong = keyframes`
  from {
    width: 664px;
  }
  to {
    width: 206px;
  }
`;
const animationLong950 = keyframes`
  from {
    width: 464px;
  }
  to {
    width: 186px;
  }
`;
const animationLong500 = keyframes`
  from {
    width: 320px;
  }
  to {
    width: 100px;
  }
`;
export const GraphContentTop = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.20) 100%);
  span {
    width: 206px;
    height: 60px;
    background-color: #33FF6C;
    border-radius: 50px;
    padding-right: 34px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 24px;
    font-weight: 700;
    justify-content: flex-end;
    &.active {
      animation: ${animationLong} 2s ease forwards;
    }
    &.short {
      width: 152px;
      &.active {
        animation: ${animationShort} 2s ease forwards;
      }
    }
  }
  @media (max-width: 950px) {
    span {
      width: 186px;
      height: 50px;
      font-size: 18px;
      &.active {
        animation: ${animationLong950} 2s ease forwards;
      }
      &.short {
        width: 132px;
        &.active {
          animation: ${animationShort950} 2s ease forwards;
        }
      }
    }
  }
  @media (max-width: 500px) {
    span {
      width: 100px;
      height: 28px;
      font-size: 12px;
      &.active {
        animation: ${animationLong500} 2s ease forwards;
      }
      &.short {
        width: 73px;
        &.active {
          animation: ${animationShort500} 2s ease forwards;
        }
      }
    }
  }
`
export const GraphContentBottom = styled.span`
  width: 664px;
  height: 40px;
  padding-right: 34px;
  background-color: #ECECEC;
  border-radius: 50px;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 950px) {
    width: 464px;
    height: 30px;
    font-size: 16px;
  }
  @media (max-width: 500px) {
    width: 320px;
    height: 20px;
    font-size: 10px;
  }
`
export const GraphBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 26px;
  right: 0;
  bottom: -82px;
  @media (max-width: 950px) {
    right: 150px;
  }
  @media (max-width: 800px) {
    right: 50%;
    transform: translateX(50%);
  }
`
export const GraphBadgeType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2F2F2F;
  font-size: 20px;
  font-weight: 400;
  span {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
  @media (max-width: 950px) {
    font-size: 16px;
    span {
      width: 16px;
      height: 16px;
    }
  }
  @media (max-width: 500px) {
    font-size: 12px;
    span {
      width: 12px;
      height: 12px;
    }
  }
`