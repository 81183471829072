import { Spin } from 'antd';

interface Props {
  loading: boolean;
}

export const FullSpinner = ({ loading }: Props) => {
  return (
    <Spin spinning={loading} fullscreen tip={'Loading...'} size={'large'} />
  );
};
