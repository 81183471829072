import { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import "../style/animation.css";

import useMenustore from "../store/menuStore";
import useUserStore from "../store/userStore";
import { Permission } from "../_types/user";

import MapCardMenu from "../components/map/content/MapCardMenu";
import Gnb from "../components/layout/Gnb";
import Map from "../components/map/content/Map";

export default function StreamviewPage() {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menu2Ref = useRef<HTMLDivElement | null>(null);

  const { isShowMapMenu2, updateIsShowMapMenu2 } = useMenustore();
  const { updateIsShowUserIcon, userStatus } = useUserStore();

  const [menu1Visible, setMenu1Visible] = useState(true);

  const handleMenu1 = () => {
    if (menu1Visible) {
      menuRef.current?.classList.add("card-menu-off");
      menuRef.current?.classList.remove("card-menu-on");
    } else if (!menu1Visible) {
      menuRef.current?.classList.add("card-menu-on");
      menuRef.current?.classList.remove("card-menu-off");
    }
    setMenu1Visible(!menu1Visible);
    updateIsShowMapMenu2(false);
  };

  if (
    userStatus !== Permission.SUPER_ADMIN &&
    userStatus !== Permission.ENGINEER
  ) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="relative flex" onClick={() => updateIsShowUserIcon(false)}>
      <div
        ref={menuRef}
        style={{ width: "380px" }}
        className="z-10 fixed flex ml-[75px]"
      >
        <div className="z-30 w-[380px] h-[100vh] bg-white flex flex-col items-center p-5 pt-3 border-r">
          <MapCardMenu />
        </div>
        <div
          ref={menu2Ref}
          style={{
            transform: isShowMapMenu2 ? "translateX(93%)" : "translateX(-10%)",
            transition: "all 0.5s ease-in-out",
          }}
          className="fixed flex"
        >
          <div className="z-30 w-[380px]">
            <div className="w-[380px]">{/* <Menu2 /> */}</div>
          </div>
          <div>
            <div
              onClick={handleMenu1}
              className={
                isShowMapMenu2
                  ? "self-start p-2 bg-white border-r border-y hover:cursor-pointer my-2"
                  : "hidden"
              }
            >
              {menu1Visible ? "〈" : "〉"}
            </div>
          </div>
        </div>
        <div
          onClick={handleMenu1}
          className={
            isShowMapMenu2
              ? "hidden"
              : "self-start p-2 bg-white hover:cursor-pointer my-2 rounded-r-sm"
          }
        >
          {menu1Visible ? "〈" : "〉"}
        </div>
      </div>

      <div className="fixed z-10">
        <Gnb />
      </div>
      <div className="pl-[75px] w-[100%]">
        <Map />
      </div>
    </div>
  );
}
