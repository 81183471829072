import { doc, getDoc } from "firebase/firestore";
import { FirestoreCollenctions } from "../../_types";
import { db } from "../../config/firebase";

export async function getListByReference<T>(data: string[]) {
  try {
    const result = data.map(async (item) => {
      const docRef = doc(db, FirestoreCollenctions.SITES, item as any);
      const dataDoc = await getDoc(docRef);
      if (dataDoc.exists()) {
        return { id: dataDoc.id, ...dataDoc.data() } as T;
      }
    });

    return Promise.all(result);
  } catch (error) {
    throw new Error("오류가 발생했습니다.");
  }
}

export async function getDocData<T>(
  data: [],
  collection: FirestoreCollenctions
) {
  try {
    const result = data.map(async (item) => {
      const docRef = doc(db, collection, item as any);
      const dataDoc = await getDoc(docRef);
      if (dataDoc.exists()) {
        return { id: dataDoc.id, ...dataDoc.data() } as T;
      }
    });

    return Promise.all(result);
  } catch (error) {
    throw new Error("오류가 발생했습니다.");
  }
}
