import * as XLSX from 'xlsx';

export const excelDownload = (data: any, fileName: string) => {
    // console.log('data = ', data)
    const excelHandler = {
        getSheetName: () => {
            return 'originResults';
        },
        getExcelData: () => {
            return data;
        },
        getWorksheet: () => {
            return XLSX.utils.json_to_sheet(excelHandler.getExcelData());
        }
    };

    const datas = excelHandler.getWorksheet();
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, excelHandler.getSheetName());
    XLSX.writeFile(workbook, fileName);

};

export const excelDataFormat = (data: any) => {

    if(data.constructor === Array) {

        data.forEach((item: any, i: number) => {
            for (const [key, value] of Object.entries(item)) {
                console.log(i, item[key].toString() )

                item[key] = JSON.stringify(item[key]);
            }
        })

        console.log('excelDataFormat= ', data)

        return data;
    }else{
        return;
    }

}