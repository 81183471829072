import styled from "styled-components";

export const DiscontentWrapper = styled.div`
  width: 100vw;
  /* height: 1154px; */
  display: flex;
  flex-direction: column;
  gap: 110px;
  align-items: center;
  background-color: #F7F6FB;
  padding-top: 175px;
  padding-bottom: 59px;
  min-width: 350px;
  overflow: hidden;
  @media (max-width: 800px) {
    padding-top: 93px;
  }
  @media (max-width: 450px) {
    padding-top: 63px;
  }
`
export const DiscontentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`
export const DiscontentTitleTop = styled.p`
  color: #333;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  span {
    color: #21a188;
    font-weight: 800;
  }
  @media (max-width: 800px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    font-weight: 800;
  }
  @media (max-width: 450px) {
    line-height: 28px;
  }
`
export const DiscontentTitleBot = styled.p`
  color: #111;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  span {
    color: #333;
    font-weight: 700;
  }
  @media (max-width: 800px) {
    font-size: 32px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 35px;
  }
  @media (max-width: 450px) {
    line-height: 28px;
    font-size: 20px;
  }
`
export const DiscontentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 84px;
  margin-top: 130px;
  @media (max-width: 1830px) {
    margin-top: 70px;
  }
  @media (max-width: 1350px) {
    margin-top: 40px;
  }
  @media (max-width: 800px) {
    gap: 70px;
    margin-top: 70px;
  }
  @media (max-width: 500px) {
    margin-top: -30px;
  }
`
export const DiscontentContentTop = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 70px;
  }
`
export const DiscontentContentBot = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 70px;
  }
`
export const DiscontentContent = styled.div`
  border-radius: 180px;
  border: 0.903px solid #E8E8E8;
  background: #FFF;
  width: 479px;
  height: 136px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  padding: 13px 14px;
  position: relative;
  text-align: center;
  @media (max-width: 1830px) {
    width: 412px;
    height: 150px;
  }
  @media (max-width: 1350px) {
    width: 312px;
    height: 120px;
  }
  @media (max-width: 1024px) {
    width: 232px;
    height: 100px;
  }
  @media (max-width: 800px) {
    width: 571px;
    height: 163px;
    border-radius: 98px;
  }
  @media (max-width: 650px) {
    width: 412px;
    height: 150px;
  }
  @media (max-width: 500px) {
    width: 312px;
    height: 89px;
  }
`
export const DiscontentContentImg = styled.div`
  display: flex;
  width: 86px;
  height: 86px;
  padding: 6.533px;
  justify-content: center;
  align-items: center;
  gap: 6.533px;
  flex-shrink: 0;
  border-radius: 326.667px;
  border: 0.653px solid #197664;
  background: #258775;
  box-shadow: 0px 0px 13.067px 0px rgba(13, 13, 13, 0.25) inset;
  position: absolute;
  top: -60px;
  @media (max-width: 1350px) {
    width: 60px;
    height: 60px;
    top: -35px;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  @media (max-width: 800px) {
    width: 92px;
    height: 92px;
    top: -50px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
  @media (max-width: 650px) {
    width: 80px;
    height: 80px;
    top: -50px;
    img {
      width: 46px;
      height: 46px;
      object-fit: contain;
    }
  }
  @media (max-width: 500px) {
    width: 50px;
    height: 50px;
    top: -30px;
    img {
      width: 27px;
      height: 27px;
      object-fit: contain;
    }
  }
`
export const DiscontentContentTxtArea = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const DiscontentContentTxt = styled.div`
  text-align: center;
  font-size: 20px;
  color: #0d0d0d;
  font-weight: 400;
  line-height: 30px;
  span {
    color: #126252;
    font-size: 23px;
    font-weight: 800;
  }
  @media (max-width: 1350px) {
    font-size: 16px;
    span {
      font-size: 18px;
    }
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 30px;
    span {
      font-size: 23px;
    }
  }
  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 20px;
    span {
      font-size: 16px;
    }
  }
`