import '../../style/animation.css';
import EVDetail from './EVcontent/EVDetail';
import { DeviceDataType } from '../../_types';
import { useEffect, useRef, useState } from 'react';
import useRobotstore from '../../store/robotStore';

import useDataStore from '../../store/dataStore';
import DetailError from '../error/DetailError';
import { ErrorBoundary } from 'react-error-boundary';
import CommonDetail from './content/CommonDetail';

type PropsType = {
  socketData: DeviceDataType[];
};

export default function Detail({ socketData }: PropsType) {
  const btnRef = useRef<HTMLInputElement>(null);
  const { isShowRobotDetail, selectedRobot } = useRobotstore();
  const { updateRobotDetailData } = useDataStore();
  // 디테일 페이지에 표시되는 로봇 정보 담는 객체, 웹소켓 데이터 타입 지정 필요
  const [robotDetailData, setRobotDetailData] = useState<DeviceDataType | null>(
    null,
  );

  useEffect(() => {
    if (socketData.length !== 0) {
      const tempSelectedRobot = socketData.find(
        (item: DeviceDataType) => item.deviceId === selectedRobot,
      );

      setRobotDetailData(tempSelectedRobot as DeviceDataType);
      updateRobotDetailData(robotDetailData);
    }
  }, [socketData, selectedRobot]);

  const style = !selectedRobot
    ? 'w-[0px]'
    : `z-[100] fixed right-0 w-[650px] h-[100vh] p-6 px-7 bg-white flex flex-col drop-shadow overflow-scroll ${
        isShowRobotDetail ? 'robot-menu-on' : 'robot-menu-off'
      } `;

  return (
    <div className={style} data-name="detail" ref={btnRef}>
      {robotDetailData && robotDetailData.robotStateColor ? (
        <ErrorBoundary FallbackComponent={DetailError}>
          {robotDetailData.modelTypeEn === 'ELEVATOR' ? (
            <EVDetail socketData={socketData} />
          ) : (
            <CommonDetail socketData={socketData} />
          )}
        </ErrorBoundary>
      ) : null}
    </div>
  );
}
