import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

import useAuth from '../hooks/useAuth';
import usePeriod, { dateFormat, getToday } from '../hooks/usePeriod';

import InquiryModal from '../components/modal/InquiryModal';
import ChartContainer from '../components/dashboard/ChartContainer';
import { PeriodType } from '../_types/dashBoard';
import SearchInput from '../components/common/SearchInput';

export default function DashboardPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const { startDate, endDate } = usePeriod();

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: dateFormat(lastMonth),
    endDate: getToday(),
  });

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [startDate, endDate]);

  const handleChangeDate = (newValue: DateValueType) => {
    navigate(
      `/dashboard?start=${newValue?.startDate}&end=${newValue?.endDate}`,
    );
  };

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('dashboard')}
      </div>

      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        <SearchInput viewOption={'all'} />

        <div className="pr-12 min-w-[300px]">
          <Datepicker
            value={period}
            inputClassName={
              'w-full  py-2 px-3 text-sm focus:bg-[#ebf7f6] placeholder:text-sm rounded-lg sm:text-sm shadow-sm '
            }
            placeholder={t('please_select_a_period')}
            primaryColor={'teal'}
            useRange={false}
            showShortcuts={true}
            onChange={handleChangeDate}
            i18n={`${i18next.resolvedLanguage}`}
            dateLooking="middle"
            configs={{
              shortcuts: {
                today: `${t('today')}`,
                yesterday: `${t('yesterday')}`,
                currentMonth: `${t('currentMonth')}`,
                pastMonth: `${t('pastMonth')}`,
                past: (period) => `${t('the_last')} ${period}${t('days')}`,
              },
            }}
          />
        </div>
      </section>
      <ChartContainer period={period} />
    </div>
  );
}
