import React from 'react'
import { PolygonFigure, PolygonWrapper } from './styles';

export const Polygon = () => {
  return (
    <PolygonWrapper>
      <PolygonFigure />
    </PolygonWrapper>
  )
}

export default React.memo(Polygon);