import { Button } from 'antd';
import { BiFullscreen } from 'react-icons/bi';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useControls } from 'react-zoom-pan-pinch';

const MapControls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <div className="flex justify-between">
      <Button onClick={() => zoomOut()}>
        <FaMinus />
      </Button>
      <Button onClick={() => resetTransform()}>
        <BiFullscreen />
      </Button>
      <Button onClick={() => zoomIn()}>
        <FaPlus />
      </Button>
    </div>
  );
};

export default MapControls;
