import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";
import { INQUIRY_LOG } from "../constants/logEvent";

const onClickApplyMemo = () => {
  const isMobile = /iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent);

  logEvent(
    analytics,
    !isMobile
      ? INQUIRY_LOG.P_INQUIRY_SOLLINK_CLICK
      : INQUIRY_LOG.M_INQUIRY_SOLLINK_CLICK
  );
  window.location.href = "https://www.myrobotsolution.com/inquiry/sollink";
};

export { onClickApplyMemo };
