import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';

import { APP_BUILD_NUMBER, APP_VERSION } from '../../constants';
import { auth } from '../../config/firebase';
import useLoginStore from '../../store/loginStore';
import useUserStore from '../../store/userStore';
import useUserData from '../../hooks/useUserData';

import account from '../../assets/icon/GNB_account.svg';
import logout from '../../assets/image/logout.svg';

export default function UserIcon() {
  const modalRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  const { isShowUserIcon, updateIsShowUserIcon } = useUserStore();
  const { updateIsLoggedIn, changeUserInfo, updateIsShowLoginModal } =
    useLoginStore();

  const { user } = useUserData();

  // 외부 영역 클릭시 닫힘
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        updateIsShowUserIcon(false);
      }
    };

    window.addEventListener('mousedown', handleClick);

    return () => window.removeEventListener('mousedown', handleClick);
  }, [modalRef, updateIsShowUserIcon]);

  function onClickLogout() {
    signOut(auth)
      .then(() => {
        localStorage.setItem('login', 'false');
        localStorage.removeItem('recentSite');
        updateIsLoggedIn(false);
        changeUserInfo(null);
        updateIsShowUserIcon(false);

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (
    pathname !== '/signup' &&
    pathname !== '/verification' &&
    pathname !== '/userpage'
  ) {
    return (
      <div
        className="w-[100%] h-[100%] relative"
        onClick={(event: any) => {
          if (event.target.dataset.name === 'bg') {
            updateIsShowUserIcon(false);
          }
        }}
      >
        <div
          className="fixed right-0 z-[100] flex flex-col items-end p-5 "
          ref={modalRef}
          data-name="bg"
        >
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center justify-end pr-2 text-xs">
              <div className="mr-2 font-bold">{t('language')}</div>
              <select
                className="underline bg-transparent rounded-md "
                value={i18n.resolvedLanguage === 'ko' ? '한국어' : 'English'}
                onChange={(event) => {
                  if (event.target.value === '한국어') {
                    i18n.changeLanguage('ko');
                  } else if (event.target.value === 'English') {
                    i18n.changeLanguage('en');
                  }
                }}
              >
                <option value="한국어">한국어</option>
                <option value="English">English</option>
              </select>
            </div>
            <div className="flex flex-col">
              {localStorage.login === 'true' ? (
                <div
                  className=" w-[45px]  h-[45px] bg-primary rounded-full text-sm text-white flex items-center justify-center hover:cursor-pointer"
                  onClick={() => updateIsShowUserIcon(!isShowUserIcon)}
                >
                  {/* TODO: ICON 이름 글자수 논의 */}
                  {user ? user.display_name?.substring(1) : ''}
                </div>
              ) : (
                <div>
                  <button
                    onClick={() => {
                      updateIsShowLoginModal(true);
                    }}
                    className="px-4 py-2 text-sm text-white bg-primary rounded-xl"
                  >
                    {t('sign_in')}
                  </button>
                </div>
              )}

              {isShowUserIcon && (
                <div className="fixed w-[320px] right-[20px] top-[70px] bg-gray-100 mt-2 mr-1 rounded-xl drop-shadow p-6">
                  <div className="text-lg">
                    {t('greet')}, {user ? user.display_name?.substring(1) : ''}
                    님!
                  </div>

                  <div className="text-sm text-gray-500">
                    {user ? user.email : ''}
                  </div>

                  <Link
                    to="/userpage"
                    className="bg-white w-[100%] rounded-3xl mt-3 mb-2 py-3 px-4 text-sm flex items-center hover:cursor-pointer hover:bg-gray-200"
                  >
                    <img
                      src={account}
                      className="h-[1.2rem] mr-2"
                      alt="account"
                    />
                    <div>{t('account')}</div>
                  </Link>

                  <div
                    className="bg-white w-[100%] rounded-3xl my-2 py-3 px-4 text-sm flex items-center hover:cursor-pointer hover:bg-gray-200"
                    onClick={onClickLogout}
                  >
                    <img
                      src={logout}
                      className="h-[1.2rem] mr-2"
                      alt="logout"
                    />
                    <div>{t('sign_out')}</div>
                  </div>

                  <div className="mt-8 text-center">
                    <p className="text-xs text-gray-400">
                      version {APP_VERSION} (
                      {APP_BUILD_NUMBER
                        ? APP_BUILD_NUMBER.padStart(4, '0')
                        : ''}
                      )
                    </p>

                    <p className="text-xs opacity-80 text-primary">
                      ©Bigwave Robotics Inc. All Rights Reserved.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
