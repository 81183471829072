import React from 'react';

export interface TitleWidgetProps {
  fontSize: number;
  title: string;
  fontColor?: string;
  icon?: React.ComponentType<any>; // icon prop을 위한 타입
  iconSize?: number;
  iconMarginR?: number;
  iconMarginL?: number;
  iconPosition?: string;
  height?: number;
  width?: number;
  padding?: string;
  useLink: boolean;
  linkAddress?: string;
}

export const TitleWidget = ({
  fontSize,
  fontColor,
  title,
  icon: Icon, // icon prop을 받아옴
  iconSize,
  iconMarginR,
  iconMarginL,
  iconPosition,
  height,
  width,
  padding,
  useLink,
  linkAddress,
}: TitleWidgetProps) => {
  return (
    <a
      {...(useLink && { href: linkAddress })}
      style={{ textDecoration: 'none' }}
    >
      <div
        style={{
          fontSize,
          color: fontColor,
          height,
          width,
          padding: padding,
        }}
        className="flex items-center"
      >
        {iconPosition === 'left' && (
          <span className={'contents content-center text-center'}>
            {Icon && <Icon size={iconSize} />}{' '}
          </span>
        )}
        <span style={{ marginRight: iconMarginR, marginLeft: iconMarginL }}>
          {title}
        </span>
        {iconPosition === 'right' && (
          <span className={'contents content-center text-center'}>
            {Icon && <Icon size={iconSize} />}{' '}
          </span>
        )}
      </div>
    </a>
  );
};
