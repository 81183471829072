import { create } from 'zustand';

type ModalType = 'common' | 'warning' | 'info';
interface ModalStore {
  type: ModalType;
  visible: boolean;
  isShowUserModal: boolean;
  title: string | null;
  contents: React.ReactNode | null;
  okText?: string;
  onClickOk?: (() => void) | null;
  cancelText?: string;
  openModal: (data: {
    type?: ModalType;
    okText?: string;
    cancelText?: string;
    title: string | null;
    contents: React.ReactNode | null;
    onClickOk?: (() => void) | null;
  }) => void;
  closeModal: () => void;
  updateIsShowUserModal: (input: boolean) => void;
}

const useModalstore = create<ModalStore>()((set) => ({
  type: 'common',
  visible: false,
  isShowUserModal: false,
  title: null,
  contents: null,
  cancelText: '',
  okText: '',
  onClickOk: null,
  openModal: (data) =>
    set((state) => ({
      type: data.type ? data.type : 'common',
      okText: data.okText,
      cancelText: data.cancelText,
      visible: true,
      title: data.title,
      contents: data.contents,
      onClickOk: data.onClickOk,
    })),
  closeModal: () =>
    set((state) => ({
      visible: false,
      title: null,
      contents: null,
    })),
  updateIsShowUserModal: (input) =>
    set((state) => ({
      isShowUserModal: input,
    })),
}));

export default useModalstore;
