import { create } from "zustand";

interface CardStore {
  cardCenter: { lat: number | null; lng: number | null };
  cardInfo: {
    siteName: string | null;
    roadAddress: string | null;
  };
  isShowCardInfoWindow: boolean;
  changeCardCenter: (inputCenter: {
    lat: number | null;
    lng: number | null;
  }) => void;
  changeCardInfo: (inputInfo: {
    siteName: string | null;
    roadAddress: string | null;
  }) => void;
  updateIsShowCardInfoWindow: (input: boolean) => void;
}

const useCardstore = create<CardStore>()((set) => ({
  cardCenter: {
    lat: null,
    lng: null,
  },
  cardInfo: {
    siteName: null,
    roadAddress: null,
  },
  isShowCardInfoWindow: false,
  changeCardCenter: (inputCenter) =>
    set((state) => ({
      cardCenter: { lat: inputCenter.lat, lng: inputCenter.lng },
    })),
  changeCardInfo: (inputInfo) =>
    set((state) => ({
      cardInfo: {
        siteName: inputInfo.siteName,
        roadAddress: inputInfo.roadAddress,
      },
    })),
  updateIsShowCardInfoWindow: (input: boolean) =>
    set((state) => ({
      isShowCardInfoWindow: input,
    })),
}));

export default useCardstore;
