import { create } from "zustand";

interface ErrorStore {
  robotDetailError: boolean;
  updateRobotDetailError: (value: boolean) => void;
}

const useErrorStore = create<ErrorStore>()((set) => ({
  robotDetailError: false,
  updateRobotDetailError: (value) => set({ robotDetailError: value }),
}));

export default useErrorStore;
