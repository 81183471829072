import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Trans } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart({ datas, maxValue }: any) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },

    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: "Title",
      },
      tooltip: {
        backgroundColor: "#08B295",
        boxPadding: 10,
        caretSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItems: any) => {
            const xValue = tooltipItems[0].label;
            return `${xValue}분 전`;
          },
          label: (tooltipItems: any) => {
            return `작업지시 ${String(Number(tooltipItems.formattedValue))}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
        max: maxValue > 0 ? maxValue : 30,
        grid: { display: false },
      },
    },
  };

  const labels = Array.from({ length: 30 }, (_, index) => 30 - index);

  const data = {
    labels,
    datasets: [
      {
        label: "작업지시",
        data: datas.map((i: any) => i),
        backgroundColor: "#21A188",
        minBarLength: 1,
        // hoverBackgroundColor: "black",
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="py-2 text-xs text-gray-600 text-start">
        <Trans i18nKey="per_minute_count" />
      </div>
      <div className="w-[100%] h-[50px] px-2">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}
