import { create } from "zustand";

interface LoginData {
  userEmail: string | undefined | null;
  isShowLoginModal: boolean;
  isLoggedIn: boolean;
  userInfo: any;
  isShowTempUserPage: boolean;
  changeUserEmail: (inputData: string | undefined | null) => void;
  changeUserInfo: (input: any) => void;
  updateIsShowLoginModal: (input: boolean) => void;
  updateIsShowTempUserPage: (input: boolean) => void;
  updateIsLoggedIn: (input: boolean) => void;
}

const useLoginStore = create<LoginData>()((set) => ({
  userEmail: "",
  isShowLoginModal: false,
  isLoggedIn: localStorage.login
    ? localStorage.login === "true"
      ? true
      : false
    : false,
  userInfo: null,
  isShowTempUserPage: false,
  changeUserEmail: (input) =>
    set((state) => ({
      userEmail: input,
    })),
  changeUserInfo: (input) =>
    set((state) => ({
      userInfo: input,
    })),
  updateIsShowLoginModal: (input) =>
    set((state) => ({
      isShowLoginModal: input,
    })),
  updateIsShowTempUserPage: (input) =>
    set((state) => ({
      isShowTempUserPage: input,
    })),
  updateIsLoggedIn: (input) =>
    set((state) => ({
      isLoggedIn: input,
    })),
}));

export default useLoginStore;
