import React from 'react';
import { ReactFlowProvider } from 'reactflow';

import 'reactflow/dist/style.css';
import '../style/schedule.css';
import InquiryModal from '../components/modal/InquiryModal';
import useAuth from '../hooks/useAuth';
import Flow from '../components/workflow/Flow';

const WorkflowPage = () => {
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        워크플로우
      </div>
      <div>
        <div className="dndflow">
          <ReactFlowProvider>
            {/*{!mobileCheck ? <ItemBuilder onDrop={onDrop}/> : <ItemSidebarMobile onDrop={onDrop}/>}*/}

            <Flow />
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default WorkflowPage;
