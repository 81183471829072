import { ChangeEvent, useEffect, useState } from 'react';
import '../style/animation.css';

import RobotTable from '../components/robot/RobotTable';
import dayjs from 'dayjs';
import { DeviceDataType } from '../_types';
import Modal from '../components/modal/Modal';
import ModalPortal from '../components/modal/ModalPortal';
import useRobotstore from '../store/robotStore';
import { useTranslation } from 'react-i18next';

import useAuth from '../hooks/useAuth';
import InquiryModal from '../components/modal/InquiryModal';
import { useSearchParams } from 'react-router-dom';
import Detail from '../components/robot/Detail';
import MultiRobotDetail from '../components/robot/MultiRobotDetail';
import SearchInput from '../components/common/SearchInput';
import useErrorStore from '../store/errorStore';
import { Tabs } from 'antd';
import MultiRobotWork from '../components/robot/MultiRobotWork';
import useSiteData from '../hooks/useSiteData';
import { FullSpinner } from '../components/loading/FullSpinner';
import useRobotData from '../hooks/useRobotData';

export default function RobotPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  // 전역 상태
  const {
    isShowRobotDetail,
    isShowMultiRobotDetail,
    updateIsShowRobotDetail,
    updateIsShowMultiRobotDetail,
    selectedRobot,
    isLoading,
  } = useRobotstore();

  const { robotSocketData } = useRobotData();

  const { robotDetailError, updateRobotDetailError } = useErrorStore();

  const { siteList } = useSiteData();

  // 필터
  const [selectRobotType, setSelectRobotType] = useState<string>('');
  const [robotTypeFilter, setRobotTypeFilter] = useState<string[]>([]);
  const [selectRobotName, setSelectRobotName] = useState('');
  const [selectedSite, setSelectedSite] = useState<string>('');

  // 지도에서 로봇 운영 클릭 시
  useEffect(() => {
    if (!searchParams.get('siteName') || !siteList) return;

    const siteName = searchParams.get('siteName');
    const site = siteList.find((item) => item.name === siteName);
    if (!site || !site.id) return;

    setSelectedSite(site.id);
    setSearchParams('');
  }, [searchParams, siteList, setSearchParams, robotSocketData]);

  useEffect(() => {
    if (robotSocketData.length > 0) {
      const temp2: string[] = [];

      const temp = robotSocketData.map((item: any) => item.modelType);

      const map = new Map();

      for (const item of temp) {
        if (!map.has(item)) {
          map.set(item, true);
          temp2.push(item);
        }
      }

      setRobotTypeFilter(temp2);
    }
  }, [robotSocketData]);

  const handleChangeSite = (selectedId: string) => {
    setSelectedSite(selectedId);
  };

  const handleSelectType = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    setSelectRobotType(target.value);
  };

  const tabItems = [
    {
      key: '1',
      label: t('robot'),
      children: (
        <>
          <section
            className="flex items-center justify-between gap-5 pb-3"
            data-name="bg"
          >
            <>
              <SearchInput handleChangeSite={handleChangeSite} />

              <select
                className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px]   text-sm border border-white rounded-lg shadow-sm"
                value={selectRobotType}
                onChange={handleSelectType}
                data-name="robotType"
              >
                <option hidden>로봇 타입 선택</option>
                <option value="">{t('robot_types_all')}</option>
                {robotTypeFilter.length !== 0 &&
                  robotTypeFilter.map((item: string, index: number) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>

              <input
                className="w-full min-w-[130px] max-w-[200px] px-3 py-2  text-sm border border-white rounded-lg shadow-sm"
                type="text"
                placeholder={t('robot_name_searching')}
                value={selectRobotName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  setSelectRobotName(value);
                }}
              />
              <div className="flex justify-end w-full gap-2 pr-10 text-xs">
                <span>{t('last_update')} </span>
                <span>{dayjs(new Date()).format('MM-DD HH:mm:ss')}</span>
              </div>
            </>
          </section>
          <RobotTable
            selectRobotType={selectRobotType}
            selectRobotName={selectRobotName}
            selectedSite={selectedSite}
            robotSocketData={robotSocketData as DeviceDataType[]}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('Multirobot_Work'),
      children: <MultiRobotWork />,
    },
  ];

  return (
    <>
      <FullSpinner loading={isLoading} />

      <div
        className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2"
        // onClick={(event: React.MouseEvent<HTMLElement>) => {
        //   const target = event.target as HTMLDivElement;
        //   const name = target.parentElement?.dataset.name;
        //   if (isShowRobotDetail && name !== 'list') {
        //     updateIsShowRobotDetail(false);
        //   }
        // }}
      >
        <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
          {t('robot_operation')}
        </div>

        <section className="pt-[91px] w-full px-12">
          <Tabs items={tabItems} />
        </section>

        {isShowRobotDetail && (
          <div className="w-[100%] z-30 h-[100vh] fixed">
            <div
              onClick={() => {
                updateIsShowRobotDetail(false);
                robotDetailError && window.location.reload();
                updateRobotDetailError(false);
              }}
              className="fixed w-[100%] h-[100vh] bg-black opacity-[0.15]"
            ></div>
          </div>
        )}
        <Detail socketData={robotSocketData} />

        {isShowMultiRobotDetail && (
          <div className="w-[100%] z-30 h-[100vh] fixed">
            <div
              onClick={() => {
                updateIsShowMultiRobotDetail(false);
                // robotDetailError && window.location.reload();
                // updateRobotDetailError(false);
              }}
              className="fixed w-[100%] h-[100vh] bg-black opacity-[0.15]"
            ></div>
          </div>
        )}
        <MultiRobotDetail robotSocketData={robotSocketData} />

        <ModalPortal>
          <Modal />
        </ModalPortal>
        {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
          !(login === 'false') && <InquiryModal />}
      </div>
    </>
  );
}
