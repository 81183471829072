import React, { useEffect, useState } from 'react'
import { DiscontentContent, DiscontentContentBot, DiscontentContentImg, DiscontentContentTop, DiscontentContentTxt, DiscontentContentTxtArea, DiscontentContentWrapper, DiscontentTitle, DiscontentTitleBot, DiscontentTitleTop, DiscontentWrapper } from './styles';
import discontentIcon1 from '../../assets/introduce/Image/discontentIcon1.png'
import discontentIcon2 from '../../assets/introduce/Image/discontentIcon2.png'
import discontentIcon3 from '../../assets/introduce/Image/discontentIcon3.png'
import discontentIcon4 from '../../assets/introduce/Image/discontentIcon4.png'
import discontentIcon5 from '../../assets/introduce/Image/discontentIcon5.png'

export const DisContent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DiscontentWrapper>
      <DiscontentTitle>
        <DiscontentTitleTop>
          제한없는 연결과
          <br style={{display: windowWidth >= 450 ? 'none' : 'block'}} /> 무한한 가능성의 <span>SOLlink,</span>
        </DiscontentTitleTop>
        <DiscontentTitleBot>
          고객의 현장 고충<span>에서 시작되었습니다.</span>
        </DiscontentTitleBot>
      </DiscontentTitle>
      <DiscontentContentWrapper>
        <DiscontentContentTop>
          <DiscontentContent>
            <DiscontentContentImg>
              <img src={discontentIcon1} />
            </DiscontentContentImg>
            <DiscontentContentTxtArea>
              <DiscontentContentTxt>
                편하려고 도입한 제어/관제 시스템,<br />
                <span>관리업체와 도입 비용만 늘어나요.</span>
              </DiscontentContentTxt>
            </DiscontentContentTxtArea>
          </DiscontentContent>
          <DiscontentContent>
            <DiscontentContentImg>
              <img src={discontentIcon2} />
            </DiscontentContentImg>
            <DiscontentContentTxtArea>
              <DiscontentContentTxt>
                <span>국내•외 다양한 로봇 브랜드</span>를 다뤄 본<br />
                제어•관제 시스템은 없을까요?
              </DiscontentContentTxt>
            </DiscontentContentTxtArea>
          </DiscontentContent>
        </DiscontentContentTop>
        <DiscontentContentBot>
          <DiscontentContent>
            <DiscontentContentImg>
              <img src={discontentIcon3} />
            </DiscontentContentImg>
            <DiscontentContentTxtArea>
              <DiscontentContentTxt>
                신규 <span>기능 업데이트</span>와 기존 시스템의<br />
                개선이 <span>정기적</span>으로 이루어지면 좋겠어요.
              </DiscontentContentTxt>
            </DiscontentContentTxtArea>
          </DiscontentContent>
          <DiscontentContent>
            <DiscontentContentImg>
              <img src={discontentIcon4} />
            </DiscontentContentImg>
            <DiscontentContentTxtArea>
              <DiscontentContentTxt>
                유휴하는 로봇만 늘어나고<br />
                <span>로봇활용도</span>가 떨어져요
              </DiscontentContentTxt>
            </DiscontentContentTxtArea>
          </DiscontentContent>
          <DiscontentContent>
            <DiscontentContentImg>
              <img src={discontentIcon5} />
            </DiscontentContentImg>
            <DiscontentContentTxtArea>
              <DiscontentContentTxt>
                로봇이 통행하는 현장의<br />
                <span>모든 인프라와도 연동</span>이 되면 좋겠어요.
              </DiscontentContentTxt>
            </DiscontentContentTxtArea>
          </DiscontentContent>
        </DiscontentContentBot>
        
      </DiscontentContentWrapper>
    </DiscontentWrapper>
  )
}


export default React.memo(DisContent);