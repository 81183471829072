import {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {Trans, useTranslation} from "react-i18next";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend,
} from "chart.js";

import {getUsageTimeByPeriod} from "../../../_api/rest/robot";
import {LOCALE} from "../../../constants";
import {ChartRobotType} from "../../../_types";
import {numberFormat} from "../../../util/format";
import useUserStore from "../../../store/userStore";
import useBlurStyle from "../../../hooks/useBlurStyle";
import usePeriod from "../../../hooks/usePeriod";

import Pagination from "../../common/Pagination";
import Skeleton from "../../common/Skeleton";
import {PeriodWithSiteId} from "../../../_types/dashBoard";

import {Tooltip} from 'react-tooltip';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend,
);

export default function MostUsedLineChart(props: any) {
    const blurStyle = useBlurStyle();
    const {selectedUserAuthSite} = useUserStore();
    const {t} = useTranslation();

    const [dataList, setDataList] = useState<ChartRobotType[]>([]);
    const [page1DataList, setPage1DataList] = useState<ChartRobotType[]>([]);
    const [page2DataList, setPage2DataList] = useState<ChartRobotType[]>([]);
    const [page3DataList, setPage3DataList] = useState<ChartRobotType[]>([]);
    const [page4DataList, setPage4DataList] = useState<ChartRobotType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [maxValue, setMaxValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate() - 1).padStart(2, "0");
        const yesterDayDate = `${year}-${month}-${day}`;

        if (selectedUserAuthSite.length === 0) {
            return;
        }

        const data: PeriodWithSiteId = {
            startDate: props.startDate === "null" || !props.startDate ? yesterDayDate : props.startDate,
            endDate: props.endDate === "null" || !props.endDate ? yesterDayDate : props.endDate,
            siteIds: selectedUserAuthSite,
        };

        getUsageTimeByPeriod(data) //
            .then((res) => {
                const tempData = [...res];
                const tempData2 = [...res];
                setDataList(res);
                setMaxValue(
                    parseInt(
                        tempData
                            .sort((a: ChartRobotType, b: ChartRobotType) => b.cnt - a.cnt)[0]
                            .ctime.slice(0, 2),
                        10
                    )
                );
                setPage1DataList(
                    tempData2
                        .sort((a: ChartRobotType, b: ChartRobotType) => b.cnt - a.cnt)
                        .splice(0, 6)
                );
                setPage2DataList(
                    tempData2
                        .sort((a: ChartRobotType, b: ChartRobotType) => b.cnt - a.cnt)
                        .splice(0, 6)
                );
                setPage3DataList(
                    tempData2
                        .sort((a: ChartRobotType, b: ChartRobotType) => b.cnt - a.cnt)
                        .splice(0, 6)
                );
                setPage4DataList(
                    tempData2
                        .sort((a: ChartRobotType, b: ChartRobotType) => b.cnt - a.cnt)
                        .splice(0, 6)
                );
                setTotalPages(4);
                setLoading(false);
            });
    }, [selectedUserAuthSite, props.startDate, props.endDate]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 500,
        },
        responsiveAnimationDuration: 0,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
                align: "end" as const,
                position: "top" as const,
                backgroundColor: function(context: any) {
                    return context.dataset.borderColor;
                },
                borderRadius: 4,
                color: 'white' as const,
                font: {
                    weight: 'bold' as const
                },
                formatter: Math.round,
                padding: 3,
            }
        },
        scales: {
            x: {
                ticks: {
                    // stepSize: 2,
                },
            },
            y: {
                min: 0,
                ticks: {
                    // stepSize: 20,
                },
            },
        },
    };

    const labels = dataList.map((item) => `${item.ctime.slice(0, 2)}시`);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: "이용건수 합계",
                data: dataList.map((item) => Math.ceil(item.cnt)),
                borderWidth: 2,
                radius: 2,
                borderColor: "#85B093",
                backgroundColor: "#85B09330",
            },
        ],
    };

    const [isShowTooltip, setIsShowTooltip] = useState(false);
    return (
        <div className="h-[100%] flex flex-col px-5 pt-2">
            <Tooltip
                anchorSelect={"#mostUsedLineChartTooltip"}
                place={"right"}
                content="로봇이 각 시간대에 수행한 서비스 건수를 나타내는
                    차트입니다."
            />
            <div className="self-start px-5 pt-4">
                <div
                    id={"mostUsedLineChartTooltip"}
                    className="text-[#08B295] text-sm cursor-default"
                >
                    <Trans i18nKey="number_of_services_by_time_slot"/>
                </div>

                <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
                    {t("most_used_time")} {maxValue}
                    {t("hour")}
                </div>
            </div>

            <div className={`w-[97%] h-[100%] pl-0 pt-0 pb-2 mt-0 ${blurStyle}`} style={{display: "flex"}}>
                <div className="w-[70%] h-[85%] mr-5 self-center justify-center items-end">
                    <Line options={options} data={data}/>
                </div>

                <div className={`w-[30%] h-[30%] mt-5 ${blurStyle}`}>
                    <div className="flex text-[0.8rem] justify-center text-center w-[90%] mt-2">
                        <div className="py-1 font-bold rounded-l-lg w-[60%] bg-neutral-100">
                            <Trans i18nKey="time"/>
                        </div>
                        <div className="py-1 font-bold rounded-r-lg w-[40%] bg-neutral-100">
                            <Trans i18nKey="number_of_cases"/>
                        </div>
                    </div>

                    <div className="text-gray-700 w-[90%] h-[30%]">
                        {loading ? (
                            <Skeleton className="w-[100%] h-[250px] rounded-lg mt-1"/>
                        ) : (
                            <>
                                {currentPage === 1 &&
                                    page1DataList.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center text-[0.7rem] text-center border-b"
                                        >
                                            <div className="py-2 w-[60%]">{item.ctime}</div>
                                            <div className="py-2 w-[40%]">
                                                {numberFormat(item.cnt, LOCALE)}
                                            </div>
                                        </div>
                                    ))}
                                {currentPage === 2 &&
                                    page2DataList.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center text-[0.7rem] text-center border-b"
                                        >
                                            <div className="py-2 w-[60%]">{item.ctime}</div>
                                            <div className="py-2 w-[40%]">
                                                {numberFormat(item.cnt, LOCALE)}
                                            </div>
                                        </div>
                                    ))}
                                {currentPage === 3 &&
                                    page3DataList.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center text-[0.7rem] text-center border-b"
                                        >
                                            <div className="py-2 w-[60%]">{item.ctime}</div>
                                            <div className="py-2 w-[40%]">
                                                {numberFormat(item.cnt, LOCALE)}
                                            </div>
                                        </div>
                                    ))}
                                {currentPage === 4 &&
                                    page4DataList.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center text-[0.7rem] text-center border-b"
                                        >
                                            <div className="py-2 w-[60%]">{item.ctime}</div>
                                            <div className="py-2 w-[40%]">
                                                {numberFormat(item.cnt, LOCALE)}
                                            </div>
                                        </div>
                                    ))}
                            </>
                        )}

                        <div className={blurStyle}>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onClickPrev={() => setCurrentPage((prev) => prev - 1)}
                                onClickNext={() => setCurrentPage((prev) => prev + 1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
