import { useEffect, useState } from 'react';

import { AuthType, DeviceDataType } from '../_types';
import { SECOND_SOCKET_URL } from '../_api/webSocket';
import useUserStore from '../store/userStore';
import useLoginStore from '../store/loginStore';

import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import { getUserById } from '../_api/firestore/user';

export default function useRobotData() {
  const { selectedUserAuthSite, userStatus } = useUserStore();
  const { userInfo } = useLoginStore();

  const [robotSocketData, setRobotSocketData] = useState<DeviceDataType[]>([]);
  const [userData, setUserData] = useState<AuthType | null>(null);
  const [authRobotList, setAuthRobotList] = useState<string[]>([]);

  useEffect(() => {
    if (selectedUserAuthSite.length === 0 || userStatus === null) return;

    const robotSocket = io(`${SECOND_SOCKET_URL}/robots`, {
      transports: ['websocket'],
      upgrade: true,
    });

    robotSocket.on('connect', () => {
      console.log(' ', 'robot socket data connected.');
    });

    robotSocket.emit('sites', selectedUserAuthSite);

    robotSocket.on('robots', (data) => {
      setRobotSocketData(data);
    });

    robotSocket.on('connect_error', (err) => {
      console.error('socket connection error : ', err);
      toast.error('데이터 연결에 문제가 발생했습니다.');
    });

    return () => {
      robotSocket.disconnect();
      robotSocket.off('connect');
      robotSocket.off('robots');
    };
  }, [selectedUserAuthSite, authRobotList, userStatus]);

  useEffect(() => {
    if (!userInfo) return;

    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;

    setAuthRobotList(userData.auth_robots);
  }, [userData]);

  return { robotSocketData };
}
