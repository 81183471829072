import { t } from 'i18next';

import SearchInput from '../components/common/SearchInput';
import ModalPortal from '../components/modal/ModalPortal';
import Modal from '../components/modal/Modal';
import useAuth from '../hooks/useAuth';
import InquiryModal from '../components/modal/InquiryModal';
import SettingCalendar from '../components/schedule/SettingCalendar';
import SettingSchedule from '../components/schedule/SettingSchedule';
import useSiteData from '../hooks/useSiteData';

const SchedulePage = () => {
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  const { siteList } = useSiteData();

  return (
    <div className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2">
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('schedule')}
      </div>

      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        <SearchInput isAllSiteOption={false} />
      </section>

      <section className="px-12 pt-3 pb-10">
        <div style={{ display: 'flex' }}>
          <SettingCalendar />
          <SettingSchedule siteList={siteList} />
        </div>
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
    </div>
  );
};

export default SchedulePage;
