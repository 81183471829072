import { CurrentTask } from '../../_types/schedule';
import TaskCard from './common/TaskCard';
import { deleteTask, postStopTask } from '../../_api/rest/schedule';
import { toast } from 'react-toastify';
import useModalstore from '../../store/modalStore';
import { t } from 'i18next';

import Spinner from '../loading/Spinner';

interface ProgressProps {
  loading: boolean;
  curentTaskList: (CurrentTask & { siteId: string })[];
  isHideButton?: boolean;
  isWidthFull?: boolean;
}

const Progress = ({ loading, curentTaskList }: ProgressProps) => {
  const { closeModal } = useModalstore();

  return (
    <div>
      {!loading ? (
        curentTaskList && curentTaskList.length > 0 ? (
          curentTaskList.map((task: CurrentTask & { siteId: string }) =>
            task.isVisible ? (
              <div className="flex flex-col gap-4 pb-3" key={task.taskId}>
                <div className="w-[60%]">
                  <TaskCard
                    taskId={task.taskId}
                    taskName={task.taskName}
                    taskState={task.taskState}
                    currentAction={task.currentAction}
                    prevAction={task.prevAction}
                    agentNameList={task.agentNameList}
                    currentActionAlias={task.currentActionAlias}
                    prevActionAlias={task.prevActionAlias}
                    currentActionCount={task.currentActionCount}
                    totalActionCount={task.totalActionCount}
                    startTime={task.startTime}
                    lastUpdateTime={task.lastUpdateTime}
                    ellapsedTimeTotal={task.ellapsedTimeTotal}
                    isStopped={task.taskState === 'USER_STOP'}
                    onStopClick={() =>
                      postStopTask({
                        siteId: task.siteId,
                        taskId: task.taskId,
                        activation: 'STOP',
                      })
                        .then(() => {
                          toast.success(t('It_has_been_stopped'));
                          closeModal();
                        })
                        .catch((error) => toast.error(`${error}`))
                    }
                    onDeleteClick={() =>
                      deleteTask({
                        siteId: task.siteId,
                        taskId: task.taskId,
                      })
                        .then(() => {
                          toast.success(t('It_has_been_deleted'));
                          closeModal();
                        })
                        .catch((error) => toast.error(`${error}`))
                    }
                  />
                </div>
              </div>
            ) : null,
          )
        ) : null
      ) : (
        <div className="flex pt-20 h-h-full">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Progress;
