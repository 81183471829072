import { create } from "zustand";

interface UserData {
  isShowUserIcon: boolean;
  userStatus: number | null;
  // userAuthSites: { name: string; id: string }[];
  selectedUserAuthSite: string[];
  bookmarkDevices: string[];
  updateIsShowUserIcon: (input: boolean) => void;
  changeUserStatus: (input: number) => void;
  // changeUserAuthSites: (input: { name: string; id: string }[]) => void;
  changeSelectedUserAuthSites: (input: string[]) => void;
  updateBookmarkDevices: (input: string[]) => void;
}

const useUserStore = create<UserData>()((set) => ({
  isShowUserIcon: false,
  userStatus: null,
  // userAuthSites: [],
  selectedUserAuthSite: [],
  bookmarkDevices: [],
  updateIsShowUserIcon: (input) =>
    set((state) => ({
      isShowUserIcon: input,
    })),
  changeUserStatus: (input) =>
    set((state) => ({
      userStatus: input,
    })),
  // changeUserAuthSites: (input) =>
  //   set((state) => ({
  //     userAuthSites: input,
  //   })),
  changeSelectedUserAuthSites: (input) =>
    set((state) => ({
      selectedUserAuthSite: input,
    })),
  updateBookmarkDevices: (input) =>
    set((state) => ({
      bookmarkDevices: input,
    })),
}));

export default useUserStore;
