import React, { useEffect, useState } from 'react'
import { ExpansionDisplay, ExpansionTxt, ExpansionWrapper, Graph, GraphBadge, GraphBadgeType, GraphContent, GraphContentBottom, GraphContentTop, GraphTitle, GraphWrapper } from './styles';

export const Expansion = () => {
  const [scroll, setScroll] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log(scroll)

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      setScroll(currentScrollY);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <ExpansionWrapper>
      <ExpansionDisplay>
        <ExpansionTxt>
          무한한 확장성
          <p>
            <span>SOLlink</span>
            를 통해 즉각적인 변화를  
            <br style={{display: windowWidth >= 550 ? 'none' : 'block'}} /> 경험하실 수 있습니다.
          </p>
        </ExpansionTxt>
        <GraphWrapper>
          <Graph>
            <GraphTitle>ROI</GraphTitle>
            <GraphContent>
              <GraphContentTop>
              <span className={scroll >= (windowWidth >= 550 ? 
                7954 
                : 
                (windowWidth >= 720 ? 
                  (windowWidth >= 950 ? 8436 : 8900) 
                  : 
                  6300)) ? 'active' : ''}>
                1년 이내
              </span>
              </GraphContentTop>
              <GraphContentBottom>5년 이상</GraphContentBottom>
            </GraphContent>
          </Graph>
        </GraphWrapper>
        <GraphWrapper>
          <Graph>
            <GraphTitle>
              엘레베이터 <br style={{display: windowWidth >= 800 ? 'block' : 'none'}} />
              연동 소요시간
              <span>*1대당 연동 시간</span>
            </GraphTitle>
            <GraphContent>
              <GraphContentTop>
              <span className={scroll >= (windowWidth >= 550 ? 
                8194 
                : 
                (windowWidth >= 720 ? 
                  (windowWidth >= 950 ? 8636 : 9100) 
                  : 
                  6500)) ? 'short active' : 'short'}>
                    1일
                </span> 
              </GraphContentTop>
              <GraphContentBottom>1개월</GraphContentBottom>
            </GraphContent>
          </Graph>
        </GraphWrapper>
        <GraphWrapper>
          <Graph>
            <GraphTitle>
              로봇 문제 발생<br />
              평균 조치 시간
            </GraphTitle>
            <GraphContent>
              <GraphContentTop>
              <span className={scroll >= (windowWidth >= 550 ? 
                8394 
                : 
                (windowWidth >= 720 ? 
                  (windowWidth >= 950 ? 8836 : 9300) 
                  : 
                  6700)) ? 'active' : ''}>
                  실시간
                </span>
              </GraphContentTop>
              <GraphContentBottom>무한</GraphContentBottom>
            </GraphContent>
          </Graph>
        </GraphWrapper>
        <GraphWrapper>
          <Graph>
            <GraphTitle>
              다수 로봇 도입 시<br />
              평균 설치 완료 기간
            </GraphTitle>
            <GraphContent>
              <GraphContentTop>
              <span className={scroll >= (windowWidth >= 550 ? 
                8594 
                : 
                (windowWidth >= 720 ? 
                  (windowWidth >= 950 ? 9036 : 9500) 
                  : 
                  6900)) ? 'short active' : 'short'}>
                  1일
                </span>
              </GraphContentTop>
              <GraphContentBottom>1개월</GraphContentBottom>
            </GraphContent>
          </Graph>
          <GraphBadge>
            <GraphBadgeType>
              <span style={{backgroundColor: '#33FF6C'}} />
              SOLlink 도입후
            </GraphBadgeType>
            <GraphBadgeType>
              <span style={{backgroundColor: '#ECECEC'}} />
              SOLlink 도입전
            </GraphBadgeType>
          </GraphBadge>
        </GraphWrapper>
      </ExpansionDisplay>
    </ExpansionWrapper>
  )
}

export default React.memo(Expansion);