import styled, { keyframes } from "styled-components";

export const ExperienceWrapper = styled.div`
  width: 100vw;
  /* height: 1397px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 137px;
  padding-top: 155px;
  padding-bottom: 52px;
  overflow: hidden;
  min-width: 350px;
  @media (max-width: 800px) {
    gap: 100px;
  }
  @media (max-width: 650px) {
    padding-top: 100px;
  }
  @media (max-width: 500px) {
    gap: 80px;
  }
  @media (max-width: 450px) {
    gap: 50px;
    padding-top: 46px;
  }
`
export const ExperienceTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  @media (max-width: 650px) {
    gap: 50px;
  }
`
export const ExperienceBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 102px;
  @media (max-width: 500px) {
    gap: 50px;
  }
`
export const ExperienceLogoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (max-width: 1024px) {
    gap: 12px;
  }
  @media (max-width: 450px) {
    gap: 7px;
  }
`
export const ExperienceLogo = styled.img`
  width: 320px;
  height: 224px;
  object-fit: contain;
  @media (max-width: 1024px) {
    width: 280px;
    height: 100%;
  }
  @media (max-width: 650px) {
    width: 220px;
    height: 100%;
  }
  @media (max-width: 450px) {
    width: 138px;
    height: 97px;
  }
`
export const ExperienceLogoTxt = styled.p`
  font-size: 46px;
  font-weight: 700;
  letter-spacing: 0.92px;
  background: linear-gradient(127deg, #1BBA9C 9.35%, #21A9D3 91.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  span {
    font-weight: 800;
  }
  @media (max-width: 1024px) {
    font-size: 38px;
  }
  @media (max-width: 550px) {
    font-size: 32px;
  }
  @media (max-width: 550px) {
    font-size: 20px;
  }
`
export const ExperienceDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
export const ExperienceTopDesc = styled.p`
  color: #111;
  font-size: 26px;
  font-weight: 700;
  position: relative;
  @media (max-width: 650px) {
    font-size: 24px;
  }
  @media (max-width: 550px) {
    font-size: 20px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`
const expandAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 528px;
  }
`;

export const ExperienceTopDescLine = styled.div`
  width: 528px;
  height: 20px;
  position: absolute;
  background-color: #C1E5F5;
  bottom: 0;
  left: -8px;
  z-index: -1;
  line-height: 40px;
  &.active {
    animation: ${expandAnimation} 1s ease forwards;
  }
  @media (max-width: 650px) {
    width: 500px !important;
    height: 15px;
    ${expandAnimation} {
      to {
        width: 500px;
      }
    }
  }
  @media (max-width: 550px) {
    width: 410px !important;
    height: 14px;
    ${expandAnimation} {
      to {
        width: 410px;
      }
    }
  }
  @media (max-width: 450px) {
    width: 330px !important;
    height: 10px;
    ${expandAnimation} {
      to {
        width: 330px;
      }
    }
  }
`
export const ExperienceMidDesc = styled.p`
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  @media (max-width: 650px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 450px) {
    font-size: 15px;
    line-height: 20px;
  }
`
export const ExperienceBotDesc = styled.p`
  line-height: 40px;
  color: #111;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  @media (max-width: 650px) {
    line-height: 30px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const ExperienceBottomIcons = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 80px;
  justify-content: center;
  @media (max-width: 800px) {
    gap: 50px;
  }
  @media (max-width: 720px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    gap: 32px;
  }
`
export const ExperienceBotIconsTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 80px;
  @media (max-width: 800px) {
    gap: 50px;
  }
  @media (max-width: 720px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    gap: 36px;
  }
  @media (max-width: 400px) {
    gap: 28px;
  }
`
export const ExperienceBotIconsBot = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 80px;
  @media (max-width: 800px) {
    gap: 50px;
  }
  @media (max-width: 720px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    gap: 36px;
  }
  @media (max-width: 400px) {
    gap: 28px;
  }
`
export const ExperienceIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  @media (max-width: 800px) {
    gap: 20px;
  }
  @media (max-width: 720px) {
    width: 160px;
  }
  @media (max-width: 500px) {
    gap: 12px;
  }
`
export const ExperienceIconImg = styled.img`
  max-width: 124px;
  height: 100px;
  object-fit: contain;
  @media (max-width: 1024px) {
    max-width: 90px;
    height: 90px;
  }
  @media (max-width: 500px) {
    max-width: 73px;
    height: 80px;
  }
`
export const ExperienceIconTxt = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  line-height: 28px;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`
export const ExperienceBtn = styled.div`
  cursor: pointer;
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 26px 32px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Pretendard';
  border-radius: 50px;
  background: linear-gradient(127deg, #1BBA9C 9.35%, #21A9D3 91.16%);
  @media (max-width: 500px) {
    width: 220px;
    border-radius: 30px;
    font-size: 18px;
    gap: 5px;
    padding: 11px;
  }
`