import {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from "chart.js";

import {getDestinationsByPeriod} from "../../../_api/rest/robot";
import {LOCALE, PAGE_VIEW_CNT_POI} from "../../../constants";
import useDashboardStore from "../../../store/dashboardStore";
import useUserStore from "../../../store/userStore";
import useBlurStyle from "../../../hooks/useBlurStyle";
import Pagination from "../../common/Pagination";
import Skeleton from "../../common/Skeleton";
import {numberFormat} from "../../../util/format";
import {
    MostUsedDestination,
    PeriodWithSiteId,
} from "../../../_types/dashBoard";

//react-tooltip
import {Tooltip} from 'react-tooltip';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
);

export default function MostUsedDestinationBarChart(props: any) {
    const blurStyle = useBlurStyle();

    const {selectedUserAuthSite} = useUserStore();
    const {currentDate, oneMonthAgoDate} = useDashboardStore();
    const [loading, setLoading] = useState<boolean>(true);
    const [poiList, setPoiList] = useState<MostUsedDestination[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [labelList, setLabelList] = useState<string[]>([]);
    const [max, setMax] = useState<MostUsedDestination | null>(null);
    const skeletonDummyData = [90, 192, 278, 228, 150, 205, 177];
    const {t} = useTranslation();

    useEffect(() => {
        if (selectedUserAuthSite.length === 0) {
            return;
        }
        const data: PeriodWithSiteId = {
            startDate:
                props.startDate === "null" || !props.startDate ? oneMonthAgoDate : props.startDate,
            endDate: props.endDate === "null" || !props.endDate ? currentDate : props.endDate,
            siteIds: selectedUserAuthSite,
        };

        getDestinationsByPeriod(data).then((res) => {
            const data = [...res];
            const temp = data.sort(
                (a: any, b: any) => b.targetPoiCnt - a.targetPoiCnt
            );

            setPoiList([...temp]);
            setMax(temp[0]);
            setTotalPages(Math.ceil(temp.length / PAGE_VIEW_CNT_POI));
            setLoading(false);
        });
    }, [selectedUserAuthSite, currentDate, oneMonthAgoDate, props.startDate, props.endDate]);

    useEffect(() => {
        if (poiList.length !== 0) {
            const labels = poiList
                .slice(
                    (currentPage - 1) * PAGE_VIEW_CNT_POI,
                    currentPage * PAGE_VIEW_CNT_POI
                )
                .map((item: any) => {
                    return `${item.robotName} - ${item.targetPoiName}`;
                });
            setLabelList(labels);
        }
    }, [poiList]);

    const options = {
        indexAxis: "y" as const,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 500,
        },
        responsiveAnimationDuration: 0,
        plugins: {
            legend: {
                display: false,
                position: "bottom" as const,
            },

            datalabels: {
                color: 'white',
                display: function(context: any) {
                    return context.dataset.data[context.dataIndex] > 15;
                },
                font: {
                    weight: 'bold' as const
                },
                formatter: function(value: number, context: any){
                    return Math.round(value)+ '건';
                }
            },
            tooltip: {
                boxPadding: 10,
                caretSize: 0,
                displayColors: false,
                callbacks: {
                    title: (tooltipItems: any) => {
                        const {dataIndex} = tooltipItems[0];
                        return labelList[dataIndex];
                    },
                    label: (tooltipItems: any) => {
                        return tooltipItems.formattedValue;
                    },
                },
            },
        },
        scales: {
            x: {
                display: false,
                grid: {display: false},
                stacked: true,
                max: max?.targetPoiCnt ?? 0,
            },
            y: {
                stacked: true,
                grid: {display: false},
            },
        },
    };
    const labels = poiList
        .slice(
            (currentPage - 1) * PAGE_VIEW_CNT_POI,
            currentPage * PAGE_VIEW_CNT_POI
        )
        .map(
            (item: any) =>
                item.robotName.slice(0, 4) + "..." + "-" + item.targetPoiName
        );

    const data = {
        labels,
        datasets: [
            {
                label: "출발지",
                data: poiList
                    .slice(
                        (currentPage - 1) * PAGE_VIEW_CNT_POI,
                        currentPage * PAGE_VIEW_CNT_POI
                    )
                    .map((item: any) => item.targetPoiCnt),
                backgroundColor: "#326D6C",
                stack: "Stack 1",
                datalabels: {
                    align: 'start' as const,
                    anchor: 'end' as const
                }
            },
        ],
    };
    const [isShowTooltip, setIsShowTooltip] = useState(false);

    return (
        <div className="flex flex-col px-5 py-2 h-[100%]">
            <div className="w-full">
                <Tooltip
                    anchorSelect={"#mostUsedDstBarChartTooltip"}
                    place={"right"}
                    content="지난 1개월간 개별 로봇 디바이스가 가장 많이 이용한 목적지입니다."
                />
                <div className="flex self-start pt-2">
                    {/* <div className="p-2 mr-5">
          <div className="text-[#08B295] text-sm">
            <Trans i18nKey="most_used_departure_point" />
          </div>

          <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
            <Trans i18nKey="1th Floor" /> | {numberFormat(15, LOCALE)}
            <Trans i18nKey="case" />
          </div>
        </div> */}

                    <div className="p-2">
                        <div
                            id={"mostUsedDstBarChartTooltip"}
                            className="text-[#08B295] text-sm"
                        >
                            <Trans i18nKey="most_popular_destinations"/>
                        </div>
                        {poiList.length !== 0 && (
                            <div className={`text-lg font-bold text-gray-800 ${blurStyle}`}>
                                {/* {numberFormat(11, LOCALE)} */}
                                {poiList[0].robotName} - {poiList[0].targetPoiName} |{" "}
                                <Trans i18nKey="charging_station"/>
                                {numberFormat(poiList[0].targetPoiCnt, LOCALE)}
                                <Trans i18nKey="case"/>
                            </div>
                        )}
                    </div>
                </div>

              { poiList.length > 0 ?
                  (<div className={`w-[97%] h-[80%] pl-5 pt-0 pb-2 mt-0 ${blurStyle}`} style={{display: "flex"}}>
                <div className={`w-[60%] h-[80%] pl-5 pt-0 pb-2 mt-3 ${blurStyle}`}>
                  {loading ? (
                      skeletonDummyData.map((item, i) => (
                          <div key={i} className="flex">
                            <Skeleton
                                width={item}
                                height={20}
                                borderRadius={3}
                                className="my-1"
                            />
                          </div>
                      ))
                  ) : (
                      <Bar options={options} data={data}/>
                  )}
                </div>
              <div className={`w-[40%] h-[80%] ml-10 mt-1 ${blurStyle}`}>
                <div
                    className={
                            "flex text-[0.8rem] justify-center text-center w-[100%] mt-3"
                        }
                    >
                        <div className="py-1 font-bold rounded-l-lg w-[70%] bg-neutral-100">
                            {/* <Trans i18nKey="place" /> */}
                            {t("robot")} - {t("destination")}
                        </div>
                        <div className="flex py-1 font-bold rounded-r-lg w-[30%] bg-neutral-100">
                            <div className="w-[100%]">
                                <Trans i18nKey="destination"/>
                                (
                                <Trans i18nKey="number_of_cases"/>)
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <Skeleton className="w-full h-[190px] mt-1 rounded-md"/>
                    ) : (
                        poiList
                            .slice(
                                (currentPage - 1) * PAGE_VIEW_CNT_POI,
                                currentPage * PAGE_VIEW_CNT_POI
                            )
                            .map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className="w-[100%] flex justify-center items-center text-[0.7rem] text-center border-b"
                                >
                                    <div className="py-2 w-[70%]" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.robotName + item.targetPoiName}
                                    </div>
                                    <div className="py-2 w-[30%]">{item.targetPoiCnt}</div>
                                </div>
                            ))
                    )}
                    <div className={`pt-1 ${blurStyle}`}>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onClickPrev={() => setCurrentPage((prev) => prev - 1)}
                            onClickNext={() => setCurrentPage((prev) => prev + 1)}
                        />
                    </div>
                </div>
              </div> ):
                  (
                      <div style={{
                        fontSize: '60px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontFamily: 'SUIT',
                        height: '400px',
                        lineHeight: 5,
                        color: '#e0e0e0'

                      }}>NO DATA</div>
                  )
              }
            </div>

        </div>
    );
}
