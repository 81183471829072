import { create } from "zustand";

interface MenuStore {
  isShowMapMenu2: boolean;
  isShowCalendar: boolean;
  updateIsShowCalendar: (input: boolean) => void;
  updateIsShowMapMenu2: (inputMenu: boolean) => void;
}

const useMenustore = create<MenuStore>()((set) => ({
  isShowMapMenu2: false,
  isShowCalendar: false,
  updateIsShowCalendar: (input) =>
    set((state) => ({
      isShowCalendar: input,
    })),
  updateIsShowMapMenu2: (inputMenu) =>
    set((state) => ({
      isShowMapMenu2: inputMenu,
    })),
}));

export default useMenustore;
