import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { DocumentData, doc, getDoc } from "firebase/firestore";

import { auth, db } from "../config/firebase";
import useModalstore from "../store/modalStore";
import useUserData from "../hooks/useUserData";

import UserModal from "../components/user/UserModal";
import { useTranslation } from "react-i18next";
import {
  FirestoreCollenctions,
  Permission,
  RobotType,
  SimpleRobotType,
  SimpleSiteType,
  SiteType,
} from "../_types";

import Spinner from "../components/loading/Spinner";
import { getAllSiteListOnSnapshot } from "../_api/firestore/site";
import { getAllRobotList } from "../_api/firestore/robot";

export default function UserPage() {
  const { t } = useTranslation();
  const { isShowUserModal, updateIsShowUserModal } = useModalstore();

  const { user } = useUserData();

  const [siteList, setSiteList] = useState<
    SimpleSiteType[] | SiteType[] | null
  >(null);

  const [robotList, setRobotList] = useState<
    RobotType[] | SimpleRobotType[] | null
  >(null);

  useEffect(() => {
    if (!user) return;
    if (user.permission === Permission.SUPER_ADMIN) {
      getAllSiteListOnSnapshot(
        setSiteList as Dispatch<SetStateAction<SiteType[]>>
      );
      getAllRobotList(setRobotList as Dispatch<SetStateAction<RobotType[]>>);
    } else {
      getAuthList();
    }
  }, [user]);

  async function getAuthList() {
    if (!user) return;

    await Promise.allSettled([getAuthSiteList(), getAuthRobotList()]);
  }

  async function getAuthSiteList() {
    if (!user) return;

    const sites: SimpleSiteType[] = [];

    // site list
    await Promise.all(
      user.auth_sites.map(async (currentSite) => {
        const docRef = doc(db, FirestoreCollenctions.SITES, currentSite as any);
        const res = await getDoc(docRef);
        sites.push({ name: res.data()?.name as string });
      })
    );

    setSiteList(sites);
    return sites;
  }

  async function getAuthRobotList() {
    if (!user) return;

    const robots: SimpleRobotType[] = [];

    const resList: DocumentData | undefined[] = [];

    // robot list
    await Promise.all(
      user.auth_robots.map(async (currentRobot) => {
        const docRef = doc(
          db,
          FirestoreCollenctions.ROBOTS,
          currentRobot as any
        );
        const res = await getDoc(docRef);
        resList.push(res.data());
        robots.push({ name: res.data()?.name as string });
      })
    );
    setRobotList(robots);
    return robots;
  }

  return (
    <>
      {isShowUserModal && <UserModal />}
      <div className="pl-[75px] flex bg-neutral-50 ">
        <div className="w-[99%] p-10  pl-7 ">
          <h1 className="mb-4 text-2xl text-gray-800">{t("my_page")}</h1>
          <div className="bg-white shadow w-[100%] min-h-[calc(100vh-16rem)] rounded-xl flex p-5 ">
            <div className="w-[40%] text-gray-600 flex flex-col items-center pt-12">
              <div className="w-[100px] h-[100px] bg-[#08B295] rounded-full mb-5 flex justify-center items-center text-white text-2xl">
                {`:)`}
              </div>

              <div className="mb-3 text-3xl font-bold">
                {user ? user.display_name : ""}
              </div>

              <div className="mb-1"> {user ? user.email : ""}</div>
              {user &&
                (auth.currentUser &&
                auth.currentUser.providerData.length > 0 &&
                auth.currentUser.providerData[0].providerId === "password" ? (
                  <div
                    className="mb-1 hover:cursor-pointer hover:text-[#08B295]"
                    onClick={() => updateIsShowUserModal(true)}
                  >
                    {t("change_password")}
                  </div>
                ) : (
                  <div
                    className="mb-1 hover:cursor-pointer hover:text-[#08B295]"
                    onClick={() => {
                      sendPasswordResetEmail(
                        auth,
                        auth?.currentUser?.email as string
                      )
                        .then((res) => {
                          alert(t("your_mail_has_been_sent"));
                        })
                        .catch((error) => {
                          console.error(error);
                          throw Error("비밀번호 재설정 실패");
                        });
                    }}
                  >
                    {t("reset_password")}
                  </div>
                ))}
            </div>

            <div className="w-[60%] h-[100%]">
              <div className="px-5 my-2 border border-gray-300 rounded-xl py-7  min-h-[136px]">
                <div className="flex items-center justify-between mb-3">
                  <div className="mr-3 font-bold text-gray-600">
                    {t("managed_sites")}
                  </div>
                </div>
                {
                  <div className="flex flex-wrap items-center">
                    {siteList ? (
                      (siteList as SimpleSiteType[]).map(
                        (site: SimpleSiteType, index: number) => (
                          <div
                            key={index}
                            className="bg-[#08B29520] text-[#08B295] px-3 py-1 rounded-2xl mr-1 mb-2 "
                          >
                            {site.name}
                          </div>
                        )
                      )
                    ) : (
                      <Spinner size="small" />
                    )}
                  </div>
                }
              </div>

              <div className="px-5 my-2 border border-gray-300 rounded-xl py-7 min-h-[136px]">
                <div className="flex items-center justify-between mb-3">
                  <div className="mr-3 font-bold text-gray-600">
                    {t("managed_robot")}
                  </div>
                </div>

                <div className="flex flex-wrap items-center">
                  {robotList ? (
                    robotList.map((robot: SimpleRobotType, index: number) => (
                      <div
                        key={index}
                        className="bg-[#08B29520] text-[#08B295] px-3 py-1 rounded-2xl mr-1 mb-2"
                      >
                        {robot.name}
                      </div>
                    ))
                  ) : (
                    <Spinner size="small" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
