import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import "../../style/menu.css";

interface PropsType {
  i18nKey: string;
  iconPath: string;
  toPath: string;
}

const menuDefaultStyle =
  "w-[100%] py-2 my-1 text-xs flex flex-col items-center hover:cursor-pointer";

const menuStyle = `${menuDefaultStyle} text-[#525252] border-l-4 border-l-white hover:text-[#45AEA3] svg-container`;
const selectedMenuStyle = `${menuDefaultStyle} text-[#45AEA3] border-l-4 border-l-[#45AEA3] selected`;

const Menu = ({ i18nKey, iconPath, toPath }: PropsType) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { pathname } = useLocation();

  return (
    <Link
      to={`${toPath}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={pathname === toPath ? selectedMenuStyle : menuStyle}
    >
      <img
        src={iconPath}
        className={`ico_menu ${isHover ? "hover" : ""} ${
          pathname === toPath ? "selected" : ""
        }`}
        alt=""
      />

      <span className="text-center">
        <Trans i18nKey={i18nKey} />
      </span>
    </Link>
  );
};

export default Menu;
