
// TODO: 소켓 api 모두 SECOND_SOCKET_URL로 변경되면 이름변경 및 환경변수 재설정하기

export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL ?? "wss://staging-api.sollink.ai";
export const SECOND_SOCKET_URL =
  process.env.REACT_APP_SECOND_SOCKET_URL ?? "wss://is-staging.sollink.ai";

// export const realtimeStateSocket = io(`${SOCKET_URL}/dashboard-realtime-state`);
// export const realTimePerMinSocket = io(
//   `${SOCKET_URL}/dashboard-realtime-per-min`
// );
// export const dataSocket = io(`${SOCKET_URL}/data`);
// export const siteSocket = io(`${SECOND_SOCKET_URL}/sites`);
