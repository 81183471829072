import styled from "styled-components";

export const MapWrapper = styled.div`
  width: 100vw;
  /* height: 1814px; */
  padding-top: 184px;
  padding-bottom: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media (max-width: 550px) {
    padding-top: 100px;
  }
  @media (max-width: 450px) {
    padding-top: 77px;
    padding-bottom: 100px;
    height: 760px;
  }
` 
export const MapTxtArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  @media (max-width: 1024px) {
    gap: 30px;
  }
`
export const MapTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #111;
  font-size: 50px;
  font-weight: 400;
  p {
    background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 800;
  }
  @media (max-width: 1024px) {
    font-size: 42px;
    p {
      font-size: 42px;
    }
  }
  @media (max-width: 550px) {
    font-size: 32px;
    p {
      font-size: 32px;
    }
  }
  @media (max-width: 450px) {
    font-size: 24px;
    p {
      font-size: 24px;
    }
  }
`
export const MapSubTitle = styled.p`
  color: #333;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 30px;
  }
  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 22px;
  }
`
export const MapImage = styled.div`
  width: 100vw;
  /* height: 906px; */
  border-radius: 20px;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), lightgray 50% / cover no-repeat; */
  margin-top: 129px;
  margin-bottom: 83px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 20px;
  }
  @media (max-width: 1380px) {
    width: 1000px;
  }
  @media (max-width: 1030px) {
    width: 750px;
  }
  @media (max-width: 800px) {
    width: 620px;
    height: 620px;
  }
  @media (max-width: 650px) {
    width: 520px;
    height: 520px;
    margin-top: 80px;
  }
  @media (max-width: 550px) {
    width: 320px;
    height: 520px;
    margin-top: 80px;
  }
`