import { useEffect, useState } from "react";
import useCardstore from "../../store/cardStore";
import { Trans, useTranslation } from "react-i18next";
import { doc, getDoc } from "firebase/firestore";
import { Permission } from "../../_types/user";
import Skeleton from "../common/Skeleton";
import { getAllSiteList, getSiteListWithAuth } from "../../_api/firestore/site";
import { SiteType, TotalCardMenu } from "../../_types/site";
import { getAllRobotList } from "../../_api/firestore/robot";
import { FirestoreCollenctions, RobotType } from "../../_types";
import useUserData from "../../hooks/useUserData";
import { db } from "../../config/firebase";

export default function TotalCard() {
  const [list, setList] = useState<TotalCardMenu[] | null>(null);

  const { t } = useTranslation();
  const { changeCardCenter, changeCardInfo, updateIsShowCardInfoWindow } =
    useCardstore();
  const [robotList, setRobotList] = useState<RobotType[]>([]);
  const [totalSiteList, setTotalSiteList] = useState<SiteType[]>([]);
  const [authSiteList, setAuthSiteList] = useState<string[]>([]);

  const { user } = useUserData();

  // 전체 사이트, 각 사이트의 로봇 리스트
  useEffect(() => {
    getAllSiteList().then((data) => {
      setTotalSiteList(data);

      if (user?.permission === Permission.SUPER_ADMIN) {
        setAuthSiteList(data.map((s) => s.name));
      }
    });

    getAllRobotList(setRobotList);
  }, [user?.permission]);

  // 사용자 권한 있는 사이트
  useEffect(() => {
    if (!user) return;

    if (
      user?.permission === Permission.ENGINEER ||
      user?.permission === Permission.USER
    ) {
      getAuthSite(user.auth_sites);
    }
  }, [user]);

  // totalSiteList, authSiteList 사용해서 카드뷰에 들어갈 list 생성
  useEffect(() => {
    if (totalSiteList.length !== 0 && robotList.length !== 0 && authSiteList) {
      const tempList: TotalCardMenu[] = [];

      // 카드뷰에 들어갈 정보 tempList에 push해서 생성한 뒤 setList로 저장
      for (const item of totalSiteList) {
        const newAddress = item.road_address.split(" ").slice(0, 2).join(" ");
        const roadAddressAlias = `${newAddress} ******`;
        const tempRobotCnt = robotList.filter(
          (el: RobotType) => el.site_id === item.id
        );
        tempList.push({
          // order: 권한 있는 사이트는 0, 아니면 1 (카드뷰 우선 순위 지정)
          order: authSiteList.includes(item.name)
            ? Permission.SUPER_ADMIN
            : Permission.ENGINEER,
          siteName: authSiteList.includes(item.name) ? item.name : item.alias,
          roadAddress: authSiteList.includes(item.name)
            ? item.road_address
            : roadAddressAlias,
          latitude: item.latitude,
          longitude: item.longitude,
          robotCnt: tempRobotCnt.length,
        });
      }

      setList(
        tempList.sort((a: TotalCardMenu, b: TotalCardMenu) => a.order - b.order)
      );
    }
  }, [totalSiteList, authSiteList, robotList]);

  // 카드뷰 클릭 시 cardStore에 카드뷰 내용 저장 -> map에서 마커에 카드뷰 내용 표시하기 위함
  const handleClick: any = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const data = target.dataset.info ? JSON.parse(target.dataset.info) : null;
    if (data.latitude && data.longitude) {
      changeCardCenter({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
      });
    }
    localStorage.setItem("lat", String(data.latitude));
    localStorage.setItem("lng", String(data.longitude));
    changeCardInfo({
      siteName: data.siteName,
      roadAddress: data.roadAddress,
    });
    updateIsShowCardInfoWindow(true);
  };

  // 권한 있는 사이트 가져오는 함수
  function getAuthSite(authSite: string[]) {
    getSiteListWithAuth(authSite) //
      .then((res) => setAuthSiteList(res.map((s) => s.name)));

    async function getAuthSiteList() {
      if (!user) return;

      const sites: string[] = [];

      // site list
      for (let i = 0; i < user.auth_sites.length; i++) {
        const currentSite = user.auth_sites[i];
        const docRef = doc(
          db,
          FirestoreCollenctions.SITES,
          currentSite as string
        );

        const stieData: any = await getDoc(docRef);
        // const res = await getDoc(currentSite);
        sites.push(stieData.data()?.name);
      }

      return sites;
    }
    getAuthSiteList();
  }

  return (
    <div className="w-[100%] h-[100vh] pt-5 px-2">
      <div className="ml-1">
        <div className="text-2xl text-gray-800">
          {t("nationwide_solink_usage_status")}
        </div>
        <div className="pt-1 pb-3 text-xs text-gray-800">
          <Trans i18nKey="robotCount_robots_are_being_used_in_siteCount_across_the_country">
            {{
              siteCount: totalSiteList.length,
              robotCount: robotList.length,
            }}
          </Trans>
        </div>
      </div>
      <div
        id={list ? "scroll" : ""}
        className={
          list
            ? "mb-3 pt-3 pb-11 h-[93%] overflow-y-scroll"
            : "mb-3 pt-3 pb-11 h-[93%]"
        }
      >
        {!list
          ? Array.from({ length: 10 }).map((_, index) => (
              <div
                key={index}
                className="p-5 bg-white shadow rounded-xl mb-3 mx-2 w-[92%]"
              >
                <div className="text-sm font-bold flex justify-between w-[100%]">
                  <div>
                    <Skeleton
                      width={130}
                      height={20}
                      borderRadius={7}
                      className="mb-1"
                    />
                    <Skeleton width={100} height={20} borderRadius={7} />
                  </div>
                  <div className="flex flex-col items-end">
                    <Skeleton
                      width={60}
                      height={20}
                      borderRadius={7}
                      className="mb-1"
                    />
                    <Skeleton width={20} height={20} borderRadius={7} />
                  </div>
                </div>
              </div>
            ))
          : list.map((item: TotalCardMenu, index: number) => {
              if (item.robotCnt !== 0) {
                return (
                  <div
                    key={index}
                    onClick={handleClick}
                    data-info={JSON.stringify(item)}
                    className="p-5 bg-white shadow rounded-xl hover:bg-[#08B29510] mb-3 mx-2 hover:cursor-pointer"
                  >
                    <div className="">
                      <div className="flex justify-between">
                        <div className="text-sm font-bold">{item.siteName}</div>
                        <div className="text-sm font-bold">
                          <Trans i18nKey="robots_introduced_count" />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="text-xs text-gray-500">
                          {item.roadAddress}
                        </div>
                        <div className="text-sm text-[#08B295]">
                          {item.robotCnt}대
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
}
