import { t } from 'i18next';
import { Tabs } from 'antd';
import SearchInput from '../components/common/SearchInput';
import ModalPortal from '../components/modal/ModalPortal';
import Modal from '../components/modal/Modal';
import Finished from '../components/history/Finished';
import InquiryModal from '../components/modal/InquiryModal';
import Ongoing from '../components/history/Ongoing';
import useAuth from '../hooks/useAuth';

const SchedulePage = () => {
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  const tabItems = [
    {
      key: '1',
      label: t('Ongoing_Work'),
      children: <Ongoing />,
    },
    {
      key: '2',
      label: t('Completed_Work'),
      children: <Finished />,
    },
  ];

  return (
    <div className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2">
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('history')}
      </div>

      <section className="pt-[91px] w-full px-12">
        <Tabs
          items={tabItems}
          tabBarExtraContent={<SearchInput viewOption="all" />}
        />
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
    </div>
  );
};

export default SchedulePage;
