import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import CalendarIcon from '../../../assets/icon/icon_calendar.png';
import PersonOffIcon from '../../../assets/icon/icon_person_off.png';
import PlayIcon from '../../../assets/icon/icon_play.png';
import {
  CleanRobotCommandType,
  CommonCommandType,
  DeviceDataType,
  RobotCommandType,
} from '../../../_types';
import { getIsRobotReady, onStartJob } from '../../../_api/rest/common';
import useDatastore from '../../../store/dataStore';
import useCommonstore from '../../../store/modalStore';

import Button from '../../common/Button';
import Select from '../../common/SingleSelect';
import ContentsPlayModal from '../../modal/ContentsPlayModal';
import CleanModal from '../../modal/CleanModal';
import TTSModal from '../../modal/TTSModal';
import VideoModal from '../../modal/VideoModal';
import TurnModal from '../../modal/TurnModal';
import VolumeModal from '../../modal/VolumeModal';
import ReportModal from '../../modal/ReportModal';
import { t } from 'i18next';
import useRobotstore from '../../../store/robotStore';

type PropsType = {
  deviceId: string;
  isOnline: boolean;
  cmdList: CommonCommandType[] | CleanRobotCommandType[] | null;
  detailData?: DeviceDataType | null;
};

export default function JobOrderButton({
  deviceId,
  isOnline,
  cmdList,
  detailData,
}: PropsType) {
  const { openModal } = useCommonstore();
  const { robotDetailData } = useDatastore();

  const { changeIsLoading } = useRobotstore();

  const [isShowScheduleInfo, setIsShowScheduleInfo] = useState<boolean>(false);
  const [poiId, setPoiId] = useState<string | null>(null);
  const [macroId, setMacroId] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [deliveryPoiId, setDeliveryPoiId] = useState<string | null>(null);
  useEffect(() => {
    setPoiId(null);
    setMacroId(null);
    setTaskId(null);
    setDeliveryPoiId(null);
  }, [deviceId]);

  const onShowScheduleInfo = (e: MouseEvent<HTMLButtonElement>) => {
    setIsShowScheduleInfo(e.type === 'mouseover');
  };

  // Type Guard
  function isValid(detail: any) {
    // if (!detail || !detail.id) return false;
    if (!detail) return false;

    return true;
  }

  const onStartCmd = async (
    command: RobotCommandType,
    type: 'macro' | 'move' | 'task' | 'common' | 'delivery',
  ) => {
    if (!isValid(detailData || robotDetailData)) return;

    changeIsLoading(true);

    const robotData: {
      command: RobotCommandType;
      deviceId: string;
      macroId?: string;
      poiId?: string;
      taskId?: string;
    } = { command, deviceId };

    let infoText = '';
    let id = '';
    let setState: Dispatch<SetStateAction<string | null>>;

    if (type === 'macro') {
      infoText = 'Please_select_a_macro';
      robotData.macroId = macroId as string;
      id = macroId as string;
      setState = setMacroId;
    }

    if (type === 'move') {
      infoText = 'Please_select_a_destination';
      robotData.poiId = poiId as string;
      id = poiId as string;
      setState = setPoiId;
    }

    if (type === 'delivery') {
      infoText = 'Please_select_a_destination';
      robotData.poiId = deliveryPoiId as string;
      id = deliveryPoiId as string;
      setState = setDeliveryPoiId;
    }

    if (type === 'task') {
      infoText = 'Please_select_a_TASK';
      robotData.taskId = taskId as string;
      id = taskId as string;
      setState = setTaskId;
    }

    if (!id && type !== 'common') {
      toast.info(t(infoText));
      changeIsLoading(false);
      return;
    }

    if (
      command !== 'COMMAND_STOP' &&
      command !== 'COMMAND_FORCED_STOP' &&
      command !== 'COMMAND_REBOOT' &&
      command !== 'COMMAND_REFRESH'
    ) {
      const isReady = await getIsRobotReady(deviceId);

      if (!isReady) {
        toast.error(t('Currently_engaged_in_another_task'));
        changeIsLoading(false);
        return;
      }
    }

    onStartJob(robotData).then(() => {
      type !== 'common' && setState(null);
      toast.success(t('Task_request_has_been_completed'));
      changeIsLoading(false);
    });
  };

  return (
    <section className="flex flex-wrap items-center">
      {cmdList?.map((cmd) => {
        switch (cmd.command) {
          case 'COMMAND_MOVE':
            return (
              <div
                key={cmd.command}
                className="inline-flex items-center ml-[6px] my-0"
              >
                <span className="w-[180px]">
                  <Select
                    label={t('Please_select_a_destination')}
                    selected={poiId as string}
                    disabled={!isOnline}
                    list={(cmd as CommonCommandType).poiList}
                    onChange={(id) => setPoiId(id)}
                  />
                </span>

                <Button
                  label={cmd.name}
                  type="button"
                  primary
                  disabled={!isOnline}
                  onClick={() => onStartCmd(cmd.command, 'move')}
                />
              </div>
            );

          case 'COMMAND_DELIVERY':
            return (
              <div
                key={cmd.command}
                className="inline-flex items-center ml-[6px] my-0"
              >
                <span className="w-[180px]">
                  <Select
                    label={t('Please_select_a_destination')}
                    selected={deliveryPoiId as string}
                    disabled={!isOnline}
                    list={(cmd as CommonCommandType).poiList}
                    onChange={(id) => setDeliveryPoiId(id)}
                  />
                </span>

                <Button
                  label={cmd.name}
                  type="button"
                  primary
                  disabled={!isOnline}
                  onClick={() => onStartCmd(cmd.command, 'delivery')}
                />
              </div>
            );

          case 'COMMAND_TASK':
            return (
              <div
                key={cmd.command}
                className="inline-flex items-center ml-[6px] my-0"
              >
                <span className="w-full min-w-[180px]">
                  <Select
                    label={t('Please_select_a_TASK')}
                    selected={taskId as string}
                    disabled={!isOnline}
                    list={(cmd as CommonCommandType).taskList}
                    onChange={(id) => setTaskId(id)}
                  />
                </span>

                <Button
                  label={cmd.name}
                  type="button"
                  primary
                  disabled={!isOnline}
                  onClick={() => onStartCmd(cmd.command, 'task')}
                />
              </div>
            );
          case 'COMMAND_MACRO':
            return (
              <div
                key={cmd.command}
                className="inline-flex items-center ml-[6px] my-0"
              >
                <span className="w-[180px]">
                  <Select
                    label={t('Please_select_a_macro')}
                    selected={macroId as string}
                    disabled={!isOnline}
                    list={(cmd as CommonCommandType).macroList}
                    onChange={(id) => setMacroId(id)}
                  />
                </span>

                <Button
                  label={t('Start')}
                  type="button"
                  primary
                  disabled={!isOnline}
                  onClick={() => onStartCmd(cmd.command, 'macro')}
                />
              </div>
            );
          case 'COMMAND_BACK_TO_SCHEDULE':
            return (
              <span key={cmd.command} className="relative">
                {isShowScheduleInfo && (
                  <span className="absolute cursor-default w-max h-f px-[10px] py-[3px] text-[12px] rounded-[6px] z-10 text-[#868686] bg-[#d8d8d8] -top-[15px] left-[70px] shadow-info">
                    {t('The_robot_will_return_to_the_previous_schedule')}
                  </span>
                )}

                <Button
                  label={cmd.name}
                  type="button"
                  disabled={!isOnline}
                  primary
                  icon={CalendarIcon}
                  onHover={onShowScheduleInfo}
                  onClick={() => onStartCmd(cmd.command, 'common')}
                />
              </span>
            );
          case 'COMMAND_CHARGE':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() => onStartCmd(cmd.command, 'common')}
              />
            );
          case 'COMMAND_CONTENTS_PLAY':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                icon={PlayIcon}
                onClick={() =>
                  openModal({
                    title: `${t('Play_content')}`,
                    contents: <ContentsPlayModal deviceId={deviceId} />,
                  })
                }
              />
            );
          case 'COMMAND_CLEAN':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                icon={PlayIcon}
                onClick={() =>
                  openModal({
                    title: `${t('Start_cleaning')}`,
                    contents: (
                      <CleanModal
                        deviceId={deviceId}
                        command={cmdList as CleanRobotCommandType[]}
                      />
                    ),
                  })
                }
              />
            );
          // open modal
          case 'COMMAND_TTS':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() =>
                  openModal({
                    title: `${cmd.name}`,
                    contents: (
                      <TTSModal deviceId={deviceId} command={cmd.command} />
                    ),
                  })
                }
              />
            );
          case 'COMMAND_VIDEO':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() =>
                  openModal({
                    title: `${cmd.name}`,
                    contents: (
                      <VideoModal deviceId={deviceId} command={cmd.command} />
                    ),
                  })
                }
              />
            );
          case 'COMMAND_TURN':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() =>
                  openModal({
                    title: `${cmd.name}`,
                    contents: (
                      <TurnModal deviceId={deviceId} command={cmd.command} />
                    ),
                  })
                }
              />
            );
          case 'COMMAND_SOUND':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() =>
                  openModal({
                    title: `${cmd.name}`,
                    contents: (
                      <VolumeModal deviceId={deviceId} command={cmd.command} />
                    ),
                  })
                }
              />
            );
          case 'COMMAND_REPORT':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                onClick={() =>
                  openModal({
                    title: `${cmd.name}`,
                    contents: <ReportModal detailData={detailData} />,
                  })
                }
              />
            );
          case 'COMMAND_REFRESH':
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                disabled={!isOnline}
                primary
                icon={PersonOffIcon}
                onClick={() => onStartCmd(cmd.command, 'common')}
              />
            );
          default:
            return (
              <Button
                key={cmd.command}
                label={cmd.name}
                type="button"
                primary
                disabled={!isOnline}
                onClick={() => onStartCmd(cmd.command, 'common')}
              />
            );
        }
      })}
    </section>
  );
}
