import { MouseEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import RefreshIcon from '../../assets/icon/icon_refresh.png';
import CheckIcon from '../../assets/icon/icon_check.png';
import InfoIcon from '../../assets/icon/icon_information.png';
import useModalstore from '../../store/modalStore';
import { ContentType, PoiType } from '../../_types';
import {
  getContentsList,
  postPlayContents,
  postPoiSync,
} from '../../_api/rest/common';
import Button from '../common/Button';

type PropsType = {
  deviceId: string;
};

export default function ContentsPlayModal({ deviceId }: PropsType) {
  const { closeModal } = useModalstore();

  const [isShowContentsInfo, setIsShowContentsInfo] = useState<boolean>(false);
  const [isShowLocationInfo, setIsShowLocationInfo] = useState<boolean>(false);
  const [poiList, setPoiList] = useState<PoiType[]>([]);
  const [contentList, setContentList] = useState<ContentType[]>([]);
  const [order, setOrder] = useState<number[]>([]);

  useEffect(() => {
    getContents(deviceId);
  }, [deviceId]);

  function getContents(deviceId: string) {
    getContentsList(deviceId) //
      .then((res) => {
        setPoiList(res.poiList);
        setContentList(res.contentList);
      });
  }

  const ellipsis = (str: string, num: number) => {
    return str.length > num ? `${str.substring(0, num)}...` : str;
  };

  const onShowLocationInfo = (e: MouseEvent<HTMLImageElement>) => {
    setIsShowLocationInfo(e.type === 'mouseover' ? true : false);
  };

  const onShowContentsInfo = (e: MouseEvent<HTMLImageElement>) => {
    setIsShowContentsInfo(e.type === 'mouseover' ? true : false);
  };

  const onRefreshPOI = (deviceId: string) => {
    const element = document.querySelector('.reload');
    element?.classList.add('animate-spin-slow');

    postPoiSync(deviceId) //
      .then(() => getContents(deviceId))
      .finally(() => element?.classList.remove('animate-spin-slow'));
  };

  const onSelectContents = (index: number) => {
    if (!order.includes(index) && order.length === 3) {
      toast.info('콘텐츠는 최대 3개까지 선택 가능합니다.');
      return;
    }

    if (!order.includes(index)) {
      setOrder((o) => [...o, index]);
    } else {
      setOrder((o) => o.filter((i) => i !== index));
    }
  };

  const onSelectPOI = (id: string) => {
    const newPoiList = poiList.map((p) => ({ ...p, checked: id === p.poiId }));
    setPoiList(newPoiList);
  };

  const onPlayContents = () => {
    if (!poiList.some((p) => p.checked)) {
      toast.info('위치를 선택해 주세요.');
      return;
    }

    if (order.length === 0) {
      toast.info('콘텐츠를 선택해 주세요.');
      return;
    }

    // content order
    const newContentList = [...contentList];
    order.forEach(
      (o, i) => (newContentList[o] = { ...newContentList[o], order: i + 1 }),
    );

    const contentData = {
      deviceId,
      poiList,
      contentList: newContentList,
    };

    postPlayContents(contentData) //
      .then(() => {
        toast.success('작업지시가 완료되었습니다.');
        closeModal();
      })
      .catch((e) => toast.error(`ERROR:: ${e.message}`));
  };

  return (
    <div className="flex flex-col justify-between h-[22rem]">
      <section className="flex justify-center">
        <div className="w-[45%] flex flex-col m-2">
          <div className="flex items-center my-3 mx-0 font-bold text-[#868686]">
            <h3>위치 선택</h3>

            <button type="button" onClick={() => onRefreshPOI(deviceId)}>
              <img
                src={RefreshIcon}
                className="reload w-[14px] ml-2 active:opacity-50"
                alt="poi 동기화"
              />
            </button>

            <span className="flex items-start h-full">
              <img
                src={InfoIcon}
                className="w-[14px]"
                alt="information"
                onMouseOver={onShowLocationInfo}
                onMouseLeave={onShowLocationInfo}
              />
            </span>
            <span
              className={`absolute w-fit px-[10px] py-[3px] text-xs font-light rounded-[6px] shadow-info bg-[#e9e9e9] top-[60px] left-[128px] ${
                isShowLocationInfo ? 'block' : 'hidden'
              }`}
            >
              POI 정보가 최신 업데이트 됩니다.
            </span>
          </div>

          <ul
            id="scroll"
            className="h-[170px] overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {poiList.map((p) => (
              <li
                key={p.poiId}
                className={`flex justify-between items-center px-4 py-[10px] cursor-pointer hover:bg-hover ${
                  p.checked && 'bg-hover'
                }`}
                onClick={() => onSelectPOI(p.poiId)}
              >
                <p className="text-xs text-[#8e979e] whitespace-nowrap overflow-hidden text-ellipsis">
                  {p.name}
                </p>
                {p.checked && <img src={CheckIcon} alt="check icon" />}
              </li>
            ))}
          </ul>
        </div>

        <div className="w-[45%] flex flex-col m-2">
          <div className="flex items-center my-3 mx-0 font-bold text-[#868686]">
            <h3>콘텐츠 선택</h3>
            <span className="flex items-start h-full">
              <img
                src={InfoIcon}
                className="w-[14px]"
                alt="information"
                onMouseOver={onShowContentsInfo}
                onMouseLeave={onShowContentsInfo}
              />
            </span>
            <span
              className={`absolute w-fit px-[10px] py-[3px] text-xs font-light rounded-[6px] shadow-info bg-[#e9e9e9] top-[43px] -right-[68px] ${
                isShowContentsInfo ? 'block' : 'hidden'
              }`}
            >
              콘텐츠는 최대 3개까지 선택 가능하며, <br />
              선택하는 순서대로 재생됩니다.
            </span>
          </div>

          <ul
            id="scroll"
            className="h-[170px] overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {contentList.map((c, index) => (
              <li
                key={c.name}
                className={`flex justify-between items-center px-4 py-[10px] cursor-pointer hover:bg-hover ${
                  order.includes(index) && 'bg-hover'
                }`}
                onClick={() => onSelectContents(index)}
              >
                <p className="text-xs text-[#8e979e] whitespace-nowrap overflow-hidden text-ellipsis">
                  {c.source}
                </p>
                {order.includes(index) && (
                  <img src={CheckIcon} alt="check icon" />
                )}
              </li>
            ))}
          </ul>
        </div>
      </section>

      {order.length > 0 && (
        <section className="flex justify-center items-center font-bold text-sm text-[#7b7b7b]">
          <span className="m-1 text-[#08b295]">
            {order.map((i) => ellipsis(contentList[i].source, 5)).join(' - ')}
          </span>
          순서대로 재생됩니다.
        </section>
      )}

      <section className="w-full flex justify-center items-center mx-0 my-[10px]">
        <Button
          outlined
          label="취소"
          type="button"
          size="small"
          onClick={closeModal}
        />
        <Button
          label="시작"
          type="button"
          size="small"
          primary
          onClick={onPlayContents}
        />
      </section>
    </div>
  );
}
