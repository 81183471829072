import "./video.css";
import DasomVideo from "./DasomVideo";
import FomeVideo from "./FomeVideo";

export const OperationVideo = ({
  type = "DASOM",
  deviceId,
}: {
  type?: "MINI" | "DASOM";
  deviceId: string;
}) => {
  return (
    <div id="operationVideo">
      {type === "DASOM" ? <DasomVideo /> : <FomeVideo deviceId={deviceId} />}
    </div>
  );
};
