import core from "../../config/axios";

interface Params {
  userUuid: string | undefined;
  deviceId: string | undefined;
}

interface getReturnData {
  userUuid: string;
  bookmarkedDeviceIds: string[];
}

const { client } = core;

const getUserBookmark = async (
  userUuid: string | undefined
): Promise<getReturnData> => {
  try {
    const res = await client.get("user-bookmark-device", {
      params: {
        userUuid,
      },
    });

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("북마크 정보 가져오기에 실패했습니다.");
  }
};

const postUserBookmark = async (data: Params): Promise<any> => {
  try {
    const res = await client.post("user-bookmark-device", data);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("북마크 추가에 실패했습니다.");
  }
};

const deleteUserBookmark = async (data: Params): Promise<any> => {
  try {
    const res = await client.delete("user-bookmark-device", { data });

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("북마크 삭제에 실패했습니다.");
  }
};

export { getUserBookmark, postUserBookmark, deleteUserBookmark };
