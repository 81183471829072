import styled from "styled-components";

export const TextAreaWrapper = styled.div`
  width: 100vw;
  padding-top: 110px;
  padding-bottom: 223px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  min-width: 350px;
  overflow: hidden;
  @media (max-width: 800px) {
    padding-top: 70px;
    padding-bottom: 160px;
  }
  @media (max-width: 540px) {
    padding-top: 50px;
    padding-bottom: 140px;
    gap: 0px;
  }
  @media (max-width: 440px) {
    padding-top: 30px;
    padding-bottom: 80px;
  }
`
export const TextAreaTop = styled.p`
  color: #333;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  @media (max-width: 1200px) {
    font-size: 36px;
  }
  @media (max-width: 800px) {
    font-size: 34px;
  }
  @media (max-width: 650px) {
    font-size: 26px;
  }
  @media (max-width: 540px) {
    font-size: 22px;
  }
  @media (max-width: 440px) {
    font-size: 17px;
    line-height: 24px;
  }
`
export const TextAreaBot = styled.p`
  color: #111;
  font-size: 50px;
  font-weight: 700;
  line-height: 48px;
  span {
    background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 800;
    line-height: 48px;
  }
  @media (max-width: 1200px) {
    font-size: 45px;
    span {
      font-size: 45px;
    }
  }
  @media (max-width: 1024px) {
    font-size: 36px;
    span {
      font-size: 36px;
    }
  }
  @media (max-width: 800px) {
    font-size: 45px;
    span {
      font-size: 45px;
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 650px) {
    font-size: 38px;
    span {
      font-size: 38px;
    }
  }
  @media (max-width: 540px) {
    font-size: 32px;
    line-height: 20px;
    span {
      font-size: 32px;
    }
  }
  @media (max-width: 440px) {
    font-size: 24px;
    line-height: 20px;
    span {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
`