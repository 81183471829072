import { useEffect, useRef, useState } from "react";
import useModalstore from "../../store/modalStore";
import checkIcon from "../../assets/icon/icon_check_circle.svg";
import checkIconColored from "../../assets/icon/icon_check_circle_colored.svg";
import { auth } from "../../config/firebase";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../_api/rest/user";

export default function UserModal() {
  const [focusPasswordTest, setFocusPasswordTest] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const testPasswordRef = useRef<HTMLInputElement>(null);
  const [targetPassword, setTargetPassword] = useState("");
  const [targetTestPassword, setTargetTestPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isPasswordCompleted, setIsPasswordCompleted] = useState(false);
  const [isCliked, setIsCliked] = useState(false);
  const { updateIsShowUserModal } = useModalstore();
  const { t } = useTranslation();

  const closeLoginModal = (event: any) => {
    const name = event.target.dataset.name;
    if (name === "background") {
      updateIsShowUserModal(false);
    }
  };

  const checkPassword = (input: string) => {
    const lowerCaseAlphabet = /[a-z]/;
    const numbers = /\d/;
    const specialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    if (
      lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      !specialCharacter.test(input)
    ) {
      return true;
    } else if (
      lowerCaseAlphabet.test(input) &&
      !numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else if (
      !lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else if (
      lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsPasswordCorrect(checkPassword(targetPassword));
  }, [targetPassword]);

  useEffect(() => {
    if (!targetPassword || !targetTestPassword) return;
    if (targetPassword === targetTestPassword) {
      setIsPasswordCompleted(true);
    } else {
      setIsPasswordCompleted(false);
    }
  }, [targetPassword, targetTestPassword]);

  const onChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
    setIsCliked(true);
    event.preventDefault();

    if (
      auth.currentUser &&
      !auth.currentUser.isAnonymous &&
      auth.currentUser.providerData[0].providerId === "password"
    ) {
      changePassword(auth.currentUser.uid, targetPassword)
        .then((_res: any) => {
          alert(t("your_password_has_been_changed"));
          updateIsShowUserModal(false);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  return (
    <div
      onClick={closeLoginModal}
      data-name="background"
      className="fixed w-[100%] h-[100vh] bg-[#00000025] z-[200] flex justify-center items-center"
    >
      <div className="bg-white w-[420px] flex flex-col items-center rounded-xl">
        <div className="my-4 px-3 py-2 w-[100%] flex items-center justify-center rounded-xl">
          {t("change_password")}
        </div>
        <div className="py-5">
          {/* <form onSubmit={onChangePassword} className="flex">
            <div className="mr-2 h-[40px] text-center flex items-center justify-center">
              전화번호
            </div>
            <input
              name="email"
              type="text"
              placeholder="전화번호"
              required
              // ref={emailRef}
              className="p-3 border h-[40px] mb-2"
            />
            <input
              type="submit"
              value="변경"
              className="bg-[#08B295] text-white rounded-xl hover:cursor-pointer text-sm w-[60px] h-[40px]"
            />
          </form> */}
          <form onSubmit={onChangePassword} className="flex flex-col">
            {/* <div className="mr-2 h-[40px] text-center flex items-center justify-center">
              비밀번호
            </div> */}
            {/* <div className="w-[220px] mr-5">
              <input
                name="email"
                type="text"
                placeholder="신규 비밀번호"
                required
                // ref={emailRef}
                className="p-3 border h-[40px] mb-2"
              />
              <input
                name="password"
                type="password"
                placeholder="신규 비밀번호 확인"
                required
                // ref={passwordRef}
                className="p-3 border h-[40px]"
              />
            </div> */}
            <input
              name="password"
              type="password"
              placeholder={`${t("new")} ${t("password")}`}
              required
              ref={passwordRef}
              onChange={(evet) => {
                setTargetPassword(evet.target.value);
              }}
              value={targetPassword}
              className="p-2 px-3 my-1 w-[97%] border"
            />
            <input
              name="password"
              type="password"
              placeholder={`${t("confirm")} ${t("new")} ${t("password")}`}
              onFocus={() => setFocusPasswordTest(true)}
              onChange={(evet) => {
                setTargetTestPassword(evet.target.value);
              }}
              value={targetTestPassword}
              required
              ref={testPasswordRef}
              className="p-2 px-3 my-1 w-[97%] border mb-3"
            />
            {focusPasswordTest && !isPasswordCompleted && (
              <div className="pl-2 mb-5 text-xs font-bold text-red-500">
                {t("passwords_dont_match")}
              </div>
            )}
            <div className="flex self-start text-start text-[10px] font-bold mb-2">
              <div className="flex items-center mx-1">
                <img
                  alt={targetPassword.length < 8 ? "조건 불충분" : "조건 만족"}
                  src={targetPassword.length < 8 ? checkIcon : checkIconColored}
                  className="w-[1rem] h-[1rem] mr-1"
                />
                <div
                  className={
                    targetPassword.length < 8
                      ? "text-gray-500"
                      : "text-[#08B295]"
                  }
                >
                  {t("eight_or_more_digits")}
                </div>
              </div>
              <div className="flex items-center mx-1">
                <img
                  alt={targetPassword.length < 8 ? "조건 불충분" : "조건 만족"}
                  src={isPasswordCorrect ? checkIconColored : checkIcon}
                  className="w-[1rem] h-[1rem] mr-1"
                />
                <div
                  className={
                    isPasswordCorrect ? "text-[#08B295]" : "text-gray-500"
                  }
                >
                  {t(
                    "at_least_2_of_lowercase_letters_numbers_special_characters"
                  )}
                </div>
              </div>
            </div>
            {/* <input
              type="submit"
              value="변경"
              className="bg-[#08B295] text-white rounded-xl hover:cursor-pointer text-sm w-[60px] h-[40px]"
            /> */}
            <div className="flex justify-center">
              <button
                className="flex items-center px-3 py-2 mx-1 my-4 text-sm bg-gray-300 rounded-xl"
                onClick={() => updateIsShowUserModal(false)}
              >
                {t("cancel")}
              </button>
              <button
                disabled={!isPasswordCompleted || isCliked}
                type="submit"
                className={`my-4 px-3 py-2 rounded-xl flex items-center text-sm mx-1 ${
                  isPasswordCompleted || !isCliked
                    ? "bg-[#08B295]"
                    : "bg-gray-500"
                } text-white`}
              >
                {t("change")}
              </button>
            </div>{" "}
          </form>
        </div>
      </div>
    </div>
  );
}
