export enum ElevatorCommand {
  COMMAND_HALL_CALL = "홀콜",
  COMMAND_CAR_CALL = "카콜",
  COMMAND_OPEN_DOOR = "문열림",
}

export interface ElevatorFloorList {
  floorIdx: string;
  floorName: string;
}

export interface ElevatorCommandList {
  command: keyof typeof ElevatorCommand;
  name: (typeof ElevatorCommand)[keyof typeof ElevatorCommand];
  order: number;
  elvFloorList?: ElevatorFloorList[];
  taskList?: { taskId: number; deviceId: string; taskName: string }[] | [];
}

export interface ElevatorDataType {
  id: number;
  alias: string;
  deviceId: string;
  manufacturer: string;
  modelName: string;
  homePoi: null;
  chargePoi: null;
  commandList: ElevatorCommandList[];
  waitJobs: [];
}
