import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { UsedRobotType } from "../../../../_types";
import { useEffect, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type PropsType = {
  robotList: UsedRobotType[];
  max: number;
};

export default function MostUsedBarChart({ robotList, max }: PropsType) {
  const [labelList, setLabelList] = useState<string[]>([]);

  useEffect(() => {
    if (robotList.length !== 0) {
      const labels = robotList?.map((item: any) => item.robotName);
      setLabelList(labels);
    }
  }, [robotList]);

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Title",
      },
      tooltip: {
        boxPadding: 10,
        caretSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItems: any) => {
            const { dataIndex } = tooltipItems[0];
            return labelList[dataIndex];
          },
          label: (tooltipItems: any) => {
            return tooltipItems.formattedValue;
          },
        },
      },
      datalabels: {
        color: 'white',
        display: function(context: any) {
          return context.dataset.data[context.dataIndex] > 15;
        },
        font: {
          weight: 'bold' as const
        },
        formatter: function(value: number, context: any){
          return Math.round(value)+ '건';
        }
      },
    },
    scales: {
      x: {
        display: false,
        grid: { display: false },
        stacked: true,
        max: max,
      },
      y: {
        stacked: true,
        grid: { display: false },
      },
    },
  };

  const labels = robotList?.map((item: any) =>
    item.robotName.length > 6
      ? item.robotName.slice(0, 6) + "..."
      : item.robotName
  );

  const data = {
    labels,
    datasets: [
      {
        label: "이용 건수",
        data: robotList?.map((item: any) => item.cnt),
        backgroundColor: "#85B093",
        datalabels: {
          align: 'start' as const,
          anchor: 'end' as const
        }
      },
    ],
  };

  return (
    <div className="w-[95%] h-[220px] self-center md:p-0">
      <Bar options={options} data={data} />
    </div>
  );
}
