import React, { useState } from 'react';
import { Grid } from '../../dashboard/common/grid/grid';
import { ColDef } from 'ag-grid-community';
import { TitleWidget } from '../../dashboard/common/label/titleWidget';
import { DateLabel } from '../../dashboard/common/label/dateLabel';
import { IoBarChart } from 'react-icons/io5';
import { RiAlarmWarningFill, RiExternalLinkFill } from 'react-icons/ri';

const DailyErrorLogGrid = () => {
  interface RowData {
    make: string | null;
    model: string | null;
    price: number | null;
  }

  const [rowData] = useState<RowData[]>([
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxster', price: 72000 },
  ]);

  const [columnDefs] = useState<ColDef<RowData>[]>([
    {
      headerName: 'Make',
      field: 'make',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: 'Model',
      field: 'model',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
    {
      headerName: 'Price',
      field: 'price',
      sortable: true,
      headerClass: 'header-center',
      cellStyle: { textAlign: 'center' },
      flex: 1,
    },
  ]);

  return (
    <div className={'p-5'}>
      <div className={'flex border-b-[1px] border-gray-100'}>
        <div className={'content-center'}>
          <TitleWidget
            fontSize={17}
            title={'일일 오류 이력'}
            icon={RiAlarmWarningFill}
            iconPosition={'left'}
            iconSize={20}
            iconMarginL={10}
            padding={'0px 10px 10px 10px'}
            width={150}
            useLink={false}
          />
        </div>
        <div className={'ml-1 content-center'}>
          <DateLabel
            fontSize={11}
            dateFormat={'YYYY-MM-DD hh:mm:ss'}
            useClock={false}
          />
        </div>
        <div className={'content-center ml-auto'}>
          <TitleWidget
            fontSize={13}
            title={'더보기'}
            icon={RiExternalLinkFill}
            iconPosition={'left'}
            iconSize={16}
            iconMarginL={3}
            padding={'0px 10px 10px 10px'}
            useLink={true}
            linkAddress={'/dashboard'}
          />
        </div>
      </div>
      <div className={'mt-5 w-full'}>
        <Grid rowData={rowData} columnDefs={columnDefs} height={300} />
      </div>
    </div>
  );
};

export default DailyErrorLogGrid;
