import { ElevatorDataType } from "../../_types/elevator";
import core from "../../config/axios";

const { client } = core;

export async function getElevator(deviceId: string): Promise<ElevatorDataType> {
  try {
    const res = await client.get(`/robot/status/${deviceId}`);

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("엘레베이터 연동에 실패했습니다.");
  }
}

export async function postElevator(
  deviceId: string,
  command: string,
  targetFloor?: string,
  startingFloor?: string
) {
  try {
    const data = {
      deviceId,
      command,
      ...(startingFloor && { startingFloor }),
      ...(targetFloor && { targetFloor }),
    };

    const res = await client.post("/robot/command", data);

    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else {
      console.error("Unexpected status code:", res.status);
      return Promise.reject(new Error(`Unexpected status code: ${res.status}`));
    }
  } catch (error) {
    console.error(error);
    throw new Error("엘레베이터 작업지시에 실패했습니다.");
  }
}
