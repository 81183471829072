import React from 'react'
import { RobotApiDownBtn, RobotApiSubTitle, RobotApiTitle, RobotApiTxtArea, RobotApiWrapper } from './styles';
import ImgSlider from '../../components/introduce/ImgSlider';
import { Icon } from '../../pages/IntroducePage';
import { CiShare1 } from "react-icons/ci";
import { toast } from 'react-toastify';

export const RobotApi = () => {
  return (
    <RobotApiWrapper>
      <RobotApiTxtArea>
        <RobotApiTitle>세계 최다 연동 기술력 보유!</RobotApiTitle>
        <RobotApiSubTitle>
          국내·외 대표 브랜드와 로봇이 다수 연동되어 있으며<br />
          추가 연동 작업 중에 있습니다.
        </RobotApiSubTitle>
      </RobotApiTxtArea>
      <ImgSlider />
      <RobotApiDownBtn onClick={() => toast.info("준비 중입니다.")}>
        API 다운로드
        <Icon><CiShare1 /></Icon>
      </RobotApiDownBtn>
    </RobotApiWrapper>
  )
}

export default React.memo(RobotApi);