import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
// import { GifWrapper, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper, PlatformImg, PlatformImgArea, PlatformImgSecond, PlatformModalBadge, PlatformModalBottom, PlatformModalDesc, PlatformModalDescArea } from './styles';
import {FaArrowLeft} from "react-icons/fa";
import {FaChevronLeft} from "react-icons/fa";
import {FaChevronRight} from "react-icons/fa";
import platformModalBg from '../../../assets/introduce/Image/platformModalBg.png'
import iPad_I from '../../../assets/introduce/Image/iPad_1.png'
import iPad_II from '../../../assets/introduce/Image/platformModalIpad_II.png'
import alarmEx from '../../../assets/introduce/Image/alarmEx.png'
import error from '../../../assets/introduce/Image/error.png'
import siren from '../../../assets/introduce/Image/siren.png'
import alarm from '../../../assets/introduce/Image/alarm.png'
import arrow_left_down from '../../../assets/introduce/Image/Arrow_left_down.png'
import arrow_right_down from '../../../assets/introduce/Image/Arrow_right_down.png'
import manager from '../../../assets/introduce/Image/manager.png'
import SI from '../../../assets/introduce/Image/SI_Icon.png'
import platformPage1_title from '../../../assets/introduce/Image/Modal/Platform/platformPage1_desc.png'
import platformPage1_desc from '../../../assets/introduce/Image/Modal/Platform/platformPage1_title.png'
import platformPage1_subTitle from '../../../assets/introduce/Image/Modal/Platform/platformPage1_subTitle.png'
import platformPage2_title from '../../../assets/introduce/Image/Modal/Platform/platformPage2_title.png'
import platformPage2_desc from '../../../assets/introduce/Image/Modal/Platform/platformPage2_desc.png'
import platformPage2_subTitle from '../../../assets/introduce/Image/Modal/Platform/platformPage2_subTitle.png'
import engineModalBg from '../../../assets/introduce/Image/engineModalBg.png'
import builderModalBg from '../../../assets/introduce/Image/builderModalBg.png'
import styled from 'styled-components';
import {useMediaQuery} from "react-responsive";
import {MobilePlatformModal} from "./Mobile/MobilePlatformModal";


function NextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
      <ModalBackBtn className="next" onClick={onClick}>
        <FaChevronRight className={'CFaChevronRight'}/>
      </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
      <ModalBackBtn className="prev" onClick={onClick}>
        <FaChevronLeft className={'CFaChevronLeft'}/>
      </ModalBackBtn>
  );
}

export const PlatformModal = ({setClicked}: { setClicked: Function }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
      <>
        {isMobile ?
            <MobilePlatformModal  setClicked={setClicked}/>
            :
            <ModalWrapper className="slider-container">
              <ModalProgressBar
                  onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
                  value={slideIndex}
                  type="range"
                  min={0}
                  max={1}
              />
              <LinkerModalContent className='platform' style={{backgroundImage: `url(${platformModalBg})`}}>
                <ModalHeader>
                  <ModalBackBtn onClick={() => setClicked('')}>
                    <FaArrowLeft className={'CFaArrowLeft'}/>
                  </ModalBackBtn>
                </ModalHeader>
                <Slider
                    ref={sliderRef}
                    {...settings}
                >
                  <LinkerModalDisplay>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='platform_page1'>
                        <PlatformModalBadge>Platform</PlatformModalBadge>
                        <PlatformTitleImg src={platformPage1_title}/>
                      </LinkerModalTitle>
                      <PlatformModalDescArea>
                        <PlatformDescImg src={platformPage1_desc}/>
                        <PlatformSubTitleImg src={platformPage1_subTitle}/>
                      </PlatformModalDescArea>
                    </LinkerModalTitleArea>
                    <PlatformImgArea className='platform_page1'>
                      <PlatformImg className='ipadI'>
                        <span>로봇 상태 파악과 명령</span>
                        <IPadImg src={iPad_I}/>
                      </PlatformImg>
                      <PlatformImg className='ipadII'>
                        <span>로봇 운영 데이터 통계</span>
                        <IPadTwoImg src={iPad_II}/>
                      </PlatformImg>
                    </PlatformImgArea>
                  </LinkerModalDisplay>

                  <LinkerModalDisplay style={{justifyContent: 'flex-start'}}>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='platform_page2'>
                        <PlatformModalBadge>Platform</PlatformModalBadge>
                        <PlatformTitleTwoImg src={platformPage2_title} />
                      </LinkerModalTitle>
                      <LinkerModalDesc className='platform_page2'>
                        <PlatformDescTwoImg src={platformPage2_desc} />
                        <PlatformSubTitleTwoImg src={platformPage2_subTitle} />
                      </LinkerModalDesc>
                    </LinkerModalTitleArea>
                    <PlatformImgArea className='platform_page2'>
                      <PlatformImgSecond className='error'>
                        <GenerateErrorSpan className='error'>
                          B호 로봇 - 오류 발생
                          <SirenImg src={siren} className='siren' />
                        </GenerateErrorSpan>

                        <ErrorRedLine></ErrorRedLine>

                        <ErrorImg src={error} className='error' />

                        <ErrorGreenLine style={{width: 2, height: windowWidth >= 716 ? 70 : 40, backgroundColor: '#508B30', padding:0}}></ErrorGreenLine>

                        <ErrorAlarmSpan className='alarm'>
                          오류 알림톡 발송
                          <AlarmImg src={alarm} className='alarm' />
                          <AlarmLeftArrow src={arrow_left_down} className='arrow_left_down' />
                          <AlarmRightArrow src={arrow_right_down} className='arrow_right_down' />
                        </ErrorAlarmSpan>

                        <ErrorIconsSpan className='icons'>
                          <IconManagerImg src={manager} className='manager' />
                          <IconsSIImage src={SI} className='SI' />
                        </ErrorIconsSpan>

                      </PlatformImgSecond>
                      <PlatformImgSecond className='alarmEx'>
                        <AlarmEXImg src={alarmEx} className='alarmEx' />
                        <AlarmExSpan className='alarmEx'>오류 알림톡 예시(한림대)</AlarmExSpan>
                      </PlatformImgSecond>
                    </PlatformImgArea>
                  </LinkerModalDisplay>
                </Slider>
              </LinkerModalContent>
            </ModalWrapper>
        }
      </>
  );
}

export default React.memo(PlatformModal)

const GenerateErrorSpan = styled.span`
  background: linear-gradient(22deg, #4C1010 -0.57%, #DA4343 57.63%, #FFA2A2 97.58%, #BD0000 97.58%, #BDE4ED 97.58%);
  width: 180px;
  height: 42px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;

  @media (max-width: 1920px) {
    width: 9.38vw;
    height: 2.29vw;
    padding: 0.42vw 1.25vw;
    font-size: 0.83vw;
    border-radius: 2.65vw;
  }
`

const SirenImg = styled.img`
  width: 35px;
  height: 35px;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;

  @media (max-width: 1920px) {
    width: 1.82vw;
    height: 1.82vw;
    position: absolute;
    top: -1.46vw;
  }
`

const ErrorRedLine = styled.span`
  width: 2px;
  height: 40px;
  padding: 0;
  background-color: #AD2626;

  @media (max-width: 1920px) {
    width: 0.10vw;
    height: 2.08vw;

  }
`

const ErrorImg = styled.img`
  width: 356.19px;
  height: 180px;
  border-radius: 6px;

  @media (max-width: 1920px) {
    width: 18.54vw;
    height: 9.38vw;
    border-radius: 0.31vw;

  }
`

const ErrorGreenLine = styled.span`
  width: 2px;
  height: 70px;
  background-color: #508B30;
  padding:0;

  @media (max-width: 1920px) {
    width: 0.10vw;
    height: 3.65vw;

  }
`

const ErrorAlarmSpan = styled.span`
  background: linear-gradient(180deg, #B3D3A1 0%, #589C33 33%, #21440D 100%);
  width: 180px;
  height: 42px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  z-index: 99;

  @media (max-width: 1920px) {
    width: 9.38vw;
    height: 2.29vw;
    padding: 0.42vw 1.25vw;
    font-size: 0.83vw;
    border-radius: 2.65vw;
  }
  
`

const AlarmImg = styled.img`
  width: 35px;
  height: 35px;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
  z-index: 999;

  @media (max-width: 1920px) {
    width: 1.82vw;
    height: 1.82vw;
    top: -1.46vw;
  }
`

const AlarmLeftArrow = styled.img`
  object-fit: contain;
  position: absolute;
  width: 50px;
  left: 41px;
  bottom: -49px;

  @media (max-width: 1920px) {
    width: 2.60vw;
    left: 2.14vw;
    bottom: -2.55vw;
  }
  
`

const AlarmRightArrow = styled.img`
  object-fit: contain;
  position: absolute;
  width: 51px;
  bottom: -49px;
  right: 40px;
  
  @media (max-width: 1920px) {
    width: 2.65vw;
    bottom: -2.55vw;
    right: 2.08vw;

  }
`

const ErrorIconsSpan = styled.span`
  display: flex;
  align-items: center;
  margin-top: 130px;
  gap: 70px;
  width: 180px;
  height: 42px;
  padding: 8px 24px;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;

  @media (max-width: 1920px) {
    margin-top: 6.77vw;
    gap: 3.65vw;
    width: 9.38vw;
    height: 2.29vw;
    padding: 0.42vw 1.25vw;
    font-size: 0.83vw;
    border-radius: 2.65vw;
  }
`

const IconManagerImg = styled.img`
  object-fit: contain;
  width: 138px;
  height: auto;

  @media (max-width: 1920px) {
    width: 7.19vw;
  }
`

const IconsSIImage = styled.img`
  object-fit: contain;
  width: 153px;
  height: auto;
  margin-top: 17px;

  @media (max-width: 1920px) {
    width: 7.92vw;
    height: auto;
    margin-top: 0.88vw;
  }
`

const AlarmEXImg = styled.img`
  width: 282px;
  height: 435px;
  border-radius: 6px;

  @media (max-width: 1920px) {
    width: 14.68vw;
    height: 22.66vw;
    border-radius: 0.31vw;
  }
`

const AlarmExSpan = styled.span`
  width: 210px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5220px;
  border: 0.5px solid rgba(255, 255, 255, 0.20);
  background: radial-gradient(100% 100% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;

  @media (max-width: 1920px) {
    width: 10.94vw;
    height: 2.29vw;
    font-size: 0.83vw;
    font-weight: 600;
    border-radius: 27.19vw;
    border: 0.03vw solid rgba(255, 255, 255, 0.20);
    padding: 0.42vw 1.25vw;
  }
  
`









const PlatformTitleImg = styled.img`
  margin-top: 30px;
  width: 989px;

  @media (max-width: 1920px) {
    margin-top: 1.56vw;
    width: 51.51vw;
  }
`

const PlatformDescImg = styled.img`
  width: 368px;
  margin: 46px 0;

  @media (max-width: 1920px) {
    width: 19.17vw;
    margin: 2.40vw 0;
  }
`

const PlatformSubTitleImg = styled.img`
  width: 702px;

  @media (max-width: 1920px) {
    width: 36.56vw;
  }
`

const IPadImg = styled.img`
  width: 390px;

  @media (max-width: 1920px) {
    width: 20.31vw;
  }
`

const IPadTwoImg = styled.img`
  width: 390px;

  @media (max-width: 1920px) {
    width: 20.31vw;
  }
`

const PlatformTitleTwoImg = styled.img`
  margin-top: 30px;
  width: 522px;

  @media (max-width: 1920px) {
    margin-top: 1.56vw;
    width: 27.19vw;
  }
`

const PlatformDescTwoImg = styled.img`
  width: 460px;
  margin-bottom: 36px;

  @media (max-width: 1920px) {
    width: 23.96vw;
    margin-bottom: 1.87vw;
  }
`

const PlatformSubTitleTwoImg = styled.img`
  width: 574px;

  @media (max-width: 1920px) {
    width: 29.89vw;
  }
`

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 66px;
  right: 200px;
  z-index: 9999;
  width: 100px;
  height: 17px;

  @media (max-width: 1920px) {
    top: 3.44vw;
    right: 10.42vw;
    width: 5.21vw;
    height: 0.89vw;
  }
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;

  @media (max-width: 1920px) {
    width: 2.604vw;
    height: 2.604vw;
  }

  &.next {
    top: -43px;
    right: 0;

    @media (max-width: 1920px) {
      top: -2.2396vw;
    }
  }

  &.prev {
    top: -43px;
    right: 60px;

    @media (max-width: 1920px) {
      top: -2.2396vw;
      right: 3.125vw;
    }
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 50px 68px;
  display: flex !important;
  flex-direction: column;
  gap: 45px;
  background-color: #000;

  @media (max-width: 1920px) {
    gap: 2.34vw;
    padding: 2.604vw 3.542vw;
  }
`
const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-left: 80.33px;
  margin-top: 33.85px;

  @media (max-width: 1920px) {
    margin-left: 4.1802vw;
    margin-top: 3.1296vh;

  }
`

const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-left: 80.33px;
  margin-top: 46px;
  margin-bottom: 20px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
    margin-top: 2.39vw;
    margin-bottom: 1.04vw;
  }
  
`


const PlatformModalBadge = styled.div`
  width: 133px;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.56px;

  @media (max-width: 1920px) {
    width: 6.93vw;
    height: 2.29vw;
    border: 0.03vw solid var(rgba(255, 255, 255, 0.20));
    text-shadow: 0px 0px 2.08vw #FFF;
    font-size: 1.46vw;
    letter-spacing: -0.03vw;
  }
`

const PlatformModalDescArea = styled.div`
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }
`

const PlatformImgArea = styled.div`
  display: flex;
  justify-content: flex-end;
  
  &.platform_page2{
    position: relative;
    top: -280px;
    right: 200px;
    
    @media (max-width: 1920px) {
      top: -14.58vw;
      right: 10.42vw;
    }
  }
  
`

const PlatformImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-left: 48px;

  @media (max-width: 1920px) {
    gap: 1.25vw;
    margin-left: 2.50vw;

  }

  span {
    width: 200px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5220px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background: radial-gradient(100% 100% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
    color: #fff;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 1920px) {
      width: 10.42vw;
      height: 2.29vw;
      border: 0.03vw solid rgba(255, 255, 255, 0.20);
      font-size: 0.83vw;
    }
  }


`
const PlatformImgSecond = styled.div`
  &.alarmEx {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    
    @media (max-width: 1920px) {
      gap: 1.77vw;
    }
  }
  

  &.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -100px;
    margin-right: 50px;
    margin-top: -70px;

    @media (max-width: 1920px) {
      margin-left: -5.21vw;
      margin-right: 2.60vw;
      margin-top: -3.65vw;
    }
  }
  
`










