import smallLogo from '../../assets/image/logo.png';

import marosolIcon from '../../assets/image/marosol.png';
import openIcon from '../../assets/icon/GNB_Open.svg';
import { Link } from 'react-router-dom';

import useDatastore from '../../store/dataStore';
import useRobotstore from '../../store/robotStore';

import { useTranslation } from 'react-i18next';
import useUserStore from '../../store/userStore';
import { Permission } from '../../_types/user';

import Menu from '../gnb/Menu';
import gnbData from '../../json/gnb.json';

export default function Gnb() {
  const { t } = useTranslation();

  const { userStatus } = useUserStore();

  const { updateIsShowRobotDetail, updateIsShowMultiRobotDetail } =
    useRobotstore();
  const { updateRobotDetailData } = useDatastore();

  const isSuperAdmin = userStatus === Permission.SUPER_ADMIN;
  const isEngineer = userStatus === Permission.ENGINEER;

  return (
    <div
      onClick={() => {
        updateIsShowRobotDetail(false);
        updateIsShowMultiRobotDetail(false);
        updateRobotDetailData(null);
      }}
      className="z-[300] fixed w-[75px] h-[100vh] bg-white border-r flex flex-col items-center justify-between pr-1"
    >
      <div className="w-[100%] mt-1">
        <Link
          to={process.env.REACT_APP_ENV === 'production' ? '/total' : '/'}
          className="border-l-4 border-l-white flex flex-col items-center w-[100%] pt-3 pb-1 mt-1 mb-2 hover:cursor-pointer"
        >
          <img src={smallLogo} width={30} height={30} alt="logo" />
        </Link>

        {gnbData.map(
          (menu, idx) =>
            (menu.i18nKey !== 'real_time' || isSuperAdmin || isEngineer) &&
            menu.enable && (
              <Menu
                key={idx}
                i18nKey={menu.i18nKey}
                iconPath={menu.iconPath}
                toPath={menu.toPath}
              />
            ),
        )}

        {/* TODO: workflow 개발 완료 후 조건 제거 & 임시 아이콘 변경*/}

        {/* {process.env.NODE_ENV === "development" && (
          <Link
            to="/workflow"
            onMouseEnter={() =>
              setIsHovered([false, false, false, false, false, true])

            }
          >
            <path d="M760-600q-57 0-99-34t-56-86H354q-11 42-41.5 72.5T240-606v251q52 14 86 56t34 99q0 66-47 113T200-40q-66 0-113-47T40-200q0-57 34-99t86-56v-251q-52-14-86-56t-34-98q0-66 47-113t113-47q56 0 98 34t56 86h251q14-52 56-86t99-34q66 0 113 47t47 113q0 66-47 113t-113 47ZM200-120q33 0 56.5-24t23.5-56q0-33-23.5-56.5T200-280q-32 0-56 23.5T120-200q0 32 24 56t56 24Zm0-560q33 0 56.5-23.5T280-760q0-33-23.5-56.5T200-840q-32 0-56 23.5T120-760q0 33 24 56.5t56 23.5ZM760-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113T760-40Zm0-80q33 0 56.5-24t23.5-56q0-33-23.5-56.5T760-280q-33 0-56.5 23.5T680-200q0 32 23.5 56t56.5 24Zm0-560q33 0 56.5-23.5T840-760q0-33-23.5-56.5T760-840q-33 0-56.5 23.5T680-760q0 33 23.5 56.5T760-680ZM200-200Zm0-560Zm560 560Zm0-560Z" />
          </svg>

            <span className="text-center">
              <Trans i18nKey="workflowBuilder" />
            </span>
          </Link>
        )} */}

        {/* <Link
          to="/smartbuilding"
          className={
            pathname === "/smartbuilding" ? selectedMenuStyle : menuStyle
          }
        >
          <img src={smartBuildingIcon} />
          스마트빌딩
        </Link> */}
        {/* <Link
          to="/alarm"
          className={
            pathname === "/alarm"
              ? "w-[100%] py-3 text-xs flex flex-col items-center font-bold border-b text-[#727272] hover:cursor-pointer bg-[#BDE8E1] relative"
              : "w-[100%] py-3 text-xs flex flex-col items-center font-bold border-b text-[#727272] hover:cursor-pointer hover:bg-[#BDE8E1] relative"
          }
        >
          <img src={notificationIcon} />
          <div className="absolute px-1 text-xs font-light text-white bg-red-500 rounded-md right-3 top-1">
            5
          </div>
          알림
        </Link> */}
      </div>

      <div className="flex flex-col items-center justify-center mb-3">
        {/* <Link
          to="/login"
          className="w-[100%] py-3 text-xs flex flex-col items-center font-bold hover:cursor-pointer"
        >
          {!userEmail && <img src={accontIcon} />}
          {userEmail === "dev-test" && <img src={yellowAccountIcon} />}
          {userEmail === "dev-test2" && <img src={greenAccountIcon} />}
        </Link> */}
        <a
          className="w-[100%] flex flex-col items-center hover:cursor-pointer hover:text-[#08B295]"
          target="_blank"
          rel="noreferrer"
          href="https://www.myrobotsolution.com"
        >
          <img src={marosolIcon} className="w-[35%]" alt="marosol logo" />
          <div className="flex items-center">
            <div className="py-2 text-xs text-gray-800">{t('marosol')}</div>
            <img src={openIcon} className="w-[0.8rem] ml-1" alt="open" />
          </div>
        </a>
      </div>
    </div>
  );
}
