import { Trans } from "react-i18next";
import Skeleton from "../../common/Skeleton";

const AllRobotsSkeleton = () => {
  return (
    <div className="relative w-[100%] h-[734px] px-3 py-2">
      <div>
        <div className="text-[#08B295] text-sm cursor-default">
          <Trans i18nKey="full_registration_robot" />
        </div>
        <div className={`flex items-center text-lg font-bold mb-7 `}>
          <div className="text-gray-800">
            0
            <Trans i18nKey="unit" />
          </div>
        </div>
        <div className={`pb-1 w-[100%] flex mobile:flex-row  items-center `}>
          <div className="p-3 tab:pb-7 tab:h-[270px] flex justify-center items-center w-[100%]">
            {
              <div className="w-[200px] h-[200px] flex justify-center items-center">
                <Skeleton className="flex justify-center items-center w-[200px] h-[200px] rounded-full">
                  <div className="rounded-full bg-white w-[100px] h-[100px]"></div>
                </Skeleton>
              </div>
            }
          </div>

          <div className="w-[80%] h-[290px] text-gray-800">
            {<Skeleton className="w-[80%] h-[90%] mt-2 rounded-md" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRobotsSkeleton;
