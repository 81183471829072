import styled from "styled-components";

export const TechnicalWrapper = styled.div`
    width: 100vw;
    /* height: 1930px; */
    min-width: 350px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

// ** Top.tsx =====
export const TechnicalTop = styled.div`
    width: 100%;
    /* height: 770px; */
    min-width: 350px;
    overflow: hidden;
`
export const TechnicalTopDisplay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F7F6FB;
    border-radius: 240px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 75px;
    padding-top: 132px;
    padding-bottom: 45px;
    @media (max-width: 1024px) {
        border-radius: 200px 0 0 0;
    }
    @media (max-width: 800px) {
        border-radius: 150px 0 0 0;
    }
    @media (max-width: 650px) {
        align-items: center;
    }
    @media (max-width: 450px) {
        border-radius: 100px 0 0 0;
        padding-top: 88px;
    }
`
export const TechnicalTopTxt = styled.div`
    display: flex;
    flex-direction: column;
    gap: 47px;
    align-items: center;
    text-align: center;
    @media (max-width: 880px) {
        gap: 30px;
    }
`
export const TechnicalTopTitle = styled.div`
    color: #333;
    font-size: 50px;
    font-weight: 500;
    p {
        font-size: 50px;
        font-weight: 800;
        background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 880px) {
        font-size: 40px;
        p {
            font-size: 40px;
        }
    }
    @media (max-width: 730px) {
        font-size: 32px;
        p {
            font-size: 32px;
        }
    }
    @media (max-width: 550px) {
        font-size: 28px;
        p {
            font-size: 28px;
        }
    }
    @media (max-width: 450px) {
        font-size: 24px;
        p {
            font-size: 24px;
        }
    }
`
export const TechnicalTopSubTitle = styled.div`
    color: #333;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    @media (max-width: 880px) {
        font-size: 26px;
    }
    @media (max-width: 730px) {
        font-size: 22px;
    }
    @media (max-width: 600px) {
        line-height: 28px;
    }
    @media (max-width: 550px) {
        line-height: 26px;
        font-size: 18px;
    }
    @media (max-width: 450px) {
        line-height: 24px;
        font-size: 16px;
    }
`
export const TechnicalTopBtns = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 28px;
    @media (max-width: 1080px) {
        gap: 0px;
    }
    @media (max-width: 650px) {
        flex-wrap: wrap;
        width: 400px;
    }
`
export const TechnicalTopBtn = styled.div`
    padding: 30px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    @media (max-width: 960px) {
        padding: 20px 16px;
    }
    @media (max-width: 770px) {
        padding: 20px 8px;
    }
    @media (max-width: 650px) {
        padding: 20px 16px;
    }
    @media (max-width: 450px) {
        gap: 10px;
    }
`
export const TechnicalTopBtnIcon = styled.div`
    width: 170px;
    height: 170px;
    border-radius: 170px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected {
        background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
    }
    @media (max-width: 1300px) {
        width: 140px;
        height: 140px;
        img {
            width: 110px;
            height: 110px;
        }
    }
    @media (max-width: 880px) {
        width: 110px;
        height: 110px;
        img {
            width: 90px;
            height: 90px;
        }
    }
    @media (max-width: 650px) {
        width: 150px;
        height: 150px;
        img {
            width: 120px;
            height: 120px;
        }
    }
    @media (max-width: 450px) {
        width: 110px;
        height: 110px;
        img {
            width: 90px;
            height: 90px;
        }
    }
`
export const TechnicalTopBtnTxt = styled.p`
    font-size: 24px;
    font-weight: 400;
    color: #111;
    line-height: 32px;
    text-align: center;
    &.selected {
        background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 880px) {
        font-size: 18px;
        line-height: 20px;
    }
    @media (max-width: 450px) {
        font-size: 15px;
        line-height: 20px;
    }
`

// ** Bottom.tsx =====
export const TechnicalBottom = styled.div`
    width: 100%;
    /* height: 1080px; */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(97deg, #18A98E 2.88%, #2F9CB1 83.13%);
    backdrop-filter: blur(2px);
    padding: 136px 308px;
    display: flex;
    justify-content: center;
    gap: 24px;
    overflow: hidden;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding: 33px 308px;
    }
    @media (max-width: 840px) {
        flex-direction: column;
        align-items: center;
        padding: 33px;
    }
`
export const TechnicalBotLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26.5px;
`
export const TechnicalBotRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20.85px;
`
export const TechnicalSelectAreaBadge = styled.p`
    padding: 0px 11px;
    height: 44px;
    text-align: center;
    color: #fff;
    text-shadow: 0px 0px 40px #FFF;
    border-radius: 12px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.56px;
    background: rgba(255, 255, 255, 0.08);
    &.linker {
        width: 103px;
        &.selected {
            background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(24, 172, 255, 0.72) 0%, rgba(24, 172, 255, 0.20) 55.21%, rgba(24, 172, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
        }
    }
    &.builder {
        width: 242px;
        &.selected {
            background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(24, 172, 255, 0.72) 0%, rgba(24, 172, 255, 0.20) 55.21%, rgba(24, 172, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
        }
    }
    &.platform {
        width: 133px;
        &.selected {
            background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(24, 172, 255, 0.72) 0%, rgba(24, 172, 255, 0.20) 55.21%, rgba(24, 172, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
        }
    }
    &.pendant {
        width: 131px;
        &.selected {
            background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(24, 172, 255, 0.72) 0%, rgba(24, 172, 255, 0.20) 55.21%, rgba(24, 172, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
        }
    }
    &.engine {
        width: 239px;
        &.selected {
            background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(24, 172, 255, 0.72) 0%, rgba(24, 172, 255, 0.20) 55.21%, rgba(24, 172, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
        }
    }
    @media (max-width: 1400px) {
        font-size: 22px;
        padding: 0;
        &.linker {
            width: 83px;
        }
        &.builder {
            width: 202px;
        }
        &.platform {
            width: 103px;
        }
        &.pendant {
            width: 101px;
        }
        &.engine {
            width: 209px;
        }
    }
    @media (max-width: 1024px) {
        font-size: 28px;
        padding: 0;
        &.linker {
            width: 103px;
        }
        &.builder {
            width: 242px;
        }
        &.platform {
            width: 133px;
        }
        &.pendant {
            width: 131px;
        }
        &.engine {
            width: 239px;
        }
    }
    @media (max-width: 840px) {
        font-size: 18px;
        height: 32px;
        padding: 0;
        &.linker {
            width: 73px;
        }
        &.builder {
            width: 162px;
        }
        &.platform {
            width: 93px;
        }
        &.pendant {
            width: 91px;
        }
        &.engine {
            width: 159px;
        }
    }
    @media (max-width:  588px) {
        font-size: 14px;
        height: 22px;
        &.linker {
            width: 53px;
        }
        &.builder {
            width: 132px;
        }
        &.platform {
            width: 68px;
        }
        &.pendant {
            width: 67px;
        }
        &.engine {
            width: 129px;
        }
    }
`
export const TechnicalSelectAreaTxt = styled.p`
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    @media (max-width: 1400px) {
        font-size: 28px;
        line-height: 30px;
    }
    @media (max-width: 840px) {
        font-size: 22px;
        line-height: 24px;
    }
    @media (max-width: 588px) {
        font-size: 16px;
        line-height: 19px;
    }
`
export const TechnicalSelectArea = styled.div`
    cursor: pointer;
    position: relative;
    width: 640px;
    padding: 32px 32px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    border: 1px solid #fff;
    background: linear-gradient(0deg, rgba(106, 182, 248, 0.06) 0%, rgba(106, 182, 248, 0.06) 100%), rgba(0, 0, 0, 0.10);
    img {
        position: absolute;
        object-fit: contain;
    }
    &.selected {
        border: 4px solid #18ACFF;
        background: radial-gradient(62.16% 49.02% at 50% 100%, rgba(13, 141, 245, 0.40) 0%, rgba(9, 134, 242, 0.00) 100%), linear-gradient(0deg, rgba(106, 182, 248, 0.06) 0%, rgba(106, 182, 248, 0.06) 100%), rgba(0, 0, 0, 0.10);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    }
    &.linker {
        height: 528px;
        img {
            bottom: 24px;
        }
    }
    &.builder {
        height: 250px;
        img {
            bottom: 24px;
            right: 120px;
        }
    }
    &.platform {
        height: 254px;
        img {
            bottom: 0;
            right: 130px;
        }
    }
    &.pendant {
        height: 254px;
        img {
            bottom: 0;
            right: 100px;
        }
    }
    &.engine {
        height: 254px;
        img {
            bottom: 0;
            right: 80px;
        }
    }
    @media (max-width: 1400px) {
        width: 480px;
        gap: 16px;
        &.linker {
            img {
                width: 340px;
            }
        }
        &.builder {
            img {
                width: 149px;
                right: 90px;
            }
        }
        &.platform {
            img {
                width: 187px;
                right: 70px;
            }
        }
        &.pendant {
            img {
                width: 146px;
                right: 75px;
            }
        }
        &.engine {
            img {
                width: 208px;
            }
        }
    }
    @media (max-width: 1024px) {
        width: 800px;
        gap: 20px;
        &.linker {
            height: 428px;
            img {
                width: 320px;
            }
        }
        &.builder {
            img {
                width: 190px;
                right: 120px;
            }
        }
        &.platform {
            img {
                width: 220px;
                right: 100px;
            }
        }
        &.pendant {
            img {
                width: 220px;
                right: 120px;
            }
        }
        &.engine {
            img {
                width: 248px;
                right: 110px;
            }
        }
    }
    @media (max-width: 840px) {
        width: 550px;
        padding: 16px;
        gap: 10px;
        &.linker {
            height: 328px;
            img {
                width: 250px;
            }
        }
        &.builder {
            img {
                width: 150px;
                right: 120px;
            }
        }
        &.platform {
            img {
                width: 190px;
                right: 100px;
            }
        }
        &.pendant {
            img {
                width: 190px;
                right: 120px;
            }
        }
        &.engine {
            img {
                width: 218px;
                right: 110px;
            }
        }
    }
    @media (max-width: 588px) {
        width: 337px;
        padding: 16px;
        gap: 10px;
        &.linker {
            height: 258px;
            img {
                width: 210px;
                bottom: 10px;
            }
        }
        &.builder {
            height: 125px;
            img {
                width: 100px;
                right: 50px;
                bottom: 10px;
            }
        }
        &.platform {
            height: 125px;
            img {
                width: 113px;
                right: 50px;
            }
        }
        &.pendant {
            height: 125px;
            img {
                width: 108px;
                right: 50px;
            }
        }
        &.engine {
            height: 125px;
            img {
                width: 124px;
                right: 50px;
            }
        }
    }
`
export const TechnicalSelectAreaArrow = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0.10);
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(5px);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 32px;
    bottom: 24px;
    cursor: pointer;
    @media (max-width: 588px) {
        width: 25px;
        height: 25px;
        right: 16px;
        bottom: 16px;
        > * {
            scale: 0.8;
        }
    }
`