import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
// import { EngineGifWrapper, EngineModalBadge, EngineModalDesc, EngineModalDescArea, GifWrapper, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper, PlatformImg, PlatformImgArea, PlatformImgSecond, PlatformModalBadge, PlatformModalBottom, PlatformModalDesc, PlatformModalDescArea } from './styles';
import { FaArrowLeft, FaYoutube } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import engine_page1 from '../../../../assets/introduce/Image/engine_page1.gif'
import engine_page2 from '../../../../assets/introduce/Image/engine_page2.gif'
import { Icon } from '../../../../pages/IntroducePage';
import enginePage1_title from '../../../../assets/introduce/Image/Modal/Engine/enginePage1_title.png'
import enginePage1_desc from '../../../../assets/introduce/Image/Modal/Engine/enginePage1_desc.png'
import enginePage2_title from '../../../../assets/introduce/Image/Modal/Engine/enginePage2_title.png'
import enginePage2_desc from '../../../../assets/introduce/Image/Modal/Engine/enginePage2_desc.png'
import enginePage2_subTitle from '../../../../assets/introduce/Image/Modal/Engine/enginePage2_subTitle.png'
import engineModalBg from '../../../../assets/introduce/Image/engineModalBg.png'
import styled from "styled-components";

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ModalBackBtn className="next" onClick={onClick}>
      <FaChevronRight className={'CMobileFaChevronRight'} />
    </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ModalBackBtn className="prev" onClick={onClick}>
      <FaChevronLeft className={'CMobileFaChevronLeft'} />
    </ModalBackBtn>
  );
}

export const MobileEngineModal = ({ setClicked }: {setClicked: Function}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
    <ModalWrapper className="slider-container">
      <ModalProgressBar
        onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
        value={slideIndex}
        type="range"
        min={0}
        max={1}
      />
      <LinkerModalContent className='engine'>
        <ModalHeader>
          <ModalBackBtn onClick={() => setClicked('')}>
            <FaArrowLeft className={'CMobileFaArrowLeft'}/>
          </ModalBackBtn>
        </ModalHeader>
        <Slider
          ref={sliderRef}
          {...settings}
        >
          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='engine_page1'>
                <EngineModalBadge>Workflow Engine</EngineModalBadge>
                <EngineTitleImg src={enginePage1_title} />
              </LinkerModalTitle>
              <LinkerModalDesc className='engine_page1'>
                <EngineDescImg src={enginePage1_desc} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <EngineGifWrapper className='engine_page1' >
              <EngineGIF src={engine_page1} />
            </EngineGifWrapper>
          </LinkerModalDisplay>

          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='engine_page2'>
                <EngineModalBadge>Workflow Engine</EngineModalBadge>
                <EngineTitleTwoImg src={enginePage2_title} />
              </LinkerModalTitle>
              <LinkerModalDesc className='engine_page2'>
                <EngineDescTwoImg src={enginePage2_desc} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <EngineModalDescArea>
              <EngineSubTitleTwoImg src={enginePage2_subTitle} />
            </EngineModalDescArea>
            <EngineGifWrapper className='engine_page2'>
              <EngineTwoGIF src={engine_page2} />
              <a href='https://www.youtube.com/watch?v=u5c78VVjCEc' target='blank'><Icon><FaYoutube /></Icon>Workflow Engine 기능 상세보기</a>
            </EngineGifWrapper>
          </LinkerModalDisplay>
        </Slider>
      </LinkerModalContent>
    </ModalWrapper>
  );
}

export default React.memo(MobileEngineModal)

const EngineTitleImg = styled.img`
  margin-top: 3.91vw;
  margin-bottom: 3.39vw;
  width: 76.56vw;
`

const EngineDescImg = styled.img`
  width: 79.3vw;
`

const EngineGIF = styled.img`
  width: 89.84vw;
  margin-top: 3.91vw;
`


const EngineTitleTwoImg = styled.img`
  margin-top: 3.91vw;
  margin-bottom: 3.39vw;
  width: 60.25vw;
`


const EngineDescTwoImg = styled.img`
  width: 89.65vw;
  margin-bottom: 2.08vw;
`

const EngineSubTitleTwoImg = styled.img`
  width: 60.81vw;
`

const EngineTwoGIF = styled.img`
  width: 102.6vw;
`


const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 5.98958vw;
  right: 22.1354vw;
  z-index: 9999;
  width: 6.51042vw;
  height: 2.1875vw;


`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 6.51042vw;
  height: 6.51042vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;


  &.next {
    top: -5.59948vw;
    right: 0;
  }

  &.prev {
    top: -5.59948vw;
    right: 7.8125vw;
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 3.90625vw 5.20833vw;
  display: flex !important;
  flex-direction: column;
  gap: 5.85938vw;
  background-color: #000;



  &.engine {
    background-image: url(${engineModalBg});
    background-color: #000;
  }
`

const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90.625vw;
  width: auto;
  margin-top: 4.40495vw;


`

const LinkerModalDesc = styled.p`
  font-size: 3.91vw;
  color: #fff;
  font-weight: 400;
  line-height: 4.43vw;
  white-space: nowrap;


 
`


const EngineModalBadge = styled.div`
  width: 31.08vw;
  height: 5.73vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 3.64583vw;
  font-weight: 700;
  letter-spacing: -0.0729vw;

`

const EngineGifWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

 
  
 
  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 0.78vw;
    font-size: 2.34vw;
    line-height: 2.4vw;
 
  }
  
  
`
const EngineModalDescArea = styled.div`

  
`
