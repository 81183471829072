import styled from "styled-components";
import '../../assets/introduce/pretendardvariable.css';
import {Spin} from "antd";

export const HomeWrapper = styled.div`
    width: 100%;
    font-family: 'Pretendard';
    overflow: hidden;
    min-width: 350px;
`
export const HomeTitleArea = styled.div`
    color: #fff;
    position: absolute;
    top: 390px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10;
    z-index: 9;
`
export const HomeTitle = styled.h1`
    font-size: 120px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    user-select: none;
    white-space: nowrap;
    @media (max-width: 1380px) {
        font-size: 100px;
    }
    @media (max-width: 1100px) {
        font-size: 80px;
    }
    @media (max-width: 850px) {
        font-size: 65px;
    }
    @media (max-width: 450px) {
        font-size: 56px;
    }
`
export const HomeSubTitle = styled.h4`
    color: #FFF;
    text-shadow: 0px 4px 10px rgba(13, 13, 13, 0.20);
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1.6px;
    margin: 0;
    user-select: none;
    @media (max-width: 1380px) {
        font-size: 28px;
    }
    @media (max-width: 1100px) {
        font-size: 24px;
    }
    @media (max-width: 850px) {
        font-size: 18px;
    }
    @media (max-width: 450px) {
        font-size: 15px;
    }
`
export const HomeDisplay = styled.div`
    width: 100vw;
    height: 1080px;
    overflow: hidden;
    position: relative;


    video {
        max-width: none !important;
        object-fit: cover !important;
        object-position: center !important;
    }


`
export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const LoadingSpin = styled(Spin)`
    transform: scale(2);
`;
export const VideoViewBlacked = styled.div`
    background: linear-gradient(180deg, rgba(13, 13, 13, 0.50) 0%, rgba(13, 13, 13, 0.00) 100%);
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
`
export const VideoView = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
`
export const VideoBtns = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 26px;
    background-color: transparent;
    bottom: 182px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;

    > div {
        display: flex;
        align-items: flex-end;
        gap: 26px;
    }

    @media (max-width: 1380px) {
        gap: 16px;
        div {
            gap: 16px;
        }
    }
    @media (max-width: 950px) {
        flex-direction: column;
    }
`
export const VideoBtn = styled.div`
    position: relative;
    border: 1.3px solid #B3B3B3;
    color: #fff;
    width: 260px;
    height: 130px;
    border-radius: 18.2px;
    box-shadow: 0px 0px 15.6px 0px rgba(13, 13, 13, 0.20);
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18.2px;
        z-index: 9;
    }

    &.select {
        border: 3.9px solid #fff;
    }

    @media (max-width: 1380px) {
        width: 220px;
    }
    @media (max-width: 1380px) {
        width: 180px;
    }
    @media (max-width: 450px) {
        width: 151px;
        height: 50px;
    }
`
export const VideoBtnBlacked = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 17px;
    z-index: 1;
    background: linear-gradient(95deg, rgba(55, 124, 111, 0.00) 1.07%, rgba(13, 13, 13, 0.00) 1.08%, rgba(13, 13, 13, 0.50) 97.48%);
`
export const VideoDesc = styled.p`
    color: #fff;
    font-size: 26px;
    font-weight: 800;
    position: absolute;
    bottom: 10px;
    right: 16px;
    z-index: 9;
    @media (max-width: 1380px) {
        font-size: 24px;
    }
    @media (max-width: 1100px) {
        font-size: 18px;
    }
    @media (max-width: 450px) {
        font-size: 15px;
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
    }
`