import React, { useEffect, useState } from 'react'
import { ManageCountTxt, ManageSubTitle, ManageTitle, ManageTxtArea, ManageWrapper, SollinkDisplayImg } from './styles'
import infinite from '../../assets/introduce/Image/infinite.png'
import sollinkDisplay from '../../assets/introduce/Image/sollinkDisplay.png'

export const ManageRobot = () => {
  const [count, setCount] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      setScroll(currentScrollY);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      (windowWidth >= 1320 && scroll >= 3336) ||
      (windowWidth >= 1024 && scroll >= 3210) ||
      (windowWidth >= 800 && scroll >= 3782) ||
      (windowWidth >= 720 && scroll >= 4060) ||
      (windowWidth >= 540 && scroll >= 3734) ||
      (windowWidth >= 500 && scroll >= 3149) ||
      (windowWidth >= 450 && scroll >= 2755) ||
      (windowWidth <= 450 && scroll >= 2705)
    ) {
      let intervalDelay = 10;
      const interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount < 997) {
            return prevCount + 9;
          } else if (prevCount < 999) {
            intervalDelay += 5;
            return prevCount + 11;
          } else {
            clearInterval(interval);
            return prevCount;
          }
        });
      }, intervalDelay);
  
      return () => clearInterval(interval);
    }
  }, [scroll, windowWidth]);
  
  
  

  return (
    <ManageWrapper>
      <ManageTxtArea>
        <ManageTitle>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {count >= 999 ? (
              <img src={infinite} alt="Infinite" />
            ) : (
              <>
                <ManageCountTxt>{count}</ManageCountTxt>
              </>
            )}
            <h4>개의 로봇<span>을</span></h4>
          </div>
          <h5>관리해보세요.</h5>
        </ManageTitle>
        <ManageSubTitle>
          기존 사용하던 로봇도 <h1>시리얼 넘버만 기입하면</h1><br style={{display: windowWidth >= 800 ? 'block' : 'none'}} />
          <h2>SOLlink의 무한한 서비스를 </h2><h3>경험</h3>할 수 있습니다.
        </ManageSubTitle>
      </ManageTxtArea>
      {/* <ThumbUpImg src={thumbUp} /> */}
      <SollinkDisplayImg src={sollinkDisplay} />
    </ManageWrapper>
  )
}

export default React.memo(ManageRobot)