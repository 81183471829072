import ServiceBarChart from "./chart/ServiceBarChart";
import MostErrorRobots from "./chart/MostErrorRobots";
import MostUsedRobots from "./chart/MostUsedRobots";
import SuccessRateBarChart from "./chart/SuccessRateBarChart";
import MostUsedDestinationBarChart from "./chart/MostUsedDestinationBarChart";
import MostUsedLineChart from "./chart/MostUsedLineChart";

import useUserStore from "../../store/userStore";
import {Suspense} from "react";

//import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import AllRobots from "./chart/AllRobotsDonutChart";
import AllRobotsSkeleton from "../loading/skeleton/AllRobotsSkeleton";

// const ErrorFallback: React.FC<FallbackProps> = ({ error }) => (
//   <div>
//     <p>에러 발생:</p>
//     <pre>{error.message}</pre>
//   </div>
// );

export default function ChartContainer(period: any) {
    const {selectedUserAuthSite} = useUserStore();

    const siteQuery = selectedUserAuthSite.join(",");

    const startDate = period.period.startDate;
    const endDate = period.period.endDate;

    return (
        <section className="px-12 pt-3 pb-10">
            <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
                <div className="w-full tab:w-[65.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <SuccessRateBarChart/>
                </div>
                <div className="w-full tab:w-[35.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <MostUsedRobots startDate={startDate} endDate={endDate}/>
                </div>
            </section>
            <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
                <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <ServiceBarChart/>
                </div>
                <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <MostUsedDestinationBarChart startDate={startDate} endDate={endDate}/>
                </div>
            </section>
            <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
                <div className="w-full tab:w-[100%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <MostErrorRobots startDate={startDate} endDate={endDate}/>
                </div>
            </section>
            <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
                <div className="w-full tab:w-[65.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <MostUsedLineChart startDate={startDate} endDate={endDate}/>
                </div>
                <div className="w-full tab:w-[35.5%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
                    <Suspense fallback={<AllRobotsSkeleton/>}>
                        {siteQuery ? (
                            <AllRobots siteQuery={siteQuery} />
                        ) : (
                            <AllRobotsSkeleton/>
                        )}
                    </Suspense>

                </div>
            </section>
        </section>
    );
}
