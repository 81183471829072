import React, { useEffect, useState } from 'react'
import { HomeDisplay, HomeSubTitle, HomeTitle, HomeTitleArea, HomeWrapper, LoadingSpin, LoadingWrapper, VideoBtn, VideoBtnBlacked, VideoBtns, VideoDesc, VideoView, VideoViewBlacked } from './styles';
import PendentImg from '../../assets/introduce/Image/keyVisual_Pendant_thumbnail.jpg'
import LinkerImg from '../../assets/introduce/Image/keyVisual_Linker_thumbnail.jpg'
import BuilderImg from '../../assets/introduce/Image/keyVisual_Builder_thumbnail.jpg'
import EngineImg from '../../assets/introduce/Image/keyVisual_Engine_thumbnail.jpg'

import ReactPlayer from 'react-player';


export const Home = () => {
  const [select, setSelect] = useState('Pendent');
  const [video, setVideo] = useState(process.env.PUBLIC_URL +'/Video/Pendant.mp4');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // setLoading(true);

    if (select == 'Pendent') {
      setVideo(process.env.PUBLIC_URL +'/Video/Pendant.mp4')
    } else if (select == 'Linker') {
      setVideo(process.env.PUBLIC_URL +'/Video/Linker.mp4')
    } else if (select == 'Builder') {
      setVideo(process.env.PUBLIC_URL +'/Video/Builder.mp4')
    } else if (select == 'Engine') {
      setVideo(process.env.PUBLIC_URL +'/Video/Engine.mp4')
    }

    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);

  }, [select])
  
  return (
    <HomeWrapper>
      <HomeDisplay>
        {/*{loading && <LoadingWrapper><LoadingSpin /></LoadingWrapper>}*/}
        <ReactPlayer
            url={video}
            playing={true}
            width={"100%"}
            height={"100%"}
            loop={true}
            muted={true}
            playsinline={true}
        />
        <VideoViewBlacked />
        <HomeTitleArea>
          <HomeTitle>Global No.1</HomeTitle>
          <HomeSubTitle>다종 로봇 통합관제시스템 Platform</HomeSubTitle>
        </HomeTitleArea>
        <VideoBtns>
          <div>
            <VideoBtn 
              className={select == 'Pendent' ? 'select' : ''}
              onClick={() => setSelect('Pendent')}
            >
              <img src={PendentImg} alt='키비주얼 Pendent 썸네일' />
              <VideoDesc>Pendent</VideoDesc>
              <VideoBtnBlacked />
            </VideoBtn>
            <VideoBtn 
              className={select == 'Linker' ? 'select' : ''}
              onClick={() => setSelect('Linker')}
            >
              <img src={LinkerImg} alt='키비주얼 Linker 썸네일' />
              <VideoDesc>Linker</VideoDesc>
              <VideoBtnBlacked />
            </VideoBtn>
          </div>
          <div>
            <VideoBtn 
              className={select == 'Builder' ? 'select' : ''}
              onClick={() => setSelect('Builder')}
            >
              <img src={BuilderImg} alt='키비주얼 Builder 썸네일' />
              <VideoDesc>Workflow Builder</VideoDesc>
              <VideoBtnBlacked />
            </VideoBtn>
            <VideoBtn 
              className={select == 'Engine' ? 'select' : ''}
              onClick={() => setSelect('Engine')}
            >
              <img src={EngineImg} alt='키비주얼 Engine 썸네일' />
              <VideoDesc>Workflow Engine</VideoDesc>
              <VideoBtnBlacked />
            </VideoBtn>
          </div>
        </VideoBtns>
      </HomeDisplay>
      
    </HomeWrapper>
  )
}

export default React.memo(Home); 