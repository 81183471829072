import React from "react";
import UserIcon from "./UserIcon";
import Gnb from "./Gnb";
import LoginModal from "../user/LoginModal";
import { useLocation } from "react-router-dom";
import useLoginStore from "../../store/loginStore";

const LayoutWrapper = () => {
  const { pathname } = useLocation();
  const { isShowLoginModal } = useLoginStore();
  return (
    <>
      {isShowLoginModal && <LoginModal />}
      <UserIcon />
      {pathname !== "/" &&
        pathname !== "/verification" &&
        pathname !== "/signup" && <Gnb />}
    </>
  );
};

export default LayoutWrapper;
