import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
import {FaArrowLeft} from "react-icons/fa";
import {FaChevronLeft} from "react-icons/fa";
import {FaChevronRight} from "react-icons/fa";
import platformModalBg from '../../../../assets/introduce/Image/platformModalBg.png'
import iPad_I from '../../../../assets/introduce/Image/iPad_1.png'
import iPad_II from '../../../../assets/introduce/Image/platformModalIpad_II.png'
import platformPage1_title from '../../../../assets/introduce/Image/Modal/Platform/platformPage1_desc.png'
import platformPage1_desc from '../../../../assets/introduce/Image/Modal/Platform/platformPage1_title.png'
import platformPage1_subTitle from '../../../../assets/introduce/Image/Modal/Platform/platformPage1_subTitle.png'
import platformPage2_title from '../../../../assets/introduce/Image/Modal/Platform/platformPage2_title.png'
import platformPage2_desc from '../../../../assets/introduce/Image/Modal/Platform/platformPage2_desc.png'
import platformPage2_subTitle from '../../../../assets/introduce/Image/Modal/Platform/platformPage2_subTitle.png'
import styled from 'styled-components';
import alarmEx from '../../../../assets/introduce/Image/alarmEx.png'
import error from '../../../../assets/introduce/Image/error.png'
import siren from '../../../../assets/introduce/Image/siren.png'
import alarm from '../../../../assets/introduce/Image/alarm.png'
import arrow_left_down from '../../../../assets/introduce/Image/Arrow_left_down.png'
import arrow_right_down from '../../../../assets/introduce/Image/Arrow_right_down.png'
import manager from '../../../../assets/introduce/Image/manager.png'
import SI from '../../../../assets/introduce/Image/SI_Icon.png'


function NextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <ModalBackBtn className="next" onClick={onClick}>
      <FaChevronRight className={'CMobileFaChevronRight'}/>
    </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <ModalBackBtn className="prev" onClick={onClick}>
      <FaChevronLeft className={'CMobileFaChevronLeft'}/>
    </ModalBackBtn>
  );
}

export const MobilePlatformModal = ({setClicked}: { setClicked: Function }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
    <ModalWrapper className="slider-container">
      <ModalProgressBar
        onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
        value={slideIndex}
        type="range"
        min={0}
        max={1}
      />
      <LinkerModalContent className='platform' style={{backgroundImage: `url(${platformModalBg})`}}>
        <ModalHeader>
          <ModalBackBtn onClick={() => setClicked('')}>
            <FaArrowLeft className={'CMobileFaArrowLeft'}/>
          </ModalBackBtn>
        </ModalHeader>
        <Slider
          ref={sliderRef}
          {...settings}
        >
          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='platform_page1'>
                <PlatformModalBadge>Platform</PlatformModalBadge>
                <PlatformTitleImg src={platformPage1_title}/>
              </LinkerModalTitle>
              <PlatformModalDescArea>
                <PlatformDescImg src={platformPage1_desc}/>
                <PlatformSubTitleImg src={platformPage1_subTitle}/>
              </PlatformModalDescArea>
            </LinkerModalTitleArea>
            <PlatformImgArea className='platform_page1'>
              <PlatformImg className='ipadI'>
                <span>로봇 상태 파악과 명령</span>
                <IPadImg src={iPad_I}/>
              </PlatformImg>
              <PlatformImg className='ipadII'>
                <span>로봇 운영 데이터 통계</span>
                <IPadTwoImg src={iPad_II}/>
              </PlatformImg>
            </PlatformImgArea>
          </LinkerModalDisplay>

          <LinkerModalDisplay style={{justifyContent: 'flex-start'}}>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='platform_page2'>
                <PlatformModalBadge>Platform</PlatformModalBadge>
                <PlatformTitleTwoImg src={platformPage2_title} />
              </LinkerModalTitle>
              <LinkerModalDesc className='platform_page2'>
                <PlatformDescTwoImg src={platformPage2_desc} />
                <PlatformSubTitleTwoImg src={platformPage2_subTitle} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <PlatformImgArea className='platform_page2'>
              <PlatformImgSecond className='error'>
                <GenerateErrorSpan className='error'>
                  B호 로봇 - 오류 발생
                  <SirenImg src={siren} className='siren' />
                </GenerateErrorSpan>

                <ErrorRedLine></ErrorRedLine>

                <ErrorImg src={error} className='error' />

                <ErrorGreenLine style={{width: 2, height: windowWidth >= 716 ? 70 : 40, backgroundColor: '#508B30', padding:0}}></ErrorGreenLine>

                <ErrorAlarmSpan className='alarm'>
                  오류 알림톡 발송
                  <AlarmImg src={alarm} className='alarm'/>
                  <AlarmLeftArrow src={arrow_left_down} className='arrow_left_down' />
                  <AlarmRightArrow src={arrow_right_down} className='arrow_right_down' />
                </ErrorAlarmSpan>

                <ErrorIconsSpan className='icons'  style={{marginTop: '80px'}}>
                  <IconManagerImg src={manager} className='manager' />
                  <IconsSIImage src={SI} className='SI' />
                </ErrorIconsSpan>

              </PlatformImgSecond>
              <PlatformImgSecond className='alarmEx'>
                <AlarmEXImg src={alarmEx} className='alarmEx' />
                <AlarmExSpan className='alarmEx'>오류 알림톡 예시(한림대)</AlarmExSpan>
              </PlatformImgSecond>
            </PlatformImgArea>
          </LinkerModalDisplay>
        </Slider>
      </LinkerModalContent>
    </ModalWrapper>
  );
}

export default React.memo(MobilePlatformModal)

const GenerateErrorSpan = styled.span`
  background: linear-gradient(22deg, #4C1010 -0.57%, #DA4343 57.63%, #FFA2A2 97.58%, #BD0000 97.58%, #BDE4ED 97.58%);
  width: 23.44vw;
  height: 5.47vw;
  padding: 1.04vw 3.12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 2.08vw;
  font-weight: 700;
  border-radius: 6.51vw;
  
`

const SirenImg = styled.img`
  width: 4.56vw;
  height: 4.56vw;
  position: absolute;
  top: -3.65vw;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;


`

const ErrorRedLine = styled.span`
  width: 0.26vw;
  height: 5.21vw;
  padding: 0;
  background-color: #AD2626;
  
`

const ErrorImg = styled.img`
  width: 46.24vw;
  height: 23.44vw;
  border-radius: 0.78vw;

`

const ErrorGreenLine = styled.span`
  width: 0.26vw;
  height: 9.11vw;
  background-color: #508B30;
  padding:0;
  
`

const ErrorAlarmSpan = styled.span`
  background: linear-gradient(180deg, #B3D3A1 0%, #589C33 33%, #21440D 100%);
  width: 23.44vw;
  height: 5.47vw;
  padding: 1.04vw 3.12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 2.08vw;
  font-weight: 700;
  border-radius: 6.51vw;
  z-index: 99;
  
  
`

const AlarmImg = styled.img`
  width: 4.56vw;
  height: 4.56vw;
  position: absolute;
  top: -3.65vw;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
  z-index: 999;
  
`

const AlarmLeftArrow = styled.img`
  object-fit: contain;
  position: absolute;
  width: 6.51vw;
  left: 5.34vw;
  bottom: -6.38vw;
`

const AlarmRightArrow = styled.img`
  object-fit: contain;
  position: absolute;
  width: 6.64vw;
  bottom: -6.38vw;
  right: 5.21vw;
  
`

const ErrorIconsSpan = styled.span`
  display: flex;
  align-items: center;
  margin-top: 130px;
  gap: 9.11vw;
  width: 23.44vw;
  height: 5.47vw;
  padding: 1.04vw 3.12vw;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 2.08vw;
  font-weight: 700;
  border-radius: 50px;
  
`

const IconManagerImg = styled.img`
  object-fit: contain;
  width: 17.97vw;
  height: auto;
  
`

const IconsSIImage = styled.img`
  object-fit: contain;
  width: 19.92vw;
  height: auto;
  margin-top: -3.22vw;

`

const AlarmEXImg = styled.img`
  width: 36.72vw;
  height: 56.64vw;
  border-radius: 0.78vw;


`

const AlarmExSpan = styled.span`
  width: 27.34vw;
  height: 5.73vw;
  font-size: 2.08vw;
  font-weight: 600;
  border-radius: 5220px;
  border: 0.5px solid rgba(255, 255, 255, 0.20);
  background: radial-gradient(100% 100% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  padding: 1.04vw 3.12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;


  
`









const PlatformTitleImg = styled.img`
  margin-top: 3.91vw;
  width: 79.3vw;
`

const PlatformDescImg = styled.img`
  width: 38.8vw;
  margin: 2.08vw 0;
`

const PlatformSubTitleImg = styled.img`
  width: 65.36vw;
`

const IPadImg = styled.img`
  width: 40.36vw;
`

const IPadTwoImg = styled.img`
  width: 40.36vw;
`

const PlatformTitleTwoImg = styled.img`
  margin-top: 3.91vw;
  width: 48.44vw;
`

const PlatformDescTwoImg = styled.img`
  width: 53.39vw;
  margin-bottom: 2.08vw;
`

const PlatformSubTitleTwoImg = styled.img`
  width: 48.7vw;
`


const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 5.98958vw;
  right: 22.1354vw;
  z-index: 9999;
  width: 6.51042vw;
  height: 2.1875vw;


`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 6.51042vw;
  height: 6.51042vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;


  &.next {
    top: -5.59948vw;
    right: 0;
  }

  &.prev {
    top: -5.59948vw;
    right: 7.8125vw;
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 3.90625vw 5.20833vw;
  display: flex !important;
  flex-direction: column;
  gap: 5.85938vw;
  background-color: #000;
  
`
const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-top: 33.85px;
  
`

const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-top: 26px;
  margin-bottom: 20px;

  
`


const PlatformModalBadge = styled.div`
  width: 17.32vw;
  height: 5.73vw;
  padding: 0 1.56vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 3.64583vw;
  font-weight: 700;
  letter-spacing: -0.0729vw;
`

const PlatformModalDescArea = styled.div`
  


`

const PlatformImgArea = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 7.51vw;
  
  
  &.platform_page2{
    position: relative;
    
  }
  
`

const PlatformImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.13vw;
  align-items: center;



  span {
    width: 26.04vw;
    height: 5.73vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5220px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background: radial-gradient(100% 100% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
    color: #fff;
    font-size: 2.08vw;
    font-weight: 600;
    
  }


`
const PlatformImgSecond = styled.div`
  &.alarmEx {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    

  }
  

  &.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin-left: -130px;
    //margin-right: 50px;
    //margin-top: -70px;


  }
  
`










