import { ReturnData } from "./common";

export interface PeriodType {
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export interface PeriodWithSiteId extends PeriodType {
  siteIds: string[];
}

export interface ModelTypes {
  cnt: number;
  modelType: string;
  modelTypeEn: string;
}

export interface AllRoborts extends ReturnData {
  modelTypes: ModelTypes[];
  totalCnt: number;
}

export interface ErrorRobotDataType {
  cnt: number;
  errorType: string;
  errorTypeKr: string;
}

export interface ErrorRobotType {
  deviceId: string;
  errorDatas: ErrorRobotDataType[];
  robotName: string;
}

export interface RobotServiceCount extends ReturnData {
  datasByMonth: RobotServeiceDataByMonth[];
  totalAvg: number;
  totalSum: number;
}

export interface RobotServeiceDataByMonth {
  datas: ServiceData[];
  date: string;
}

export interface ServiceData extends ReturnData {
  cnt: number;
  robotModelType: string;
  robotModelTypeEn: string;
}

export interface MostUsedDestination {
  deviceId: string;
  robotName: string;
  targetPoi: string;
  targetPoiCnt: number;
  targetPoiName: string;
}

export interface SuccessRate {
  data: SuccessRateData[];
  date: string;
}

export interface SuccessRateData {
  ERROR: number;
  RESULT: number;
  SUCCESS: number;
}

export enum RobotModels {
  CLEAN_BOT = "CLEAN_BOT",
  SERVE_BOT_DRAWER = "SERVE_BOT_DRAWER",
  SERVE_BOT_TRAY = "SERVE_BOT_TRAY",
  DISINFECTION_BOT = "DISINFECTION_BOT",
  GUIDE_BOT = "GUIDE_BOT",
}
