import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserData from '../hooks/useUserData';
import useAuth from '../hooks/useAuth';
import { DeviceDataType } from '../_types';
import { getUserBookmark } from '../_api/rest/bookmark';
import useUserStore from '../store/userStore';
import BookmarkCard from '../components/bookmark/BookmarkCard';
import ModalPortal from '../components/modal/ModalPortal';
import Modal from '../components/modal/Modal';
import InquiryModal from '../components/modal/InquiryModal';
import SearchInput from '../components/common/SearchInput';
import Spinner from '../components/loading/Spinner';
import { FullSpinner } from '../components/loading/FullSpinner';
import useRobotstore from '../store/robotStore';
import useRobotData from '../hooks/useRobotData';

const BookmarkPage = () => {
  const { t } = useTranslation();

  const { selectedUserAuthSite } = useUserStore();

  const { isLoading } = useRobotstore();

  const { user } = useUserData();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const { robotSocketData } = useRobotData();

  const [bookmarkDevices, setBookmarkDevices] = useState<string[] | null>(null);
  const [bookmarkData, setBookmarkData] = useState<DeviceDataType[]>([]);
  const [isSkeleton, setIsSkeleton] = useState<boolean>(true);

  const fetchUserBookmark = async () => {
    const { bookmarkedDeviceIds } = await getUserBookmark(user?.id);
    setBookmarkDevices(bookmarkedDeviceIds);
  };

  useEffect(() => {
    fetchUserBookmark();
  }, [user]);

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) return;

    setIsSkeleton(true);
  }, [selectedUserAuthSite]);

  useEffect(() => {
    if (!robotSocketData || selectedUserAuthSite.length === 0) return;

    setBookmarkData(
      robotSocketData.filter(
        (item) =>
          selectedUserAuthSite.includes(item.siteId) &&
          (bookmarkDevices ?? []).includes(item.deviceId),
      ),
    );
  }, [bookmarkDevices, robotSocketData, selectedUserAuthSite]);

  useEffect(() => {
    if (!bookmarkDevices || selectedUserAuthSite.length === 0) return;

    setTimeout(() => {
      setIsSkeleton(false);
    }, 500);
  }, [bookmarkDevices, bookmarkData]);

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      <FullSpinner loading={isLoading} />
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('bookmark')}
      </div>
      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        <SearchInput viewOption={'all'} />
      </section>

      <section className="flex flex-wrap justify-between px-12 pt-3 pb-10">
        {!isSkeleton ? (
          bookmarkData.length > 0 ? (
            bookmarkData.map((item, idx) => (
              <BookmarkCard
                key={idx}
                robotData={item}
                fetchUserBookmark={fetchUserBookmark}
              />
            ))
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-[65vh] gap-5 pt-10">
              <span className="pb-5 text-3xl font-semibold text-gray-400">
                {t('No_bookmarks_found')}
              </span>
            </div>
          )
        ) : (
          <div className="mt-[290px] flex items-center justify-center w-full h-full">
            <Spinner loading={isSkeleton} />
          </div>
        )}
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
    </div>
  );
};

export default BookmarkPage;
