import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
// import { GifWrapper, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper } from './styles';
import {FaArrowLeft} from "react-icons/fa";
import {FaChevronLeft} from "react-icons/fa";
import {FaChevronRight} from "react-icons/fa";
import Linker_B_A from '../../../assets/introduce/Image/Linker_B&A.gif'
import { Icon } from '../../../pages/IntroducePage';
import {FaYoutube} from "react-icons/fa";
import linkerModalBg from '../../../assets/introduce/Image/linkerModalBg.png'
import linkerPage1Bottom from '../../../assets/introduce/Image/Modal/Linker/LinkModalBottom.png'
import linkerPage1Title from '../../../assets/introduce/Image/Modal/Linker/LinkerPage1Title.png'
import linkerPage2Title from '../../../assets/introduce/Image/Modal/Linker/LinkerPage2Title.png'
import linkerPage1SubTitle from '../../../assets/introduce/Image/Modal/Linker/LinkerPage1SubTitle.png'
import linkerPage2SubTitle_1 from '../../../assets/introduce/Image/Modal/Linker/LinkerPage2SubTitle_1.png'
import linkerPage2SubTitle_2 from '../../../assets/introduce/Image/Modal/Linker/LinkerPage2SubTitle_2.png'
import styled from "styled-components";
import './CustomizeStyles.css'
import {useMediaQuery} from "react-responsive";
import {MobileLinkerModal} from "./Mobile/MobileLinkerModal";

function NextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
      <ModalBackBtn className="next" onClick={onClick}>
        <FaChevronRight className={'CFaChevronRight'} />
      </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
      <ModalBackBtn className="prev" onClick={onClick}>
        <FaChevronLeft className={'CFaChevronLeft'}/>
      </ModalBackBtn>
  );
}

export const LinkerModal = ({setClicked}: { setClicked: Function }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
      <>
        {isMobile ? <MobileLinkerModal setClicked={setClicked} /> : <ModalWrapper className="slider-container">
          <ModalProgressBar
              onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
              value={slideIndex}
              type="range"
              min={0}
              max={1}
          />
          <LinkerModalContent style={{backgroundImage: `url(${linkerModalBg})`}}>
            <ModalHeader>
              <ModalBackBtn onClick={() => setClicked('')}>
                <FaArrowLeft className={'CFaArrowLeft'}/>
              </ModalBackBtn>
            </ModalHeader>
            <Slider
                ref={sliderRef}
                {...settings}
            >
              <LinkerModalDisplay>
                <LinkerModalTitleArea>
                  <LinkerModalTitle className='LinkerPage1'>
                    <LinkerModalBadge>Linker</LinkerModalBadge>
                    <LinkerTitleImg src={linkerPage1Title}/>
                  </LinkerModalTitle>
                  <LinkerModalDesc className='linker_page1'>
                    <LinkerSubTitleImg src={linkerPage1SubTitle} />
                  </LinkerModalDesc>
                </LinkerModalTitleArea>
                <LinkerModalBottomSection>
                  <LinkerModalBottom>
                    <LinkerConnectImg src={linkerPage1Bottom}/>
                  </LinkerModalBottom>
                  <LinkerBeforeAfterGIFImg src={Linker_B_A}/>
                </LinkerModalBottomSection>
              </LinkerModalDisplay>

              <LinkerModalDisplay >
                <LinkerModalTitleArea className='linker_page2'>
                  <LinkerModalTitle className='linker_page2'>
                    <LinkerModalBadge>Linker</LinkerModalBadge>
                    <LinkerTitleImg src={linkerPage2Title}/>
                  </LinkerModalTitle>
                  <LinkerModalDesc className='linker_page2'>
                    <LinkerSubTitleTwoImg src={linkerPage2SubTitle_1} />
                  </LinkerModalDesc>
                </LinkerModalTitleArea>
                <LinkerModalBottomSecond>
                  <LinkerSubTitleBottomImg src={linkerPage2SubTitle_2}/>

                  <GifWrapper className='linker_page2'>
                    <LinkerVideoImg src={'/Video/keyVisual_Linker_video.gif'}/>
                    <a href='https://www.youtube.com/watch?v=2tYtKabCduM' target='blank'><Icon><FaYoutube/></Icon>SOLlink 로봇
                      간·인프라 간 연동 더보기</a>
                  </GifWrapper>
                </LinkerModalBottomSecond>
              </LinkerModalDisplay>
            </Slider>
          </LinkerModalContent>
        </ModalWrapper>}
      </>

  );
}

export default React.memo(LinkerModal)

// ------------- 추가 스타일 코드 --------------------

const LinkerTitleImg = styled.img`
  width: 700px;
  
  @media (max-width: 1920px) {
    width: 36.46vw;
  }
`

const LinkerSubTitleImg = styled.img`
  width: 1179px;
  
  @media (max-width: 1920px) {
    width: 61.56vw;
  }
`

const LinkerSubTitleTwoImg = styled.img`
  width: 1071px;
  
  @media (max-width: 1920px) {
    width: 55.78vw;
  }
`

const LinkerConnectImg = styled.img`
  width: 600px;
  height: 154px;

  @media (max-width: 1920px) {
    width: 31.25vw;
    height: 8.02vw;
  }
`

const LinkerBeforeAfterGIFImg = styled.img`
  width: 860px;
  height: 484px;
  margin-top: 98px;
  margin-left: 103px;

  @media (max-width: 1920px) {
    width: 44.79vw;
    height: 25.21vw;
    margin-top: 5.1vw;
    margin-left: 5.3646vw;
  }
`

const LinkerSubTitleBottomImg = styled.img`
  width: 656px;
  height: 127px;
  margin-top: 58px;

  @media (max-width: 1920px) {
    width: 34.17vw;
    height: 6.6vw;
    margin-top: 3.02vw;
  }
`

const LinkerVideoImg = styled.img`
  width: 770px;
  height: 433px;
  margin-top: 66px;
  margin-left: 206px;

  @media (max-width: 1920px) {
    width: 40.21vw;
    height: 22.6vw;
    margin-top: 3.44vw;
    margin-left: 10.73vw;
  }
  
`


// ------------- 이하 기존의 스타일 코드 ---------------

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 66px;
  right: 200px;
  z-index: 9999;
  width: 100px;
  height: 17px;

  @media (max-width: 1920px) {
    top: 3.44vw;
    right: 10.42vw;
    width: 5.21vw;
    height: 0.89vw;
  }
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;

  @media (max-width: 1920px) {
    width: 2.604vw;
    height: 2.604vw;
  }
  
  &.next {
    top: -43px;
    right: 0;

    @media (max-width: 1920px) {
      top: -2.2396vw;
    }
  }

  &.prev {
    top: -43px;
    right: 60px;

    @media (max-width: 1920px) {
      top: -2.2396vw;
      right: 3.125vw;
    }
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 50px 68px;
  display: flex !important;
  flex-direction: column;
  gap: 45px;
  background-color: #000;

  @media (max-width: 1920px) {
    gap: 2.34vw;
    padding: 2.604vw 3.542vw;
  }
  
`
const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-left: 80.33px;
  margin-top: 33.85px;

  @media (max-width: 1920px) {
    margin-left: 4.1802vw;
    margin-top: 3.1296vh;
  }
`
const LinkerModalBadge = styled.div`
  width: 103px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(255, 202, 64, 0.72) 0%, rgba(255, 202, 64, 0.20) 55.21%, rgba(255, 202, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.56px;
  margin-bottom: 30px;

  @media (max-width: 1920px) {
    width: 5.36vw;
    height: 2.29vw;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    text-shadow: 0px 0px 40px #FFF;
    font-size: 1.46vw;
    letter-spacing: -0.029vw;
    margin-bottom: 1.5625vh;
  }
`
const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-left: 80.33px;
  margin-top: 62px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
    margin-top: 3.23vw;
  }
  
  
`

const LinkerModalBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 137.33px;

  @media (max-width: 1920px) {
    margin-left: 7.15vw;
  }
`

const LinkerModalBottom = styled.div`
 margin-top: 310px;

  @media (max-width: 1920px) {
    margin-top: 16.15vw;
  }
  
`
const LinkerModalBottomSecond = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }

  
`


const GifWrapper = styled.div`

  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    line-height: 140%;

    @media (max-width: 1920px) {
      gap: 0.31vw;
      font-size: 0.94vw;
    }
  }
  

`

