import { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import useModalstore from '../../store/modalStore';
import { RobotCommandType } from '../../_types';
import { onStartJob } from '../../_api/rest/common';
import Button from '../common/Button';

type PropsType = {
  deviceId: string | number;
  command: RobotCommandType;
};

export default function VideoModal({ deviceId, command }: PropsType) {
  const { closeModal } = useModalstore();
  const [videoLink, setVideoLink] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoLink(e.target.value);
  };

  const onSendVideoUrl = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!videoLink.trim()) {
      toast.info('동영상 URL을 입력하세요.');
      return;
    }

    const robotData = {
      command,
      deviceId,
      videoLink,
    };

    onStartJob(robotData) //
      .then((res) => {
        toast.success('작업지시가 완료되었습니다.');
        closeModal();
      })
      .catch((e) => toast.error(`ERROR:: ${e.message}`));
  };

  return (
    <form
      className="flex flex-col justify-evenly h-[12rem]"
      onSubmit={onSendVideoUrl}
    >
      <section>
        <div>
          <h3 className="my-3 mx-0 font-bold text-[#868686]">URL</h3>
          <input
            className="w-full p-2 rounded-md border-[1px] border-[#d1d1d1]"
            placeholder="전송할 동영상 URL을 입력하세요."
            autoFocus
            onChange={onChange}
          />
        </div>
      </section>

      <section className="flex items-center justify-center w-full mx-0 my-3">
        <Button
          outlined
          label="취소"
          type="button"
          size="small"
          onClick={closeModal}
        />
        <Button outlined label="전송" type="submit" size="small" primary />
      </section>
    </form>
  );
}
