import { t } from "i18next";
import { Pathname } from "../_types";

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-5 pt-10">
      <span className="text-5xl font-semibold text-[#12172C] pb-5">
        {t("A_temporary_error_has_occurred")}
      </span>
      <div className="flex flex-col items-center justify-center text-sm text-gray-500">
        <p>{t("Sorry_Please_try_again_later")}</p>
      </div>

      <button
        className="px-6 py-3 transition duration-300 ease-in-out text-white rounded-lg shadow-lg hover:opacity-90 shadow-[#83d6c8] bg-primary"
        // Link to 경로 변경시 컴포넌트 변경 안됨
        onClick={() => (window.location.href = Pathname.MAIN)}
      >
        {t("Go_to_the_main_page")}
      </button>
    </div>
  );
};

export default ErrorPage;
