import { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import useModalstore from '../../store/modalStore';
import { RobotCommandType } from '../../_types';
import { onStartJob } from '../../_api/rest/common';
import Select from '../common/SingleSelect';
import Button from '../common/Button';

type PropsType = {
  deviceId: string;
  command: RobotCommandType;
};

type FormType = {
  turnDirection: 'left' | 'right';
  turnAngle: number;
};

export default function TurnModal({ deviceId, command }: PropsType) {
  const { closeModal } = useModalstore();

  const direction = [
    { id: 'left', name: '왼쪽' },
    { id: 'right', name: '오른쪽' },
  ];
  const [form, setForm] = useState<FormType>({
    turnDirection: 'left',
    turnAngle: 0,
  });

  const onSelectDirection = (id: string) => {
    if (id !== 'left' && id !== 'right') return;
    setForm((prev) => ({ ...prev, turnDirection: id }));
  };

  const onChangeAngle = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, turnAngle: Number(e.target.value) }));
  };

  const onSendTurn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const robotData = {
      command,
      deviceId,
      ...form,
    };

    onStartJob(robotData) //
      .then((res) => {
        toast.success('작업지시가 완료되었습니다.');
        closeModal();
      })
      .catch((e) => toast.error(`ERROR:: ${e.message}`));
  };

  return (
    <form
      className="flex flex-col justify-evenly h-[14rem]"
      onSubmit={onSendTurn}
    >
      <section className="flex flex-col justify-center h-full">
        <div className="flex items-center w-full my-2">
          <h3 className="flex-[0.2] my-3 mx-0 font-bold text-[#868686]">
            방향
          </h3>

          <div className="flex-[0.8]">
            <Select
              label="방향"
              list={direction}
              selected={form.turnDirection}
              onChange={onSelectDirection}
            />
          </div>
        </div>

        <div className="flex items-center w-full my-2">
          <h3 className="flex-[0.2] my-3 mx-0 font-bold text-[#868686]">
            각도
          </h3>

          <div className="flex-[0.8]">
            <input
              className="w-full p-[15px] text-sm rounded-md border-[1px] border-gray-100"
              type="number"
              value={form.turnAngle}
              placeholder="각도를 입력하세요."
              onChange={onChangeAngle}
            />
          </div>
        </div>
      </section>

      <section className="flex items-center justify-center w-full mx-0 my-3">
        <Button
          outlined
          label="취소"
          type="button"
          size="small"
          onClick={closeModal}
        />
        <Button outlined label="전송" type="submit" size="small" primary />
      </section>
    </form>
  );
}
