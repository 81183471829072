import React, { useState } from 'react'
import Top from './Top';
import { TechnicalWrapper } from './styles';
import Bottom from './Bottom';



export const Technical = ({ setClicked }: {setClicked: Function}) => {
  const [select, setSelect] = useState<string>('');

  return (
    <TechnicalWrapper>
      {/* 상단 */}
      <Top select={select} setSelect={setSelect} />

      {/* 하단 */}
      <Bottom select={select} setSelect={setSelect} setClicked={setClicked} />
    </TechnicalWrapper>
  )
}

export default React.memo(Technical);