import { ReactNode } from "react";

interface PropsType {
  width?: number;
  height?: number;
  borderRadius?: number;
  className?: string;
  children?: ReactNode;
}

export default function Skeleton({
  width,
  height,
  borderRadius,
  className,
  children,
}: PropsType) {
  const keyframes = `@keyframes skeleton-gradient {
        0% {
            background-color: #00000009;
        }
      
        50% {
            background-color: #00000015;
        }
      
        100% {
            background-color: #00000009;
        }
      }`;

  const style = {
    width: width,
    height: height,
    borderRadius: `${borderRadius}px`,
    animation: `skeleton-gradient 1s infinite ease-in-out`,
  };

  return (
    <>
      <style>{keyframes}</style>
      <div style={style} className={className}>
        {children}
      </div>
    </>
  );
}
