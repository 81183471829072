import { t } from 'i18next';
import { TaskState } from '../../../_types/schedule';
import Button from '../../common/Button';
import DeleteIcon from '../../../assets/icon/icon_delete.svg';
import useModalstore from '../../../store/modalStore';
import { useEffect } from 'react';
interface TaskCardProps {
  taskId: string;
  taskName: string;
  taskState: keyof typeof TaskState;
  agentNameList: string[];
  currentAction?: string;
  prevAction?: string;
  currentActionAlias: string;
  prevActionAlias?: string;
  currentActionCount: string | number;
  totalActionCount: string | number;
  startTime: string;
  lastUpdateTime: string;
  ellapsedTimeTotal: string;
  isStopped: boolean;
  isHideButton?: boolean;
  onDeleteClick: () => void;
  onStopClick: () => void;
}

const TaskCard = ({
  taskId,
  taskName,
  taskState,
  agentNameList,
  currentAction,
  prevAction,
  currentActionAlias,
  prevActionAlias,
  currentActionCount,
  totalActionCount,
  startTime,
  lastUpdateTime,
  ellapsedTimeTotal,
  isStopped,
  isHideButton = false,
  onDeleteClick,
  onStopClick,
}: TaskCardProps) => {
  const { openModal } = useModalstore();

  return (
    <div
      className={`flex flex-col gap-2 p-5 shadow rounded-xl min-w-[500px] ${' bg-white'}`}
    >
      <div>
        <div className="flex items-center justify-between py-2 ">
          <div className="flex gap-1 ">
            <span className="text-center">{taskName ? taskName : taskId}</span>
            <span>|</span>
            {agentNameList?.map((robot, i) => (
              <span
                key={i}
                className="flex items-center text-xs  border-2  border-gray-100 text-[#08B295] rounded-lg px-[4px] "
              >
                {robot}
              </span>
            ))}
          </div>
          {isStopped ? (
            <Button icon={DeleteIcon} onlyIcon onClick={onDeleteClick} />
          ) : (
            <div className="flex">
              {!isHideButton && (
                <Button
                  persnalStyle="text-[#05B195]"
                  label={t('Stop_schedule')}
                  type="button"
                  onClick={() =>
                    openModal({
                      title: `${t('Do_you_want_to_stop_the_schedule')}`,
                      contents: (
                        <div>
                          <p>{t('This_action_cannot_be_undone')}</p>
                          <p>
                            {t(
                              'If_you_wish_to_continue_running_the_schedule_please_press_Continue',
                            )}
                          </p>
                        </div>
                      ),
                      type: 'warning',
                      onClickOk: onStopClick,
                      cancelText: `${t('Continue')}`,
                      okText: `${t('Stop')}`,
                    })
                  }
                  size="small"
                />
              )}
              <Button icon={DeleteIcon} onlyIcon onClick={onDeleteClick} />
            </div>
          )}
        </div>
        <div className="w-[full] border border-gray-100" />
      </div>

      <span>{TaskState[taskState]}</span>
      <div className="flex flex-col w-[80%] gap-2">
        <div className="flex items-center w-full text-xl text-start">
          <span className="min-w-[85px]">{t('Current_Task')}:</span>
          {currentActionAlias ? (
            <span className="min-w-[180px]">{currentActionAlias}</span>
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="flex items-center gap-1 pl-1 text-sm text-gray-500 text-start">
          <span>{t('Previous_Task')}:</span>
          {prevActionAlias ? (
            <>
              <span>{prevActionAlias}</span>
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
      {currentActionCount && (
        <div className="flex w-[full] space-x-2 ">
          <div className="justify-center w-full h-6 bg-gray-200 rounded-full dark:bg-neutral-200">
            <div
              className="h-6 text-center rounded-full text-[white] transition-width duration-500 dark: bg-[#08B295]"
              style={{
                width: `${
                  (Number(currentActionCount) / Number(totalActionCount)) * 100
                }%`,
              }}
            >
              {
                <span>{`${(
                  (Number(currentActionCount) / Number(totalActionCount)) *
                  100
                ).toFixed(0)}% `}</span>
              }
            </div>
          </div>
        </div>
      )}

      <table className="w-[full] text-xs border-collapse rounded-lg shadow-sm bg-neutral-200">
        <thead>
          <tr>
            <th className="p-2 border-collapse rounded-lg ">
              {t('Start_Time')}
            </th>
            <th className="p-2 border-collapse">{t('Elapsed_Time')}</th>
            <th className="p-2 border-collapse rounded-lg ">
              {t('Recent_Update_Time')}
            </th>
          </tr>
        </thead>
        <tbody className="border-collapse shadow-sm bg-neutral-200">
          <tr className="text-center bg-white rounded-lg shadow-sm">
            <td className="p-2 border-collapse rounded-bl-lg ">
              {new Date(startTime).toLocaleString()}
            </td>
            <td className="p-2 border-collapse">
              {ellapsedTimeTotal?.split('.')[0]}
            </td>
            <td className="p-2 border-collapse rounded-br-lg ">
              {new Date(lastUpdateTime).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TaskCard;
