import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../stories/widget/grid/ag-grid-theme-custom.css';
import {
  AgGridEvent,
  ColDef,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import '../../../../stories/widget/grid/grid.css';

interface RowData {
  make: string | null;
  model: string | null;
  price: number | null;
}

export interface GridProps {
  columnDefs: ColDef<RowData>[];
  rowData: RowData[];
  height?: number;
  width?: number;
  rowSelection?: 'single' | 'multiple';
  pagination?: boolean;
  headerHeight?: number;
}

export const Grid = ({
  rowData,
  columnDefs,
  height,
  width,
  rowSelection = 'single',
  pagination = false,
  headerHeight,
}: GridProps) => {
  const onRowSelected = (event: any) => {
    event.preventDefault;
    if (event.node.selected) {
      alert('Row Selected !!!');
      console.log('Row Select');
    }
  };

  return (
    <div style={{ height, width }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection={rowSelection}
        pagination={pagination}
        headerHeight={headerHeight}
        // onSelectionChanged={onSelectionChanged} // 선택 변경 이벤트
        onRowSelected={onRowSelected} // 개별 행 선택 이벤트
      ></AgGridReact>
    </div>
  );
};
