import React, {useRef, useState} from 'react'
import {FaArrowLeft} from "react-icons/fa";
import pendantModalBg from '../../../../assets/introduce/Image/pendantModalBg.png'
import pendantPage1_title from '../../../../assets/introduce/Image/Modal/Pendant/pendantPage1_title.png'
import pendantPage1_subTitle from '../../../../assets/introduce/Image/Modal/Pendant/pendantPage1_subTitle.png'
import pendantPage1_desc from '../../../../assets/introduce/Image/Modal/Pendant/pendantPage1_desc.png'
import styled from "styled-components";

export const MobilePendantModal = ({setClicked}: { setClicked: Function }) => {
  return (
    <ModalWrapper className="slider-container">
      <LinkerModalContent className='pendant' style={{backgroundImage: `url(${pendantModalBg})`}}>
        <ModalHeader>
          <ModalBackBtn onClick={() => setClicked('')}>
            <FaArrowLeft className={'CMobileFaArrowLeft'}/>
          </ModalBackBtn>
        </ModalHeader>
        <LinkerModalDisplay>
          <LinkerModalTitleArea>
            <LinkerModalTitle>
              <PendantModalBadge>Pendant</PendantModalBadge>
              <PendantTitleImg src={pendantPage1_title}/>
            </LinkerModalTitle>
            <LinkerModalDesc className='pendant'>
              <PendantDescImg src={pendantPage1_desc}/>
            </LinkerModalDesc>
          </LinkerModalTitleArea>
          <PlatformModalBottom className='builder_page1'>
            <PendantsubTitleImg src={pendantPage1_subTitle}/>
          </PlatformModalBottom>
          <PendantImgWrapper>
            <Vertical>
              <span>Windows</span>
              <span className='line'/>
              <span>Android</span>
              <span className='line'/>
              <span>Desktop</span>
            </Vertical>
            <Horizon>
              <span/>
              <PendantVideoImg src={'/Video/keyVisual_Pendant_video.gif'}/>
              <span/>
            </Horizon>
            <Vertical>
              <span>Mac</span>
              <span className='line'/>
              <span>IOS</span>
              <span className='line'/>
              <span>Tablet</span>
            </Vertical>
          </PendantImgWrapper>
        </LinkerModalDisplay>
      </LinkerModalContent>
    </ModalWrapper>
  );
}

export default React.memo(MobilePendantModal)


const PendantTitleImg = styled.img`
  width: 62.11vw;
  margin-top: 3.91vw;
`

const PendantDescImg = styled.img`
  width: 49.61vw;
`

const PendantsubTitleImg = styled.img`
  width: 69.79vw;
`

const PendantVideoImg = styled.img`
  width: 55.21vw;
`


const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 6.51042vw;
  height: 6.51042vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;


  &.next {
    top: -5.59948vw;
    right: 0;
  }

  &.prev {
    top: -5.59948vw;
    right: 7.8125vw;
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 3.90625vw 5.20833vw;
  display: flex !important;
  flex-direction: column;
  gap: 5.85938vw;
  background-color: #000;

`

const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90.625vw;
  width: auto;
  margin-top: 4.40495vw;

 
`

const LinkerModalDesc = styled.p`
  font-size: 3.91vw;
  color: #fff;
  font-weight: 400;
  line-height: 4.43vw;
  white-space: nowrap;
  margin-top: 4.69vw;
  margin-bottom: 3.26vw;
  
`

const PlatformModalBottom = styled.p`


`

const PendantModalBadge = styled.div`
  width: 17.06vw;
  height: 5.73vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 3.64583vw;
  font-weight: 700;
  letter-spacing: -0.0729vw;


`
const PendantImgWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 5.21vw;

 


  span {
    width: 10.42vw;
    height: 4.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background: radial-gradient(100% 100% at 50.61% 100%, rgba(205, 64, 255, 0.72) 0%, rgba(205, 64, 255, 0.20) 55.21%, rgba(205, 64, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
    color: #fff;
    font-size: 1.82291vw;
    font-weight: 600;
    
  }

`
const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    &.line {
      width: 0.39vw;
      height: 15.23vw;
      background-color: #7a2e95;
      margin: 0 auto;

    
    }
  }
`
const Horizon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    width: 63.31vw;
    height: 0.39vw;
    background-color: #7a2e95;
    margin: 1.96vw 0;

  
  }

`