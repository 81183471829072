import { create } from "zustand";

interface MapStore {
  mode: string;
  center: { lat: number; lng: number };
  zoom: number;
  markerInfo: {
    siteName: string;
    roadAddress: string;
    lat: number | null;
    lng: number | null;
  };
  isMarkerClick: boolean;
  changeMode: (inputMode: string) => void;
  changeCenter: (inputCenter: { lat: number; lng: number }) => void;
  changeZoom: (inputZoom: number) => void;
  updateIsMarkerClick: (input: boolean) => void;
  changeMarkerInfo: (
    inputSiteName: string,
    inputAddress: string,
    inputLat: number,
    inputLng: number
  ) => void;
}

const useMapstore = create<MapStore>()((set) => ({
  mode: localStorage.mapMode ? localStorage.mapMode : "normal",
  center: {
    lat:
      localStorage.lat && localStorage.lat !== 0
        ? Number(localStorage.lat)
        : 36.3522548,
    lng:
      localStorage.lng && localStorage.lng !== 0
        ? Number(localStorage.lng)
        : 127.0153666,
  },
  zoom: localStorage.zoom ? Number(localStorage.zoom) : 8,
  markerInfo: {
    siteName: "",
    roadAddress: "",
    lat: null,
    lng: null,
  },
  isMarkerClick: false,
  changeMode: (inputMode) =>
    set((state) => ({
      mode: inputMode,
    })),
  changeCenter: (inputCenter) =>
    set((state) => ({
      center: { lat: inputCenter.lat, lng: inputCenter.lng },
    })),
  changeZoom: (inputZoom) =>
    set((state) => ({
      zoom: inputZoom,
    })),
  changeMarkerInfo: (inputSiteName, inputAddress, inputLat, inputLng) =>
    set((state) => ({
      markerInfo: {
        siteName: inputSiteName,
        roadAddress: inputAddress,
        lat: inputLat,
        lng: inputLng,
      },
    })),
  updateIsMarkerClick: (input) =>
    set((state) => ({
      isMarkerClick: input,
    })),
}));

export default useMapstore;
