import React, { useState } from 'react'
import { TechnicalBotLeft, TechnicalBotRight, TechnicalBottom, TechnicalSelectArea, TechnicalSelectAreaArrow, TechnicalSelectAreaBadge, TechnicalSelectAreaTxt } from './styles'
import { FaArrowRight } from "react-icons/fa";
import Tech_linker from '../../assets/introduce/Image/Tech_linker.png'
import Tech_platform from '../../assets/introduce/Image/Tech_platform.png'
import Tech_builder from '../../assets/introduce/Image/Tech_builder.png'
import Tech_pendant from '../../assets/introduce/Image/Tech_pendant.png'
import Tech_engine from '../../assets/introduce/Image/Tech_engine.png'
import {useMediaQuery} from "react-responsive";

export const Bottom = ({ select, setSelect, setClicked }: { select: string, setSelect: Function, setClicked: Function }) => {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});


  return (
      <TechnicalBottom>
        {/* Linker, Builder 구역 */}
        <TechnicalBotLeft>
          <TechnicalSelectArea
              className={select == 'linker' ? 'linker selected' : 'linker'}
              onMouseEnter={() => setSelect('linker')}
              onClick={() => isMobile ? setClicked('mobileLinker') : setClicked('linker')}
          >
            <TechnicalSelectAreaBadge className={select == 'linker' ? 'linker selected' : 'linker'}>Linker</TechnicalSelectAreaBadge>
            <TechnicalSelectAreaTxt>빌딩 내 인프라의<br/>손쉬운 클라우드 연동</TechnicalSelectAreaTxt>
            <TechnicalSelectAreaArrow><FaArrowRight /></TechnicalSelectAreaArrow>
            <img src={Tech_linker} />
          </TechnicalSelectArea>
          <TechnicalSelectArea
              className={select == 'builder' ? 'builder selected' : 'builder'}
              onMouseEnter={() => setSelect('builder')}
              onClick={() => isMobile ? setClicked('mobileBuilder') : setClicked('builder')}
          >
            <TechnicalSelectAreaBadge className={select == 'builder' ? 'builder selected' : 'builder'}>Workflow Builder</TechnicalSelectAreaBadge>
            <TechnicalSelectAreaTxt>직관적이고<br/>편리한 화면 구성</TechnicalSelectAreaTxt>
            <TechnicalSelectAreaArrow><FaArrowRight /></TechnicalSelectAreaArrow>
            <img src={Tech_builder} />
          </TechnicalSelectArea>
        </TechnicalBotLeft>

        {/* Platform, Pendant, Engine 구역 */}
        <TechnicalBotRight>
          <TechnicalSelectArea
              className={select == 'platform' ? 'platform selected' : 'platform'}
              onMouseEnter={() => setSelect('platform')}
              onClick={() =>  isMobile ? setClicked('mobilePlatform') : setClicked('platform')}
          >
            <TechnicalSelectAreaBadge className={select == 'platform' ? 'platform selected' : 'platform'}>Platform</TechnicalSelectAreaBadge>
            <TechnicalSelectAreaTxt>실시간 제어 및 관제</TechnicalSelectAreaTxt>
            <TechnicalSelectAreaArrow><FaArrowRight /></TechnicalSelectAreaArrow>
            <img src={Tech_platform} />
          </TechnicalSelectArea>
          <TechnicalSelectArea
              className={select == 'pendant' ? 'pendant selected' : 'pendant'}
              onMouseEnter={() => setSelect('pendant')}
              onClick={() => isMobile ? setClicked('mobilePendant') : setClicked('pendant')}
          >
            <TechnicalSelectAreaBadge className={select == 'pendant' ? 'pendant selected' : 'pendant'}>Pendant</TechnicalSelectAreaBadge>
            <TechnicalSelectAreaTxt>언제, 어디서든<br/>원하는 환경에서 접속</TechnicalSelectAreaTxt>
            <TechnicalSelectAreaArrow><FaArrowRight /></TechnicalSelectAreaArrow>
            <img src={Tech_pendant} />
          </TechnicalSelectArea>
          <TechnicalSelectArea
              className={select == 'engine' ? 'engine selected' : 'engine'}
              onMouseEnter={() => setSelect('engine')}
              onClick={() => isMobile ? setClicked('mobileEngine') : setClicked('engine')}
          >
            <TechnicalSelectAreaBadge className={select == 'engine' ? 'engine selected' : 'engine'}>Workflow Engine</TechnicalSelectAreaBadge>
            <TechnicalSelectAreaTxt>상호작용과 통합관리</TechnicalSelectAreaTxt>
            <TechnicalSelectAreaArrow><FaArrowRight /></TechnicalSelectAreaArrow>
            <img src={Tech_engine} />
          </TechnicalSelectArea>
        </TechnicalBotRight>
      </TechnicalBottom>
  )
}

export default React.memo(Bottom)