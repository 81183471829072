import React, { useEffect, useState } from 'react';
import { LuLayoutTemplate } from 'react-icons/lu';
import useWorkflowStore from '../../store/workflowStore';
import useUserStore from '../../store/userStore';
import { getUsersetNodeList } from '../../_api/rest/workflow';
import errorPage from '../../pages/ErrorPage';

const ItemUserset = (props: any) => {
  const { robotInfo } = useWorkflowStore();
  const { selectedUserAuthSite } = useUserStore();

  const [presetNodeList, setPresetNodeList] = useState<any>([]);
  const [usersetNodeList, setUsersetNodeList] = useState<any>([]);

  const [selectUsersetId, setSelectUsersetId] = useState<string>('');

  const onDragStart = (event: any, nodeType: any, usersetId: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.setData('presetID', event.target.title);
    event.dataTransfer.effectAllowed = 'move';

    setSelectUsersetId(usersetId);
  };

  useEffect(() => {
    if (props.reRender) {
      setSelectUsersetId('');
    }
  }, [props.reRender]);

  useEffect(() => {
    if (props.isReset) {
      setUsersetNodeList([]);
      setSelectUsersetId('');
    }
  }, [props.isReset]);

  useEffect(() => {
    if (selectedUserAuthSite) {
      getUsersetNodeListAPI(selectedUserAuthSite[0]);
    }

    if (props.reRender) {
      props.resetReRender(false, 'userset');
    }
  }, [selectedUserAuthSite, props.reRender]);

  const getUsersetNodeListAPI = async (siteId: string) => {
    await getUsersetNodeList(siteId)
      .then((res) => {
        if (res !== null && res !== undefined) {
          setUsersetNodeList(res);
        }
      })
      .catch((error) => {
        errorPage();
      });
  };

  return (
    <aside className={'wf-body-left-contents'}>
      {/*/!* USERSET *!/*/}
      {/*{usersetNodeList.length > 0 ? (*/}
      {/*    <div style={styles.sidebarItemHeadingContainer}>*/}
      {/*        <div style={{ display: "flex" }}>*/}
      {/*            <AiOutlineBars size="20" color="rgb(100, 100, 100)" />*/}
      {/*            <span style={styles.sidebarItemHeading}> USERSET </span>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*) : (*/}
      {/*    ""*/}
      {/*)}*/}
      {usersetNodeList.length > 0 ? (
        usersetNodeList.map((item: any, idx: number) => {
          return (
            <div
              key={`node_${idx}`}
              id={`USERSET|${item.usersetId}`}
              draggable={true}
              onDragStart={(event) =>
                onDragStart(event, 'default', item.usersetId)
              }
            >
              <div
                style={styles.parent}
                className={
                  selectUsersetId === item.usersetId
                    ? 'border-[1px] border-[#26bfa1] rounded-[4px]'
                    : 'border-[1px] border-[#dddddd] rounded-[4px]'
                }
              >
                <div style={styles.iconParent}>
                  <LuLayoutTemplate size={20} />
                </div>
                {item.usersetName}
              </div>
            </div>
          );
        })
      ) : (
        <div className={'text-gray-400 text-[14px]'}>
          등록된 USERSET 가 없습니다.
        </div>
      )}
    </aside>
  );
};

export default ItemUserset;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: '#fff',
    cursor: 'move',
    marginTop: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Gmarket Sans, Medium',
  },
  iconParent: {
    borderRight: '1px solid rgb(230, 230, 230)',
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: 'rotate(90deg)',
  },
  joinIcon: {
    transform: 'rotate(-90deg)',
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '10px',
    lineHeight: 'normal',
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
