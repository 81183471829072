import useAuth from '../hooks/useAuth';
import InquiryModal from '../components/modal/InquiryModal';
import { ReactFlowProvider } from 'reactflow';
import Flow from '../components/workflow/Flow';
import React from 'react';
import SearchInput from '../components/common/SearchInput';
import Datepicker from 'react-tailwindcss-datepicker';
import i18next from 'i18next';
import MostErrorRobots from '../components/dashboard/chart/MostErrorRobots';
import DailyWorkDistributionChart from '../components/sollinkHome/widget/DailyWorkDistributionChart';
import ServiceBarChart from '../components/dashboard/chart/ServiceBarChart';
import MostUsedDestinationBarChart from '../components/dashboard/chart/MostUsedDestinationBarChart';
import DailyOperatingRobotsChart from '../components/sollinkHome/widget/DailyOperatingRobotsChart';
import DailyErrorLogGrid from '../components/sollinkHome/widget/DailyErrorLogGrid';
import { Button } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from 'react-icons/fa6';
import { GrDocumentDownload } from 'react-icons/gr';

const SollinkHomePage = () => {
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  const pdfDownload = async () => {
    const canvas = await html2canvas(document.querySelector('#pdfDownload')!, {
      scale: 1.5,
    });

    const imageFile = canvas.toDataURL('image/png');

    const doc = new jsPDF('p', 'px', 'a4');

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    //이미지의 길이와 pdf의 가로길이가 다르므로 이미지 길이를 기준으로 비율을 구함
    const widthRatio = pageWidth / canvas.width;
    //비율에 따른 이미지 높이
    const customHeight = canvas.height * 0.23;

    //pdf에 1장에 대한 이미지 추가
    doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight);
    //doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    //감소하면서 남은 길이 변수
    let heightLeft = customHeight;
    //증가하면서 이미지 자를 위치 변수
    let heightAdd = -pageHeight;

    // 한 페이지 이상일 경우
    while (heightLeft >= pageHeight) {
      //pdf페이지 추가
      doc.addPage();
      //남은 이미지를 추가
      doc.addImage(imageFile, 'png', 0, heightAdd, pageWidth, customHeight);
      //남은길이
      heightLeft -= pageHeight;
      //남은높이
      heightAdd -= pageHeight;
    }
    //문서저장
    doc.save('filename' + new Date().getTime() + '.pdf');
  };

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        솔링크 홈
      </div>
      <section
        className="pt-[90px] items-center justify-between pb-3 pl-1 pr-1"
        data-name="bg"
      >
        <div className={'float-end mb-3 mr-1'}>
          <Button
            className={'buttonSwitch flex items-center justify-center'}
            onClick={pdfDownload}
            type={'primary'}
            icon={<GrDocumentDownload />}
          >
            PDF
          </Button>
        </div>
        <div id={'pdfDownload'}>
          <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
            <div className="w-full tab:w-[100%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
              <DailyWorkDistributionChart />
            </div>
          </section>
          <section className="flex flex-col w-full h-[400px] tab:flex-row mb-1">
            <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
              <DailyOperatingRobotsChart />
            </div>
            <div className="w-full tab:w-[50%] h-[400px] rounded-md tab:mt-0 tab:mr-1 bg-white shadow-sm">
              <DailyErrorLogGrid />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default SollinkHomePage;
