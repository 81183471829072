import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { UserType } from "../_types/user";
import { getUserById } from "../_api/firestore/user";

export default function useUserData() {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (userData) => {
      if (userData) {
        getUserData(userData.uid, userData.emailVerified);
      }
    });
  }, []);

  async function getUserData(uid: string, emailVerified: boolean) {
    const res = await getUserById(uid);
    setUser({ ...res, id: uid, emailVerified } as UserType);
  }

  return { user };
}
