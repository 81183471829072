import React, {useRef, useState} from 'react'
import Slider from "react-slick";
// import { BuilderModalBadge, EngineGifWrapper, EngineModalBadge, EngineModalDesc, EngineModalDescArea, GifWrapper, Horizon, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper, PendantImgWrapper, PendantModalBadge, PlatformImg, PlatformImgArea, PlatformImgSecond, PlatformModalBadge, PlatformModalBottom, PlatformModalDesc, PlatformModalDescArea, Vertical } from './styles';
import {FaArrowLeft} from "react-icons/fa";
import pendantModalBg from '../../../assets/introduce/Image/pendantModalBg.png'
import pendantPage1_title from '../../../assets/introduce/Image/Modal/Pendant/pendantPage1_title.png'
import pendantPage1_subTitle from '../../../assets/introduce/Image/Modal/Pendant/pendantPage1_subTitle.png'
import pendantPage1_desc from '../../../assets/introduce/Image/Modal/Pendant/pendantPage1_desc.png'
import pendantPage1_content from '../../Assets/Image/Modal/Pendant/pendantPage1_content.png'
import engineModalBg from '../../Assets/Image/engineModalBg.png'
import builderModalBg from '../../Assets/Image/builderModalBg.png'
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {MobilePendantModal} from "./Mobile/MobilePendantModal";

export const PendantModal = ({setClicked}: { setClicked: Function }) => {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  return (
      <>
        {isMobile ?
            <MobilePendantModal setClicked={setClicked}/>
            :
            <ModalWrapper className="slider-container">
              <LinkerModalContent className='pendant' style={{backgroundImage: `url(${pendantModalBg})`}}>
                <ModalHeader>
                  <ModalBackBtn onClick={() => setClicked('')}>
                    <FaArrowLeft className={'CFaArrowLeft'}/>
                  </ModalBackBtn>
                </ModalHeader>
                <LinkerModalDisplay>
                  <LinkerModalTitleArea>
                    <LinkerModalTitle>
                      <PendantModalBadge>Pendant</PendantModalBadge>
                      <PendantTitleImg src={pendantPage1_title}/>
                    </LinkerModalTitle>
                    <LinkerModalDesc className='pendant'>
                      <PendantDescImg src={pendantPage1_desc}/>
                    </LinkerModalDesc>
                  </LinkerModalTitleArea>
                  <PlatformModalBottom className='builder_page1'>
                    <PendantsubTitleImg src={pendantPage1_subTitle}/>
                  </PlatformModalBottom>
                  <PendantImgWrapper>
                    <Vertical>
                      <span>Windows</span>
                      <span className='line'/>
                      <span>Android</span>
                      <span className='line'/>
                      <span>Desktop</span>
                    </Vertical>
                    <Horizon>
                      <span/>
                      <PendantVideoImg src={'/Video/keyVisual_Pendant_video.gif'}/>
                      <span/>
                    </Horizon>
                    <Vertical>
                      <span>Mac</span>
                      <span className='line'/>
                      <span>IOS</span>
                      <span className='line'/>
                      <span>Tablet</span>
                    </Vertical>
                  </PendantImgWrapper>
                </LinkerModalDisplay>
              </LinkerModalContent>
            </ModalWrapper>
        }
      </>
  );
}

export default React.memo(PendantModal)


const PendantTitleImg = styled.img`
  width: 577px;
  margin-top: 30px;

  @media (max-width: 1920px) {
    width: 30.05vw;
    margin-top: 1.56vw;
  }
`

const PendantDescImg = styled.img`
  width: 381px;
  margin-top: 46px;
  margin-bottom: 20px;

  @media (max-width: 1920px) {
    width: 19.84vw;
    margin-top: 2.40vw;
    margin-bottom: 1.04vw;
  }
`

const PendantsubTitleImg = styled.img`
  width: 636px;
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    width: 33.23vw;
    margin-left: 4.18vw;
  }

`

const PendantVideoImg = styled.img`
  width: 485px;

  @media (max-width: 1920px) {
    width: 25.26vw;
  }
`


const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;

  @media (max-width: 1920px) {
    width: 2.604vw;
    height: 2.604vw;
  }

  &.next {
    top: -43px;
    right: 0;

    @media (max-width: 1920px) {
      top: -2.2396vw;
    }
  }

  &.prev {
    top: -43px;
    right: 60px;

    @media (max-width: 1920px) {
      top: -2.2396vw;
      right: 3.125vw;
    }
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 50px 68px;
  display: flex !important;
  flex-direction: column;
  gap: 45px;
  background-color: #000;

  @media (max-width: 1920px) {
    gap: 2.34vw;
    padding: 2.604vw 3.542vw;
  }
`

const LinkerModalDisplay = styled.div`
  //display: flex !important;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: space-between;
  //height: calc(100vh - 170px);
  //padding: 0px 80px;
  //padding-bottom: 128px;
  //position: relative;
  //@media (max-width: 1250px) {
  //  padding: 0px 20px;
  //}
  //@media (max-width: 450px) {
  //  padding: 0px;
  //}
`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-left: 80.33px;
  margin-top: 33.85px;

  @media (max-width: 1920px) {
    margin-left: 4.1802vw;
    margin-top: 3.1296vh;
  }
`

const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-left: 80.33px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }
`

const PlatformModalBottom = styled.p`


`

const PendantModalBadge = styled.div`
  width: 131px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.56px;

  @media (max-width: 1920px) {
    width: 6.82vw;
    height: 2.29vw;
    border: 0.03vw solid var(rgba(255, 255, 255, 0.20));
    text-shadow: 0px 0px 2.08vw #FFF;
    font-size: 1.46vw;
    letter-spacing: -0.03vw;
  }
`
const PendantImgWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: -70px;

  @media (max-width: 1920px) {
    top: -3.65vw;
  }


  span {
    width: 160px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background: radial-gradient(100% 100% at 50.61% 100%, rgba(205, 64, 255, 0.72) 0%, rgba(205, 64, 255, 0.20) 55.21%, rgba(205, 64, 255, 0.00) 100%), rgba(255, 255, 255, 0.08);
    color: #fff;
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 1920px) {
      width: 8.33vw;
      height: 2.81vw;
      border: 0.03vw solid rgba(255, 255, 255, 0.20);
      font-size: 1.25vw;
    }

  }

`
const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    &.line {
      width: 3px;
      height: 177px;
      background-color: #7a2e95;
      margin: 0 auto;

      @media (max-width: 1920px) {
        width: 0.16vw;
        height: 9.28vw;
      }
    }
  }
`
const Horizon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    width: 567px;
    height: 3px;
    background-color: #7a2e95;
    margin: 25px 0;

    @media (max-width: 1920px) {
      width: 29.53vw;
      height: 0.16vw;
      margin: 1.30vw 0;

    }
  }

`