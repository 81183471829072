import React from 'react'
import {MapImage, MapSubTitle, MapTitle, MapTxtArea, MapWrapper} from './styles';
import {ExperienceBtn} from '../Experience/styles';
import {Icon} from '../../pages/IntroducePage';
import {CiShare1} from "react-icons/ci";
import SOLlink_map from '../../assets/introduce/Image/sollink_map.png'
import {logEvent} from "firebase/analytics";
import {analytics} from "../../config/firebase";
import {INQUIRY_LOG} from "../../constants/logEvent";

export const Map = () => {
    const onClickApplyMemo = () => {
        const isMobile = /iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent);

        logEvent(
            analytics,
            !isMobile
                ? INQUIRY_LOG.P_INQUIRY_SOLLINK_CLICK
                : INQUIRY_LOG.M_INQUIRY_SOLLINK_CLICK
        );
        window.location.href = "https://www.myrobotsolution.com/inquiry/sollink";
    };

    return (
        <MapWrapper>
            <MapTxtArea>
                <MapTitle>
                    이미 검증된
                    <p>SOLlink의 우수한 활용성</p>
                </MapTitle>
                <MapSubTitle>
                    이미 전국 180여 개의 현장에서<br/>
                    SOLlink의 무한한 서비스를 경험하고 계십니다.
                </MapSubTitle>
            </MapTxtArea>
            <MapImage>
                <img src={SOLlink_map}/>
            </MapImage>
            <ExperienceBtn onClick={onClickApplyMemo}>
                SOLlink 체험하기
                <Icon><CiShare1/></Icon>
            </ExperienceBtn>
        </MapWrapper>
    )
}

export default React.memo(Map);