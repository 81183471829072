import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Gausium_Serving from '../../assets/introduce/Image/Robots/가우시움로봇비전 - 서빙로봇.png'
import Gausium_Phantas from '../../assets/introduce/Image/Robots/가우시움로봇비전-phantas (청소로봇).png';
import Gausium_SC50 from '../../assets/introduce/Image/Robots/가우시움로봇비전-SC50 (청소로봇).png';
import Gausium_SC75 from '../../assets/introduce/Image/Robots/가우시움로봇비전-SC75 (청소로봇).png';
import Gausium_VC40 from '../../assets/introduce/Image/Robots/가우시움로봇비전-VC40 (청소로봇).png';
import DOGU_iroi from '../../assets/introduce/Image/Robots/도구공간 (다학제로봇).png';
import Robotics_Ant from '../../assets/introduce/Image/Robots/로보티즈 - 집개미 (배송로봇).png';
import BearRobotics_Servi from '../../assets/introduce/Image/Robots/베어로보틱스 - Servi (서빙로봇).png';
import BearRobotics_ServiAir from '../../assets/introduce/Image/Robots/베어로보틱스 - Servi Air (방역로봇).png';
import OrionStar_Guide from '../../assets/introduce/Image/Robots/오리온스타-안내로봇.png';
import OrionStar_Forme from '../../assets/introduce/Image/Robots/오리온스타-포미.png';
import Yujin_GoCart180 from '../../assets/introduce/Image/Robots/유진로봇 - 고카트180 (AMR).png';
import Yujin_GoCart250 from '../../assets/introduce/Image/Robots/유진로봇 - 고카트250 (AMR).png';
import Yujin_GoCart500 from '../../assets/introduce/Image/Robots/유진로봇 - 고카트500 (AMR).png';
import Keenon_T1 from '../../assets/introduce/Image/Robots/키논로보틱스 - T1 (서빙로봇3).png';
import Keenon_T2 from '../../assets/introduce/Image/Robots/키논로보틱스 - T2 (서빙로봇5).png';
import Keenon_T5 from '../../assets/introduce/Image/Robots/키논로보틱스 - T5 (서빙로봇2).png';
import Keenon_T6 from '../../assets/introduce/Image/Robots/키논로보틱스 - T6 (서빙로봇4).png';
import Keenon_T8 from '../../assets/introduce/Image/Robots/키논로보틱스 - T8 (서빙로봇4).png';
import Keenon_W3 from '../../assets/introduce/Image/Robots/키논로보틱스 - W3 (서빙로봇1).png';
import Pudu_Bella from '../../assets/introduce/Image/Robots/푸두로보틱스 - 벨라봇 (서빙로봇1).png';
import Pudu_K from '../../assets/introduce/Image/Robots/푸두로보틱스 - 케이봇 (서빙로봇3).png';
import Pudu_Serving2 from '../../assets/introduce/Image/Robots/푸두로보틱스 (서빙로봇2).png';
import Hyundai_D1 from '../../assets/introduce/Image/Robots/현대로보틱스 - D1 (방역로봇).png';
import KT_Dasom from '../../assets/introduce/Image/Robots/KT - 다솜이 (케어로봇).png';
import LG_CLOi_Quarantine from '../../assets/introduce/Image/Robots/LG전자-CLOi (방역로봇).png';
import LG_CLOi_Delivery from '../../assets/introduce/Image/Robots/LG전자-CLOi (배송로봇).png';
import LG_CLOi_Serving from '../../assets/introduce/Image/Robots/LG전자-CLOi (서빙로봇).png';
import LG_CLOi_information from '../../assets/introduce/Image/Robots/LG전자-CLOi (안내로봇).png';
import OMRON_HD1500 from '../../assets/introduce/Image/Robots/OMRON - HD1500 (AMR).png';
import OMRON_LD60 from '../../assets/introduce/Image/Robots/OMRON - LD60 (AMR).png';
import OMRON_LD90 from '../../assets/introduce/Image/Robots/OMRON - LD90 (AMR).png';
import OMRON_LD250 from '../../assets/introduce/Image/Robots/OMRON - LD250 (AMR).png';
import Vesselo_VMR90 from '../../assets/introduce/Image/Robots/Vesselo - VMR90 (AMR).png';
import { ActionRobotDesc, ActiveRobot, ActiveRobotWrapper, ArrowBtn, NonActiveRobot, RobotSlider, RobotWrapper } from "./styles";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdArrowRoundForward } from "react-icons/io";


const RobotArr = [
  {
    company: '가우시움로봇비전',
    name: '서빙로봇',
    img: Gausium_Serving
  },
  {
    company: '가우시움로봇비전',
    name: 'phantas',
    img: Gausium_Phantas
  },
  {
    company: '가우시움로봇비전',
    name: 'SC50',
    img: Gausium_SC50
  },
  {
    company: '가우시움로봇비전',
    name: 'SC75',
    img: Gausium_SC75
  },
  {
    company: '가우시움로봇비전',
    name: 'VC40',
    img: Gausium_VC40
  },
  {
    company: '도구공간',
    name: '다학제로봇',
    img: DOGU_iroi
  },
  {
    company: '로보티즈',
    name: '집개미',
    img: Robotics_Ant
  },
  {
    company: '베어로보틱스',
    name: 'Servi',
    img: BearRobotics_Servi
  },
  {
    company: '베어로보틱스',
    name: 'Servi Air',
    img: BearRobotics_ServiAir
  },
  {
    company: '오리온스타',
    name: '안내로봇',
    img: OrionStar_Guide
  },
  {
    company: '오리온스타',
    name: '포미',
    img: OrionStar_Forme
  },
  {
    company: '유진로봇',
    name: '고카트180',
    img: Yujin_GoCart180
  },
  {
    company: '유진로봇',
    name: '고카트250',
    img: Yujin_GoCart250
  },
  {
    company: '유진로봇',
    name: '고카트500',
    img: Yujin_GoCart500
  },
  {
    company: '키논로보틱스',
    name: 'T1',
    img: Keenon_T1
  },
  {
    company: '키논로보틱스',
    name: 'T2',
    img: Keenon_T2
  },
  {
    company: '키논로보틱스',
    name: 'T5',
    img: Keenon_T5
  },
  {
    company: '키논로보틱스',
    name: 'T6',
    img: Keenon_T6
  },
  {
    company: '키논로보틱스',
    name: 'T8',
    img: Keenon_T8
  },
  {
    company: '키논로보틱스',
    name: 'W3',
    img: Keenon_W3
  },
  {
    company: '푸두로보틱스',
    name: '벨라봇',
    img: Pudu_Bella
  },
  {
    company: '푸두로보틱스',
    name: '케이봇',
    img: Pudu_K
  },
  {
    company: '푸두로보틱스',
    name: '서빙로봇2',
    img: Pudu_Serving2
  },
  {
    company: '현대로보틱스',
    name: 'D1',
    img: Hyundai_D1
  },
  {
    company: 'KT',
    name: '다솜이',
    img: KT_Dasom
  },
  {
    company: 'LG전자',
    name: 'CLOi (방역로봇)',
    img: LG_CLOi_Quarantine
  },
  {
    company: 'LG전자',
    name: 'CLOi (배송로봇)',
    img: LG_CLOi_Delivery
  },
  {
    company: 'LG전자',
    name: 'CLOi (서빙로봇)',
    img: LG_CLOi_Serving
  },
  {
    company: 'LG전자',
    name: 'CLOi (안내로봇)',
    img: LG_CLOi_information
  },
  {
    company: 'OMRON',
    name: 'HD1500',
    img: OMRON_HD1500
  },
  {
    company: 'OMRON',
    name: 'LD60',
    img: OMRON_LD60
  },
  {
    company: 'OMRON',
    name: 'LD90',
    img: OMRON_LD90
  },
  {
    company: 'OMRON',
    name: 'LD250',
    img: OMRON_LD250
  },
  {
    company: 'Vesselo',
    name: 'VMR90',
    img: Vesselo_VMR90
  },
]

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ArrowBtn className="next" onClick={onClick}>
        <IoMdArrowRoundForward />
      </ArrowBtn>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ArrowBtn className="prev" onClick={onClick}>
        <IoMdArrowRoundBack />
      </ArrowBtn>
  );
}

export const ImgSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: windowWidth >= 1080 ? 5 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
    cssEase: "linear",
    beforeChange: (current: any, next: any) => {
      setActiveSlide(next);
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
      <RobotSlider>
        <Slider {...settings}>
          {RobotArr?.map((it, idx) => (
              <RobotWrapper  key={idx}>
                {(idx == 0 ? RobotArr?.length : idx == 1 ? RobotArr?.length + 1 : idx)
                == activeSlide + (windowWidth >= 1080 ? 2 : 1)
                    ? (
                        <ActiveRobotWrapper>
                          <ActiveRobot>
                            <img src={it.img} />
                          </ActiveRobot>
                          <ActionRobotDesc>
                            <h1>{it.company}</h1>
                            <h3>{it.name}</h3>
                          </ActionRobotDesc>
                        </ActiveRobotWrapper>
                    ) : (
                        <NonActiveRobot>
                          <img src={it.img} />
                        </NonActiveRobot>
                    )}
              </RobotWrapper>
          ))}
        </Slider>
      </RobotSlider>
  );
}

export default React.memo(ImgSlider);
