import { useEffect, useRef, useState } from "react";
import TriangleUpIcon from "../../assets/icon/icon_triangle_up.png";
import TriangleDownIcon from "../../assets/icon/icon_triangle_down.png";

interface PropsType {
  label: string;
  selected?: string;
  disabled?: boolean;
  list?: { [key: string]: any }[];
  onChange: (id: string) => void;
}

export default function Select({
  label,
  selected,
  disabled,
  list,
  onChange,
}: PropsType) {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    // 외부 클릭시 select 닫힘
    const handleClick = (e: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        setIsActive(false);
      }
    };
    window.addEventListener("mousedown", handleClick);
    return () => window.removeEventListener("mousedown", handleClick);
  }, [selectRef]);

  const onClick = () => {
    setIsActive((prev) => !prev);
  };

  const onSelect = (id: string) => {
    onChange(id);
    onClick();
  };

  return (
    <div
      className={`relative w-full rounded-[6px] border-[1px] border-gray-100 bg-white`}
      ref={selectRef}
    >
      <button
        className={`flex items-center justify-between w-full text-sm border-0 h-[38px] px-[15px] text-gray-500 ${
          disabled ? "cursor-default" : "cursor-pointer"
        }`}
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        <span>
          {selected
            ? list?.find(
                (l) =>
                  (l.id ?? l.taskId ?? l.floorName ?? l.taskName) === selected
              )?.name ??
              list?.find(
                (l) =>
                  (l.id ?? l.taskId ?? l.floorName ?? l.taskName) === selected
              )?.floorName ??
              list?.find(
                (l) =>
                  (l.id ?? l.taskId ?? l.floorName ?? l.taskName) === selected
              )?.taskName
            : label}
        </span>
        <img
          src={isActive ? TriangleUpIcon : TriangleDownIcon}
          alt="arrow"
          className="ml-4"
        />
      </button>

      <ul
        id="scroll"
        className={`bg-white absolute top-[40px] left-0 w-full list-none p-0 rounded-[4px] max-h-0 ease-linear duration-300 drop-shadow z-10 ${
          isActive && "max-h-[180px]"
        } ${list && list.length > 6 ? "overflow-auto" : "overflow-hidden"}`}
      >
        {list && list.length > 0 ? (
          list.map((l, idx) => (
            <li
              key={idx}
              className={`flex items-center justify-center h-[30px] p-[5px] text-[0.8rem] cursor-pointer hover:bg-[#d3ece8] ${
                selected === (l.id ?? l.floorName ?? l.taskName)
                  ? "bg-[#d3ece8]"
                  : "bg-white"
              }`}
              value={l.id ?? l.floorName ?? l.taskName}
              onClick={() =>
                onSelect(l.id ?? l.taskId ?? l.floorName ?? l.taskName)
              }
            >
              {l.name ?? l.floorName ?? l.taskName}
            </li>
          ))
        ) : (
          <li className="flex items-center justify-center h-[30px] p-[5px] text-[0.8rem] cursor-default bg-white">
            No Options
          </li>
        )}
      </ul>
    </div>
  );
}
