type PropsType = {
  currentPage: number;
  totalPages: number;
  onClickPrev: () => void;
  onClickNext: () => void;
};

export default function Pagination({
  currentPage,
  totalPages,
  onClickPrev,
  onClickNext,
}: PropsType) {
  return (
    <div className="flex justify-center items-center w-full my-2 text-[10px]">
      <button
        type="button"
        className={`font-bold text-base mx-4 ${
          currentPage === 1
            ? "text-[#85B09375]"
            : "text-[#4ca168] hover:brightness-110"
        }`}
        disabled={currentPage === 1}
        onClick={onClickPrev}
      >
        {`<`}
      </button>

      <p className="px-3 py-2 bg-[#85B09375] rounded-xl mx-1">{currentPage}</p>

      <button
        type="button"
        className={`font-bold text-base mx-4 ${
          totalPages === 0 || currentPage === totalPages
            ? "text-[#85B09375]"
            : "text-[#4ca168] hover:brightness-110"
        }`}
        disabled={totalPages === 0 || currentPage === totalPages}
        onClick={onClickNext}
      >
        {`>`}
      </button>
    </div>
  );
}
