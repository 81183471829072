import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
  User,
  UserCredential,
} from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import logo from '../assets/image/SOLlink.png';
import { useNavigate } from 'react-router-dom';
import useLoginStore from '../store/loginStore';
import checkIcon from '../assets/icon/icon_check_circle.svg';
import checkIconColored from '../assets/icon/icon_check_circle_colored.svg';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Permission, PermissionStatus } from '../_types/user';
import { useTranslation } from 'react-i18next';
import { FirestoreCollenctions } from '../_types';
import { postFirebaseUidToPlus } from '../_api/rest/user';

export default function SignUp() {
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const testPasswordRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isPasswordCompleted, setIsPasswordCompleted] = useState(false);
  const [focusPasswordTest, setFocusPasswordTest] = useState(false);
  const [targetPassword, setTargetPassword] = useState('');
  const [targetTestPassword, setTargetTestPassword] = useState('');
  const auth = getAuth();
  const { t } = useTranslation();

  const navigator = useNavigate();
  const { changeUserEmail } = useLoginStore();

  const checkPassword = (input: string) => {
    const lowerCaseAlphabet = /[a-z]/;
    const numbers = /\d/;
    const specialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    if (
      lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      !specialCharacter.test(input)
    ) {
      return true;
    } else if (
      lowerCaseAlphabet.test(input) &&
      !numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else if (
      !lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else if (
      lowerCaseAlphabet.test(input) &&
      numbers.test(input) &&
      specialCharacter.test(input)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsPasswordCorrect(checkPassword(targetPassword));
  }, [targetPassword]);

  useEffect(() => {
    if (targetPassword === targetTestPassword) {
      setIsPasswordCompleted(true);
    } else {
      setIsPasswordCompleted(false);
    }
  }, [targetPassword, targetTestPassword]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const email = emailRef.current?.value;
      const name = nameRef.current?.value;

      if (email && name) {
        setIsLoading(true);

        const { user }: Pick<UserCredential, 'user'> =
          await createUserWithEmailAndPassword(auth, email, targetPassword);

        await postFirebaseUidToPlus({ firebaseUid: user.uid });

        updateProfile(auth.currentUser as User, {
          displayName: name,
        });
        setDoc(doc(db, FirestoreCollenctions.USERS, user.uid), {
          auth_robots: [],
          auth_sites: [],
          display_name: name,
          email: user.email,
          joined_at: new Date(),
          permission: Permission.USER,
          phone: null,
          status: PermissionStatus.JOIN,
          updated_at: null,
          updated_by: null,
        });
        changeUserEmail(email);
        setIsSignUp(true);
        setIsLoading(false);
        setTimeout(() => navigator('/verification'), 1500);
      }
    } catch (error) {
      if (
        error instanceof Error &&
        'code' in error &&
        error.code === 'auth/email-already-in-use'
      ) {
        alert(t('the_email_is_already_registered'));

        navigator('/#login');
      }

      console.error(error);
    }
  };

  return (
    <div className="w-[100%] bg-[#08B29520]">
      <div className="h-[100vh] flex flex-col justify-center items-center drop-shadow-md">
        <div className="bg-white w-[360px] py-10 rounded-xl flex flex-col items-center">
          <>
            <img src={logo} className="w-[180px]" />
            <div className="my-2 text-xs text-gray-800">
              {t('sign_up_and_sexperience_solink')}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center items-center text-sm w-[300px] py-2"
            >
              <div className="text-sm text-gray-600 font-bold self-start my-1 w-[97%]">
                {t('email')}
              </div>
              <input
                name="email"
                type="text"
                placeholder={t('email')}
                required
                ref={emailRef}
                className="p-2 px-3 my-1 w-[97%] border"
              />
              <div className="text-sm text-gray-600 font-bold self-start mt-3 w-[97%]">
                {t('name')}
              </div>
              <input
                name="username"
                type="text"
                placeholder={`ex. ${t('nickname')}`}
                required
                ref={nameRef}
                className="p-2 px-3 my-1 w-[97%] border"
              />
              <div className="text-sm text-gray-600 self-start my-1 w-[97%] mt-3 flex items-center">
                <div className="font-bold">{t('password')}</div>
              </div>
              <input
                name="password"
                type="password"
                placeholder={t('password')}
                required
                ref={passwordRef}
                onChange={(evet) => {
                  setTargetPassword(evet.target.value);
                }}
                value={targetPassword}
                className="p-2 px-3 my-1 w-[97%] border"
              />

              <input
                name="password"
                type="password"
                placeholder={`${t('confirm')} ${t('password')}`}
                onFocus={() => setFocusPasswordTest(true)}
                onChange={(evet) => {
                  setTargetTestPassword(evet.target.value);
                }}
                value={targetTestPassword}
                required
                ref={testPasswordRef}
                className="p-2 px-3 my-1 w-[97%] border mb-3"
              />

              {focusPasswordTest && !isPasswordCompleted && (
                <div className="w-full pl-2 mb-5 text-xs font-bold text-left text-red-500">
                  {t('passwords_dont_match')}
                </div>
              )}
              <div className="flex self-start text-start text-[10px] font-bold mb-2">
                <div className="flex items-center mx-1">
                  <img
                    src={
                      targetPassword.length < 8 ? checkIcon : checkIconColored
                    }
                    className="w-[1rem] h-[1rem] mr-1"
                  />
                  <div
                    className={`leading-3 ${
                      targetPassword.length < 8
                        ? 'text-gray-500'
                        : 'text-[#08B295]'
                    }`}
                  >
                    {t('eight_or_more_digits')}
                  </div>
                </div>
                <div className="flex items-center mx-1">
                  <img
                    src={isPasswordCorrect ? checkIconColored : checkIcon}
                    className="w-[1rem] h-[1rem] mr-1"
                  />
                  <div
                    className={`leading-3 ${
                      isPasswordCorrect ? 'text-[#08B295]' : 'text-gray-500'
                    }`}
                  >
                    {t(
                      'at_least_2_of_lowercase_letters_numbers_special_characters',
                    )}
                  </div>
                </div>
              </div>
              {isSignUp ? (
                <div className="w-[100%] flex justify-center items-center text-sm">
                  <div className="hover:cursor-pointer bg-[#08B295] text-white w-[97%] rounded-full flex justify-center items-center p-2">
                    <div className="mx-1 fade-in-effect">
                      {t('registration_completed')}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20"
                      viewBox="0 -960 960 960"
                      width="20"
                      fill="white"
                      className="fade-in-effect"
                    >
                      <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                    </svg>
                  </div>
                </div>
              ) : isLoading ? (
                <div className="hover:cursor-pointer bg-[#08B295] text-white w-[97%] rounded-full flex justify-center items-center p-2">
                  <div className="mr-2">
                    {t('registration_pending_approval')}
                  </div>
                  <div className="loading-effect border border-2 rounded-full w-[1rem] h-[1rem] border-white border-t-[#08B295]"></div>
                </div>
              ) : (
                <input
                  type="submit"
                  disabled={!isPasswordCompleted}
                  value={t('sign_up')}
                  className={
                    isPasswordCompleted
                      ? 'hover:cursor-pointer bg-[#08B295] text-white w-[97%] rounded-full p-2'
                      : 'bg-gray-300 text-gray-500 w-[97%] rounded-full p-2'
                  }
                />
              )}
            </form>
          </>
        </div>
      </div>
    </div>
  );
}
