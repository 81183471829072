import { useEffect, useRef, useState } from 'react';

export type TCoordinates = { x: number; y: number };

interface UseCanvasProps {
  initialCoordinates: TCoordinates;
  imageSrc: string;
}

const INITIAL_POSITION = { x: 0, y: 0 };

const useCanvas = ({ initialCoordinates, imageSrc }: UseCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [coordinates, setCoordinates] =
    useState<TCoordinates>(initialCoordinates);
  const scaleRef = useRef<number>(1);
  const viewPosRef = useRef<TCoordinates>(INITIAL_POSITION);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const image = new Image();
    imageRef.current = image;
    image.src = imageSrc;

    const drawCoordinates = (
      ctx: CanvasRenderingContext2D,
      coordinates: TCoordinates,
    ) => {
      const canvas = ctx.canvas;
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous coordinates
      setTransform(ctx);
      if (imageRef.current) {
        ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
      }
      ctx.fillStyle = 'red';
      ctx.beginPath();
      ctx.arc(coordinates.x, coordinates.y, 10, 0, 2 * Math.PI); // Draw marker
      ctx.fill();
    };

    const setTransform = (ctx: CanvasRenderingContext2D) => {
      ctx.setTransform(
        scaleRef.current,
        0,
        0,
        scaleRef.current,
        viewPosRef.current.x,
        viewPosRef.current.y,
      );
    };

    image.onload = () => {
      drawCoordinates(ctx, coordinates);
    };

    return () => {
      // Cleanup function to clear interval or any other resources
    };
  }, [imageSrc, coordinates]);

  return { canvasRef, setCoordinates };
};

export default useCanvas;
