import { useEffect, useState } from 'react';
import { Select } from 'antd';
import useUserStore from '../../store/userStore';
import { SiteOptionType, SiteType } from '../../_types';
import useSiteData from '../../hooks/useSiteData';
import { t } from 'i18next';

interface PropsType {
  viewOption?: 'site' | 'all' | 'none';
  isAllSiteOption?: boolean;
  style?: React.CSSProperties;
  size?: 'small' | 'middle' | 'large';
  handleChangeSite?: (selectedId: string) => void;
}

export default function SearchInput({
  viewOption = 'site',
  isAllSiteOption = true,
  style,
  size = 'large',
  handleChangeSite,
}: PropsType) {
  const { changeSelectedUserAuthSites } = useUserStore();

  const { siteList } = useSiteData();
  const [optionSiteList, setOptionSiteList] = useState<SiteOptionType[]>([]);

  const [value, setValue] = useState<string | null>(null);

  const makeOptions = () => {
    const options = siteList.map((site) => {
      return {
        label: site.name,
        value: site.id!,
      };
    });

    const sortOptions = options.sort((a, b) => a.label.localeCompare(b.label));

    if (isAllSiteOption) {
      sortOptions.unshift({ label: '사이트 전체', value: 'all' });
    }

    setOptionSiteList(sortOptions);
  };

  const initializeView = () => {
    const recentSite = window.localStorage.getItem('recentSite');
    if (recentSite) {
      return setValue(recentSite);
    }

    if (viewOption === 'all') {
      return setValue(viewOption);
    }
    if (viewOption === 'none') {
      return setValue(null);
    }
    setValue(siteList.sort((a, b) => a.name.localeCompare(b.name))[0].id!);
  };

  const changeValue = (value: string) => {
    if (value !== 'all') {
      window.localStorage.setItem('recentSite', value);
    }

    setValue(value);
  };

  useEffect(() => {
    if (!siteList || siteList.length === 0) return;

    makeOptions();
    initializeView();
  }, [siteList]);

  useEffect(() => {
    handleChangeSite && handleChangeSite('');

    if (!value) {
      changeSelectedUserAuthSites([]);
      return;
    }
    if (value === 'all') {
      changeSelectedUserAuthSites(siteList.map((site: SiteType) => site.id!));
      return;
    }
    handleChangeSite && handleChangeSite(value);
    changeSelectedUserAuthSites([value]);
  }, [value]);

  return (
    <Select
      showSearch
      size={size}
      style={{ width: 350, ...style }}
      onChange={changeValue}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      placeholder={t('Please_choose_a_site')}
      options={optionSiteList}
      value={value}
    />
  );
}
