import ReactDOMServer from "react-dom/server";

interface MapOverlayProps {
  siteName: string;
  roadAddress: string;
  isVisibleOperationButton: boolean;
}

export default function MapOverlay({
  siteName,
  roadAddress,
  isVisibleOperationButton,
}: MapOverlayProps) {
  return ReactDOMServer.renderToString(
    <div id="content">
      <div id="title">{siteName}</div>
      <div id="address">{roadAddress}</div>
      {isVisibleOperationButton && (
        <div id="btn">
          <div id="robotBtn">
            <a href={`/robot?siteName=${siteName}`}>로봇운영</a>
          </div>
        </div>
      )}
    </div>
  );
}
