import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "../style/animation.css";

import useMenustore from "../store/menuStore";
import useUserData from "../hooks/useUserData";

import TotalCard from "../components/total/TotalCard";
import Gnb from "../components/layout/Gnb";
import Map from "../components/total/Map";

export default function TotalPage() {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menu2Ref = useRef<HTMLDivElement | null>(null);
  const [menu1Visible, setMenu1Visible] = useState(true);
  const { isShowMapMenu2, updateIsShowMapMenu2 } = useMenustore();
  const { user } = useUserData();

  const { t, i18n } = useTranslation();
  const lngs = {
    en: { nativeName: "🇺🇲" },
    ko: { nativeName: "🇰🇷" },
  } as any;

  const handleMenu1 = () => {
    if (menu1Visible) {
      menuRef.current?.classList.add("total-card-menu-off");
      menuRef.current?.classList.remove("total-card-menu-on");
    } else if (!menu1Visible) {
      menuRef.current?.classList.add("total-card-menu-on");
      menuRef.current?.classList.remove("total-card-menu-off");
    }
    setMenu1Visible(!menu1Visible);
    updateIsShowMapMenu2(false);
  };

  return (
    <div className="relative flex">
      <div
        ref={menuRef}
        style={{ width: "380px" }}
        className="z-10 fixed flex ml-[75px]"
      >
        <div className="z-30 w-[380px] h-[100vh] bg-white flex flex-col items-center p-5 pt-3 border-r">
          <TotalCard />
        </div>
        <div
          ref={menu2Ref}
          style={{
            transform: isShowMapMenu2 ? "translateX(93%)" : "translateX(-10%)",
            transition: "all 0.5s ease-in-out",
          }}
          className="fixed flex"
        >
          <div className="z-30 w-[380px]">
            <div className="w-[380px]">{/* <Menu2 /> */}</div>
          </div>
          <div className="">
            <div
              onClick={handleMenu1}
              className={
                isShowMapMenu2
                  ? "self-start p-2 bg-white border-r border-y hover:cursor-pointer my-2"
                  : "hidden"
              }
            >
              {menu1Visible ? "〈" : "〉"}
            </div>
          </div>
        </div>
        <div
          onClick={handleMenu1}
          className={
            isShowMapMenu2
              ? "hidden"
              : "self-start p-2 bg-white hover:cursor-pointer my-2 rounded-r-sm"
          }
        >
          {menu1Visible ? "〈" : "〉"}
        </div>
      </div>

      <div className="fixed z-10">
        <Gnb />
      </div>

      <div className="pl-[75px] w-[100%]">
        <Map />
      </div>
    </div>
  );
}
