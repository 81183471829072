import styled from "styled-components";

export const ManageWrapper = styled.div`
  width: 100vw;
  height: 1346px;
  background-color: #2F2F2F;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 242px;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    height: 1200px;
  }
  @media (max-width: 800px) {
    height: 1100px;
    padding-top: 160px;
  }
  @media (max-width: 450px) {
    height: 729px;
    padding-top: 70px;
  }
`
export const ManageTxtArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 69px;
  @media (max-width: 450px) {
    gap: 47px;
  }
`
export const ManageTitle = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    transform: translateY(2.3px);
    margin-right: 8px;
  }
  h4 {
    font-family: 'Pretendard';
    font-size: 50px;
    font-weight: 800;
    letter-spacing: 1px;
    span {
      font-weight: 400;
    }
  }
  h5 {
    margin-left: 18px;
    font-family: 'Pretendard';
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 50px;
    transform: translateY(2.3px);
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    h4, h5 {
      font-size: 40px;
    }
    img {
      width: 80px;
      height: 80px;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    h4, h5 {
      font-size: 26px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
`
export const ManageCountTxt = styled.span`
  background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-weight: 800;
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  transform: translateY(20px);
  margin-right: 10px;
`
export const ManageSubTitle = styled.div`
  color: #e2e2e2;
  font-family: 'Pretendard';
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  h1 {
    color: #18ECC4;
    font-size: 32px;
    font-weight: 700;
    display: inline-block;
  }
  h2, h3 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    display: inline-block;
  }
  h3 {
    margin-left: 10px;
  }
  @media (max-width: 800px) {
    h1 {
      display: block;
    }
    h2 {
      display: block;
    }
  }
  @media (max-width: 650px) {
    font-size: 24px;
    h1, h2, h3 {
      font-size: 24px;
    }
  }
  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 24px;
    h1, h2, h3 {
      font-size: 16px;
    }
  }
`
export const ThumbUpImg = styled.img`
  width: 489px;
  object-fit: contain;
  position: absolute;
  left: 218px;
  bottom: -115px;
  @media (max-width: 1800px) {
    width: 400px;
  }
  @media (max-width: 1480px) {
    width: 370px;
  }
`
export const SollinkDisplayImg = styled.img`
  width: 770px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  @media (max-width: 1800px) {
    width: 700px;
  }
  @media (max-width: 1480px) {
    width: 570px;
  }
  @media (max-width: 800px) {
    width: 370px;
  }
  @media (max-width: 450px) {
    width: 295px;
  }
`