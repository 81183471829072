import { useEffect, useRef } from 'react';
import useModalstore from '../../store/modalStore';
import CircleWarningIcon from '../../assets/icon/icon_circle_warning.svg';
import Button from '../common/Button';

export default function Modal() {
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    visible,
    title,
    contents,
    closeModal,
    type,
    onClickOk,
    okText,
    cancelText,
  } = useModalstore();

  // 외부 영역 스크롤 방지
  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  // 외부 영역 클릭시 모달창 닫힘
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        closeModal();
      }
    };

    window.addEventListener('mousedown', handleClick);

    return () => window.removeEventListener('mousedown', handleClick);
  }, [modalRef, closeModal]);

  return (
    <div
      className={`w-screen h-screen fixed top-0 left-0 flex justify-center items-center bg-backdrop transition-opacity z-[150] ${
        visible ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
    >
      <div
        className="relative w-1/3 max-w-md bg-white rounded-md"
        ref={modalRef}
      >
        {type === 'warning' && (
          <>
            <div className="flex justify-center bg-danger py-[25px] max-w-md rounded-t-md w-full min-w-[300px]">
              <img src={CircleWarningIcon} alt="warning" width={'40px'} />
            </div>
            <div className="p-4 flex flex-col gap-2.5 items-center w-full max-w-md min-w-[300px]  bg-white rounded-b-md">
              <span className="pt-1 text-lg font-bold">{title}</span>
              <div className="flex flex-col text-center text-[#525252] text-sm">
                {contents}
              </div>
              <div className="flex px-12 py-2 justify-center gap-[30px] w-full min-w-[300px]">
                <Button
                  label={cancelText as string}
                  full
                  circle
                  type="button"
                  size="small"
                  onClick={closeModal}
                />
                <Button
                  label={okText as string}
                  circle
                  full
                  type="button"
                  size="small"
                  danger
                  onClick={onClickOk}
                />
              </div>
            </div>
          </>
        )}
        {type === 'info' && (
          <>
            <header className="flex items-center justify-start px-6 py-3 bg-[#05B195] text-white text-xl font-bold rounded-t-md">
              {title}
            </header>
            <div className="flex flex-col text-center text-[#525252] text-sm">
              {contents}
            </div>
          </>
        )}
        {type === 'common' && (
          <>
            <header className="flex items-center justify-start px-6 py-3 bg-[#05B195] text-white text-xl font-bold rounded-t-md">
              {title}
            </header>
            <div className="overflow-auto max-h-[550px] px-6">{contents}</div>
          </>
        )}
      </div>
    </div>
  );
}
