import SollinkModal from "../../assets/image/image_inquiry_modal.png";
const InquiryModal = () => {
  return (
    <div
      className="fixed w-full h-[100vh] bg-[#00000025] z-[200]  flex justify-center items-center "
      data-name="background"
    >
      <div className="flex flex-col items-center justify-center py-10 pr-[75px]">
        <a
          // href={`${process.env.REACT_APP_MAROSOL}`}
          //   className="flex flex-col items-center justify-center py-10 "
          href="https://www.myrobotsolution.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src={SollinkModal} alt="솔링크 문의하기" />
        </a>
      </div>
    </div>
  );
};

export default InquiryModal;
