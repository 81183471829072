import React from 'react';

import {
    BaseEdge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
    useReactFlow,
} from 'reactflow';

import {PiFilePlus} from "react-icons/pi";
import useWorkflowStore from "../../store/workflowStore";

const CustomEdge = ({id,sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd}: EdgeProps) => {

    const {setEdges} = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const {
      edgeType
    } = useWorkflowStore();

    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {/*<button className="edgebutton" onClick={onEdgeClick}>*/}
                    {/*    ×*/}
                    {/*</button>*/}

                    <PiFilePlus size={20} />
                </div>
                {/*<div>*/}
                {/*    <PiFilePlus size={20} />*/}
                {/*</div>*/}
            </EdgeLabelRenderer>
        </>
    );
}


export default CustomEdge;