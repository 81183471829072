import { MouseEvent } from "react";

type PropsType = {
  label?: string;
  type?: "submit" | "button" | "reset";
  size?: "small" | "medium";
  circle?: boolean;
  persnalStyle?: string;
  outlined?: boolean;
  primary?: boolean;
  danger?: boolean;
  name?: string;
  icon?: string;
  onlyIcon?: boolean;
  disabled?: boolean;
  full?: boolean;
  onHover?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClick?: any;
};

export default function Button({
  label,
  type,
  size,
  circle,
  persnalStyle,
  primary,
  outlined,
  danger,
  icon,
  disabled,
  onHover,
  onClick,
  full,
  onlyIcon,
}: PropsType) {
  const buttonStyle = () => {
    let style = "bg-default active:opacity-50  ";

    if (primary) {
      style = "bg-primary text-white active:opacity-50";
    }

    if (outlined) {
      style = "border-[1px] border-gray-200 active:opacity-50";
    }

    if (danger) {
      style = "bg-danger text-white active:opacity-50";
    }

    if (disabled) {
      style = "bg-[#eee] active:opacity-100  text-white";
    }

    return style;
  };

  return (
    <button
      className={`text-xs  flex justify-center  items-center m-1 px-4 min-w-max ${
        circle ? "rounded-full" : "rounded-lg"
      } ${buttonStyle()} ${
        !onlyIcon && (size === "small" ? "py-2" : "py-3")
      } ${persnalStyle} ${full && "w-full"}`}
      disabled={disabled}
      type={type}
      onMouseOver={onHover}
      onMouseLeave={onHover}
      onClick={onClick}
    >
      <span className="text-center">{label}</span>
      {icon && (
        <img
          src={icon}
          alt="icon"
          width={"24px"}
          className={`${onlyIcon ? "py-1" : "w-4 ml-5"}`}
        />
      )}
    </button>
  );
}
