import {
  ContentType,
  PoiType,
  PostJobOrderType,
  RobotDetailType,
} from '../../_types';
import core from '../../config/axios';

const { client, isClient } = core;

export default function getAPI() {
  const getCompanyList = async () => {
    try {
      const { data } = await client.get('/dashboard/company/branch/total/list');
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getCompanyData = (deviceId: string) => {
    try {
      client
        .get(
          `/robot-control-single/insert-test-data?deviceId=${deviceId}&truncate=0`,
        )
        .then((res) => {
          if (res.data === 'SUCCESS') {
            return true;
          } else {
            return false;
          }
        });

      // const URL = `https://staging-api.sollink.ai/robot-control-single/insert-test-data?deviceId=${deviceId}&truncate=0`;
      // axios.get(URL).then((res) => {
      //   if (res.data === "SUCCESS") {
      //     return true;
      //   }
      // });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return { getCompanyList, getCompanyData };
}

export async function getDashboardData() {
  try {
    const res = await client.get(`/dashboard/total/robot/cnt`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getCmdList(deviceId: string) {
  try {
    const { data }: { data: RobotDetailType } = await client.get(
      `/robot/status/${deviceId}`,
    );

    return data ? data.commandList.sort((a, b) => a.order - b.order) : null;
  } catch (error) {
    console.error(error);
  }
}

export async function getIsRobotReady(deviceId: string | number) {
  try {
    const res = await client.get(
      `/robot/check-is-robot-ready?deviceId=${deviceId}`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function onStartJob(robotData: PostJobOrderType) {
  try {
    const res = await client.post(`/robot/command`, robotData);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getReportList(robotDetailData: any) {
  try {
    const res = await client.get(
      `/robot/gausium-task-report/${robotDetailData.deviceId}?page=1&pageSize=100`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getContentsList(deviceId: string) {
  try {
    const res = await client.get(
      `/robot/dogu/mission/pois/contents/${deviceId}`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postPlayContents(contentsData: {
  deviceId: string;
  poiList: PoiType[];
  contentList: ContentType[];
}) {
  try {
    const res = await client.post(
      `/robot/dogu/mission/pois/contents`,
      contentsData,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postPoiSync(deviceId: string) {
  try {
    const res = await client.post('/robot-poi/sync', { deviceId: deviceId });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
