import { Timestamp } from "firebase/firestore";

export enum Permission {
  SUPER_ADMIN,
  ENGINEER,
  USER,
}

export enum PermissionStatus {
  WAITING,
  DONE,
  JOIN,
}

export interface AuthType {
  id?: string;
  email: string;
  display_name: string;
  permission: Permission | null; // 어드민 관리자 권한
  status: PermissionStatus; // 관리자 권한 요청 처리 상태
  phone: string | null;
  joined_at: Date | Timestamp;
  updated_at: string | null;
  updated_by: string | null;
  auth_sites: string[];
  auth_robots: string[];
}

export interface UserType extends AuthType {
  emailVerified: boolean;
}
