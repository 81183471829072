import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
// import { BuilderModalBadge, EngineGifWrapper, EngineModalBadge, EngineModalDesc, EngineModalDescArea, GifWrapper, LinkerModalBadge, LinkerModalBottom, LinkerModalBottomSecond, LinkerModalBottomTxt, LinkerModalContent, LinkerModalDesc, LinkerModalDisplay, LinkerModalPill, LinkerModalPills, LinkerModalTitle, LinkerModalTitleArea, ModalBackBtn, ModalHeader, ModalProgressBar, ModalWrapper, PlatformImg, PlatformImgArea, PlatformImgSecond, PlatformModalBadge, PlatformModalBottom, PlatformModalDesc, PlatformModalDescArea } from './styles';
import { FaArrowLeft, FaYoutube } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import builder_page1 from '../../../assets/introduce/Image/builder_page1.gif'
import builder_page2 from '../../../assets/introduce/Image/builder_page2.gif'
import { Icon } from '../../../pages/IntroducePage';
import builderPage1_title from '../../../assets/introduce/Image/Modal/Builder/builderPage1_title.png'
import builderPage1_desc from '../../../assets/introduce/Image/Modal/Builder/builderPage1_desc.png'
import builderPage1_subTitle from '../../../assets/introduce/Image/Modal/Builder/builderPage1_subTitle.png'
import builderPage2_title from '../../../assets/introduce/Image/Modal/Builder/builderPage2_title.png'
import builderPage2_desc from '../../../assets/introduce/Image/Modal/Builder/builderPage2_desc.png'
import builderPage2_subTitle from '../../../assets/introduce/Image/Modal/Builder/builderPage2_subTitle.png'
import engineModalBg from '../../../assets/introduce/Image/engineModalBg.png'
import builderModalBg from '../../../assets/introduce/Image/builderModalBg.png'
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {MobileBuilderModal} from "./Mobile/MobileBuilderModal";

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ModalBackBtn className="next" onClick={onClick}>
        <FaChevronRight className={'CFaChevronRight'}/>
      </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
      <ModalBackBtn className="prev" onClick={onClick}>
        <FaChevronLeft className={'CFaChevronLeft'}/>
      </ModalBackBtn>
  );
}

export const BuilderModal = ({ setClicked }: {setClicked: Function}) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isMobile = useMediaQuery({query: '(max-width: 768px)'});

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
      <>
        {isMobile ?
            <MobileBuilderModal setClicked={setClicked} />
            :
            <ModalWrapper className="slider-container">
              <ModalProgressBar
                  onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
                  value={slideIndex}
                  type="range"
                  min={0}
                  max={1}
              />
              <LinkerModalContent className='builder'>
                <ModalHeader>
                  <ModalBackBtn onClick={() => setClicked('')}>
                    <FaArrowLeft className={'CFaArrowLeft'}/>
                  </ModalBackBtn>
                </ModalHeader>
                <Slider
                    ref={sliderRef}
                    {...settings}
                >
                  <LinkerModalDisplay>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='builder_page1'>
                        <BuilderModalBadge>Workflow Builder</BuilderModalBadge>
                        <BuilerTitleImg src={builderPage1_title} />
                      </LinkerModalTitle>
                      <LinkerModalDesc className='builder_page1'>
                        <BuilderDescImg src={builderPage1_desc} />
                      </LinkerModalDesc>
                    </LinkerModalTitleArea>
                    <PlatformModalBottom className='builder_page1'>
                      <BuilderSubtitleImg src={builderPage1_subTitle} />
                    </PlatformModalBottom>
                    <EngineGifWrapper className='builder_page1'>
                      <BuilderGif src={builder_page1} />
                      <a href='https://www.youtube.com/watch?v=IdclecFv2PU' target='blank'><Icon><FaYoutube /></Icon>Workflow Builder 기능 상세보기</a>
                    </EngineGifWrapper>
                  </LinkerModalDisplay>

                  <LinkerModalDisplay>
                    <LinkerModalTitleArea>
                      <LinkerModalTitle className='builder_page2'>
                        <BuilderModalBadge>Workflow Builder</BuilderModalBadge>
                        <BuilerTitleTwoImg src={builderPage2_title} />
                      </LinkerModalTitle>
                      <LinkerModalDesc className='builder_page2'>
                        <BuilderDescTwoImg src={builderPage2_desc} />
                        <BuilderSubtitleTwoImg src={builderPage2_subTitle} />
                      </LinkerModalDesc>
                    </LinkerModalTitleArea>
                    <EngineGifWrapper className='builder_page2'>
                      <BuilderTwoGif src={builder_page2} />
                    </EngineGifWrapper>
                  </LinkerModalDisplay>
                </Slider>
              </LinkerModalContent>
            </ModalWrapper>
        }
      </>
  );
}

export default React.memo(BuilderModal)

const BuilerTitleImg = styled.img`
  width: 793px;
  margin-top: 30px;
  
  @media (max-width: 1920px) {
    width: 41.30vw;
    margin-top: 1.56vw;
  }
`

const BuilderDescImg = styled.img`
  width: 381px;

  @media (max-width: 1920px) {
    width: 19.84vw;
  }
`

const BuilderSubtitleImg = styled.img`
  width: 575px;

  @media (max-width: 1920px) {
    width: 29.95vw;
  }
`

const BuilderGif = styled.img`
  width: 790px;

  @media (max-width: 1920px) {
    width: 41.14vw;
  }
`

const BuilerTitleTwoImg = styled.img`
  width: 608px;
  margin-top: 30px;
  
  @media (max-width: 1920px) {
    width: 31.67vw;
    margin-top: 1.56vw;
  }
`

const BuilderDescTwoImg = styled.img`
  width: 746px;

  @media (max-width: 1920px) {
    width: 38.96vw;
  }
`

const BuilderSubtitleTwoImg = styled.img`
  width: 734px;
  margin-top: 34px;

  @media (max-width: 1920px) {
    width: 38.33vw;
    margin-top: 1.77vw;
  }
`

const BuilderTwoGif = styled.img`
  width: 790px;
  
  @media (max-width: 1920px) {
    width: 41.14vw;
   
  }
`

















const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 66px;
  right: 200px;
  z-index: 9999;
  width: 100px;
  height: 17px;

  @media (max-width: 1920px) {
    top: 3.44vw;
    right: 10.42vw;
    width: 5.21vw;
    height: 0.89vw;
  }
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;

  @media (max-width: 1920px) {
    width: 2.604vw;
    height: 2.604vw;
  }

  &.next {
    top: -43px;
    right: 0;

    @media (max-width: 1920px) {
      top: -2.2396vw;
    }
  }

  &.prev {
    top: -43px;
    right: 60px;

    @media (max-width: 1920px) {
      top: -2.2396vw;
      right: 3.125vw;
    }
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 50px 68px;
  display: flex !important;
  flex-direction: column;
  gap: 45px;
  background-color: #000;

  @media (max-width: 1920px) {
    gap: 2.34vw;
    padding: 2.604vw 3.542vw;
  }
  
  &.builder {
    background-image: url(${builderModalBg});
  }
  
`
const LinkerModalDisplay = styled.div`
  //display: flex !important;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: space-between;
  //height: calc(100vh - 170px);
  //padding: 0px 80px;
  //padding-bottom: 128px;
  //position: relative;
  //@media (max-width: 1250px) {
  //  padding: 0px 20px;
  //}
  //@media (max-width: 450px) {
  //  padding: 0px;
  //}
`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 696px;
  width: auto;
  margin-left: 80.33px;
  margin-top: 33.85px;

  @media (max-width: 1920px) {
    margin-left: 4.1802vw;
    margin-top: 3.1296vh;

  }
`
const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-left: 80.33px;
  margin-top: 46px;
  margin-bottom: 20px;

  @media (max-width: 1920px) {
    margin-left: 4.18vw;
    margin-top: 2.39vw;
    margin-bottom: 1.04vw;
  }
  
  
  &.builder_page2{
    margin-left: 80.33px;
    margin-top: 46px;
    margin-bottom: 0;

    @media (max-width: 1920px) {
      margin-left: 4.18vw;
      margin-top: 1.88vw;
    }
  }
  
`

const PlatformModalBottom = styled.p`
  margin-left: 80.33px;
  
  @media (max-width: 1920px) {
    margin-left: 4.18vw;
  }
`

const EngineGifWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 20px;

  @media (max-width: 1920px) {
    padding-right: 1.04vw;
  }
  
  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    line-height: 140%;

    @media (max-width: 1920px) {
      gap: 0.31vw;
      font-size: 0.94vw;
    }
  }
  
  
`

const BuilderModalBadge = styled.div`
  width: 242px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.56px;

  @media (max-width: 1920px) {
    width: 12.60vw;
    height: 2.39vw;
    border: 0.03vw solid var(rgba(255, 255, 255, 0.20));
    text-shadow: 0px 0px 2.08vw #FFF;
    font-size: 1.46vw;
    letter-spacing: -0.03vw;
  }
`

