import React, { useEffect, useState } from 'react'
import { TextAreaBot, TextAreaTop, TextAreaWrapper } from './styles'

export const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TextAreaWrapper>
      <TextAreaTop>고민하시던 로봇 관리 시스템,</TextAreaTop>
      <TextAreaBot>
        <span>SOLlink가 수준 높은 솔루션 </span>
        으로 <br style={{display: windowWidth >= 800 ? 'none' : 'block'}} />해결하겠습니다.
      </TextAreaBot>
    </TextAreaWrapper>
  )
}

export default React.memo(Banner)