import React, { useEffect, useState } from 'react'
import { ExperienceBotDesc, ExperienceBotIconsBot, ExperienceBotIconsTop, ExperienceBottom, ExperienceBottomIcons, ExperienceBtn, ExperienceDesc, ExperienceIcon, ExperienceIconImg, ExperienceIconTxt, ExperienceLogo, ExperienceLogoArea, ExperienceLogoTxt, ExperienceMidDesc, ExperienceTop, ExperienceTopDesc, ExperienceTopDescLine, ExperienceWrapper } from './styles';
import Logo from '../../assets/introduce/Image/Logo.png'
import { CiShare1 } from "react-icons/ci";
import { Icon } from '../../pages/IntroducePage';
import experience_Icon1 from '../../assets/introduce/Image/experience_Icon1.png'
import experience_Icon2 from '../../assets/introduce/Image/experience_Icon2.png'
import experience_Icon3 from '../../assets/introduce/Image/experience_Icon3.png'
import experience_Icon4 from '../../assets/introduce/Image/experience_Icon4.png'
import {logEvent} from "firebase/analytics";
import {analytics} from "../../config/firebase";
import {INQUIRY_LOG} from "../../constants/logEvent";

export const Experience = () => {
  const [scroll, setScroll] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      setScroll(currentScrollY);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickApplyMemo = () => {
    const isMobile = /iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent);

    logEvent(
        analytics,
        !isMobile
            ? INQUIRY_LOG.P_INQUIRY_SOLLINK_CLICK
            : INQUIRY_LOG.M_INQUIRY_SOLLINK_CLICK
    );
    window.location.href = "https://www.myrobotsolution.com/inquiry/sollink";
  };


  return (
    <ExperienceWrapper>
      <ExperienceTop>
        <ExperienceLogoArea>
          <ExperienceLogo src={Logo} />
          <ExperienceLogoTxt>세상의 <span>모든 로봇</span>을 연결하다</ExperienceLogoTxt>
        </ExperienceLogoArea>
        <ExperienceDesc>
          <ExperienceTopDesc>
            도입된 로봇을 현장에서 원격으로 관제•제어하세요.
            <ExperienceTopDescLine className={scroll >= 900 ? 'active' : ''} />
          </ExperienceTopDesc>
          <ExperienceMidDesc>
            SOLlink는 글로벌 No.1 다종•이종 로봇 <br style={{display: windowWidth >= 900 ? 'none' : 'block'}} />
            통합관제시스템으로 <br style={{display: windowWidth >= 900 ? 'block' : 'none'}} />
            현장에 투입된 모든 로봇과 <br style={{display: windowWidth >= 900 ? 'none' : 'block'}} />
            인프라를 연결하여 실시간 원격 제어 및 통합관제가 <br style={{display: windowWidth >= 550 ? 'none' : 'block'}} />
            가능합니다.
          </ExperienceMidDesc>
          <ExperienceBotDesc>
            세상 모든 로봇과의 연결, <br style={{display: windowWidth >= 650 ? 'none' : 'block'}} />
            그 이상의 확장된 서비스를 직접 확인하세요.
          </ExperienceBotDesc>
        </ExperienceDesc>
      </ExperienceTop>
      <ExperienceBottom>
        <ExperienceBottomIcons>
          <ExperienceBotIconsTop>
            <ExperienceIcon>
              <ExperienceIconImg src={experience_Icon1} />
              <ExperienceIconTxt>직관적인 UI로<br/>손쉬운 로봇 명령</ExperienceIconTxt>
            </ExperienceIcon>
            <ExperienceIcon>
              <ExperienceIconImg src={experience_Icon2} />
              <ExperienceIconTxt>실시간 오류 알림과<br/>원격 모니터링,<br />정기적인 업데이트</ExperienceIconTxt>
            </ExperienceIcon>
          </ExperienceBotIconsTop>
          <ExperienceBotIconsBot>
            <ExperienceIcon>
              <ExperienceIconImg src={experience_Icon3} />
              <ExperienceIconTxt>이종•다종 로봇<br/>연결</ExperienceIconTxt>
            </ExperienceIcon>
            <ExperienceIcon>
              <ExperienceIconImg src={experience_Icon4} />
              <ExperienceIconTxt>로봇•인프라<br/>연결</ExperienceIconTxt>
            </ExperienceIcon>
          </ExperienceBotIconsBot>
        </ExperienceBottomIcons>
        <ExperienceBtn onClick={onClickApplyMemo}>
          SOLlink 체험하기
          <Icon><CiShare1 /></Icon>
        </ExperienceBtn>
      </ExperienceBottom>
    </ExperienceWrapper>
  )
}


export default React.memo(Experience);