import React, { useEffect, useState } from 'react'
import { TechnicalTop, TechnicalTopBtn, TechnicalTopBtnIcon, TechnicalTopBtnTxt, TechnicalTopBtns, TechnicalTopDisplay, TechnicalTopSubTitle, TechnicalTopTitle, TechnicalTopTxt } from './styles';
import linker_select from '../../assets/introduce/Image/linker_select.png'
import builder_select from '../../assets/introduce/Image/Builder_select.png'
import engine_select from '../../assets/introduce/Image/Engine_select.png'
import platform_select from '../../assets/introduce/Image/platform_select.png'
import pendant_select from '../../assets/introduce/Image/pendant_select.png'
import linker_notSelect from '../../assets/introduce/Image/linker_notSelect.png'
import builder_notSelect from '../../assets/introduce/Image/Builder_notSelect.png'
import engine_notSelect from '../../assets/introduce/Image/Engine_notSelect.png'
import platform_notSelect from '../../assets/introduce/Image/platform_notSelect.png'
import pendant_notSelect from '../../assets/introduce/Image/pendant_notSelect.png'

export const Top = ({ select, setSelect }: { select: string, setSelect: Function }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <TechnicalTop>
        <TechnicalTopDisplay>
          {/* 상단 텍스트 */}
          <TechnicalTopTxt>
            <TechnicalTopTitle>
              이 모든 서비스를 가능하게 한
              <p>마로솔의 Technical Enabler</p>
            </TechnicalTopTitle>
            <TechnicalTopSubTitle>
              마로솔만의 독자적이고 전문적인 <br style={{display: windowWidth >= 600 ? 'none' : 'block'}} />
              SOLlink 기능을 공개합니다.
            </TechnicalTopSubTitle>
          </TechnicalTopTxt>

          {/* 상단 버튼 */}
          <TechnicalTopBtns>
            <TechnicalTopBtn>
              <TechnicalTopBtnIcon
                  onMouseEnter={() => setSelect('linker')}
                  onClick={() => setSelect('linker')}
                  className={select == 'linker' ? 'selected' : ''}
              >
                {select == 'linker' ? (
                    <img src={linker_select} />
                ) : (
                    <img src={linker_notSelect} />
                )}
              </TechnicalTopBtnIcon>
              <TechnicalTopBtnTxt className={select == 'linker' ? 'selected' : ''}>Linker</TechnicalTopBtnTxt>
            </TechnicalTopBtn>
            <TechnicalTopBtn>
              <TechnicalTopBtnIcon
                  onMouseEnter={() => setSelect('builder')}
                  onClick={() => setSelect('builder')}
                  className={select == 'builder' ? 'selected' : ''}
              >
                {select == 'builder' ? (
                    <img src={builder_select} />
                ) : (
                    <img src={builder_notSelect} />
                )}
              </TechnicalTopBtnIcon>
              <TechnicalTopBtnTxt className={select == 'builder' ? 'selected' : ''}>Workflow<br />Builder</TechnicalTopBtnTxt>
            </TechnicalTopBtn>
            <TechnicalTopBtn>
              <TechnicalTopBtnIcon
                  onMouseEnter={() => setSelect('engine')}
                  onClick={() => setSelect('engine')}
                  className={select == 'engine' ? 'selected' : ''}
              >
                {select == 'engine' ? (
                    <img src={engine_select} />
                ) : (
                    <img src={engine_notSelect} />
                )}
              </TechnicalTopBtnIcon>
              <TechnicalTopBtnTxt className={select == 'engine' ? 'selected' : ''}>Workflow<br />Engine</TechnicalTopBtnTxt>
            </TechnicalTopBtn>
            <TechnicalTopBtn>
              <TechnicalTopBtnIcon
                  onMouseEnter={() => setSelect('platform')}
                  onClick={() => setSelect('platform')}
                  className={select == 'platform' ? 'selected' : ''}
              >
                {select == 'platform' ? (
                    <img src={platform_select} />
                ) : (
                    <img src={platform_notSelect} />
                )}
              </TechnicalTopBtnIcon>
              <TechnicalTopBtnTxt className={select == 'platform' ? 'selected' : ''}>Platform</TechnicalTopBtnTxt>
            </TechnicalTopBtn>
            <TechnicalTopBtn>
              <TechnicalTopBtnIcon
                  onMouseEnter={() => setSelect('pendant')}
                  onClick={() => setSelect('pendant')}
                  className={select == 'pendant' ? 'selected' : ''}
              >
                {select == 'pendant' ? (
                    <img src={pendant_select} />
                ) : (
                    <img src={pendant_notSelect} />
                )}
              </TechnicalTopBtnIcon>
              <TechnicalTopBtnTxt className={select == 'pendant' ? 'selected' : ''}>Pendant</TechnicalTopBtnTxt>
            </TechnicalTopBtn>
          </TechnicalTopBtns>
        </TechnicalTopDisplay>
      </TechnicalTop>
  )
}

export default React.memo(Top);