import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { io } from 'socket.io-client';
import Progress from '../schedule/Progress';
import Empty from '../empty';
import Spinner from '../loading/Spinner';
import useScheduleStore from '../../store/scheduleStore';
import useUserStore from '../../store/userStore';
import { CurrentTask, ScheduleSocketData } from '../../_types';
import { SECOND_SOCKET_URL } from '../../_api/webSocket';
import warningIcon from '../../assets/icon/icon_warning.svg';

const Ongoing = () => {
  const { selectedUserAuthSite } = useUserStore();

  const { updateScheduleData, scheduleData } = useScheduleStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [allCurrentTaskList, setAllCurrentTaskList] = useState<
    (CurrentTask & { siteId: string })[][] | null
  >(null);

  useEffect(() => {
    if (!scheduleData || selectedUserAuthSite.length === 0) return;

    setLoading(true);

    scheduleData.length > 0
      ? setLoading(false)
      : setTimeout(() => {
          setLoading(false);
        }, 800);

    const allCurrentTaskList =
      scheduleData.length > 0
        ? scheduleData
            ?.filter(({ siteId }) => selectedUserAuthSite.includes(siteId))
            .map(({ currentTask, siteId }) =>
              currentTask.map((task) => ({
                ...task,
                siteId,
              })),
            )
        : null;

    setAllCurrentTaskList(allCurrentTaskList);
  }, [scheduleData, selectedUserAuthSite]);

  useEffect(() => {
    const siteSocket = io(`${SECOND_SOCKET_URL}/sites`, {
      transports: ['websocket'],
      upgrade: true,
    });

    const onConnect = () => {
      console.log('site socket connected');
    };

    const getSocketData = (data: ScheduleSocketData[]) => {
      updateScheduleData(data);
    };
    siteSocket.connect();
    siteSocket.on('connect', onConnect);
    siteSocket.on('sites', getSocketData);

    return () => {
      siteSocket.disconnect();
      siteSocket.off('connect', onConnect);
      siteSocket.off('sites', getSocketData);
    };
  }, [selectedUserAuthSite]);
  return (
    <>
      {!loading ? (
        allCurrentTaskList && allCurrentTaskList.length > 0 ? (
          allCurrentTaskList?.map(
            (currentTaskList: (CurrentTask & { siteId: string })[], idx) => (
              <Progress
                key={idx}
                loading={loading}
                curentTaskList={currentTaskList}
              />
            ),
          )
        ) : (
          <div className="flex items-center justify-center w-full pt-[120px]  ">
            <Empty
              mainText={t('There_are_no_ongoing_works_at_the_moment')}
              subText={t('There_is_no_work_available_on_the_selected_site')}
            >
              {<img src={warningIcon} className="w-[48px] py-4" />}
            </Empty>
          </div>
        )
      ) : (
        <div className="mt-[250px]">
          <Spinner loading={loading} />
        </div>
      )}
    </>
  );
};

export default Ongoing;
