import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PeriodType } from "../_types/dashBoard";

export default function usePeriod() {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: null,
    endDate: null,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startDate =
    searchParams.get("start") === null
      ? dateFormat(lastMonth)
      : searchParams.get("start");
  const endDate =
    searchParams.get("end") === null ? getToday() : searchParams.get("end");

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [endDate, startDate]);

  return period;
}

export function dateFormat(date: any) {
  const year = date.getFullYear(); //yyyy
  let month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  let day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  return year + "-" + month + "-" + day;
}

export function getToday() {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
}
