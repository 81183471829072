import { ReactNode } from "react";

interface EmptyProps {
  mainText: string;
  subText: string;
  children?: ReactNode;
  size?: "small" | "medium" | "large";
}

const Empty = ({ mainText, subText, children, size }: EmptyProps) => {
  const getSize = () => {
    let style = "w-[500px]";

    if (size === "small") style = "w-[300px]";
    if (size === "large") style = "w-[800px]";
    return style;
  };

  return (
    <div
      className={`${getSize()} rounded-lg p-10 text-white flex flex-col items-center justify-center gap-4 bg-[#09c9a9] shadow-md`}
    >
      {children}
      <div className="text-3xl text-center">{mainText}</div>
      <div className="text-sm text-center">{subText}</div>
    </div>
  );
};

export default Empty;
