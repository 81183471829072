import { useEffect, useState } from 'react';
import { getAllSiteListOnSnapshot } from '../_api/firestore/site';

import { SiteType } from '../_types/site';
import { AuthType, Permission } from '../_types/user';
import { FirestoreCollenctions } from '../_types/common';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import useLoginStore from '../store/loginStore';
import { getUserById } from '../_api/firestore/user';
import useUserStore from '../store/userStore';

export default function useSiteData() {
  const [userData, setUserData] = useState<AuthType | null>(null);
  const [siteList, setSiteList] = useState<SiteType[]>([]);

  const { userInfo } = useLoginStore();
  const { userStatus } = useUserStore();

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      getAllSiteListOnSnapshot(setSiteList);
      return;
    }

    const fetchData = async () => {
      const siteData: SiteType[] = [];

      userData.auth_sites.map(async (siteId) => {
        const docRef = doc(db, FirestoreCollenctions.SITES, siteId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = {
            id: docSnapshot.id,
            ...(docSnapshot.data() as SiteType),
          };

          siteData.push(data);
        } else {
          return null;
        }

        setSiteList(siteData as SiteType[]);
      });
    };

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      fetchData();
    }
  }, [userData, userStatus]);

  return { siteList };
}
