import { Simulate } from 'react-dom/test-utils';
import load = Simulate.load;

type Size = 'small' | 'medium' | 'large';

interface LoadingProps {
  personalStyle?: string;
  size?: Size;
  loading?: boolean;
}

const Spinner = ({ personalStyle, size = 'medium', loading }: LoadingProps) => {
  const getSize = () => {
    if (size === 'small') return 'h-4 w-4 border-2 ';
    if (size === 'medium') return 'h-8 w-8 border-4 ';
    if (size === 'large') return 'h-12 w-12 border-6 ';
  };

  return loading ? (
    <div className={`flex items-center justify-center w-full ${personalStyle}`}>
      <div
        className={`inline-block  animate-spin text-primary rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${getSize()}`}
        role="status"
      ></div>
    </div>
  ) : null;
};

export default Spinner;
