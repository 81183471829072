import React, {useEffect, useRef, useState} from 'react'
import Slider from "react-slick";
import {FaArrowLeft} from "react-icons/fa";
import {FaChevronLeft} from "react-icons/fa";
import {FaChevronRight} from "react-icons/fa";
import Linker_B_A from '../../../../assets/introduce/Image/Linker_B&A.gif'
import { Icon } from '../../../../pages/IntroducePage';
import {FaYoutube} from "react-icons/fa";
import linkerModalBg from '../../../../assets/introduce/Image/linkerModalBg.png'
import linkerPage1Bottom from '../../../../assets/introduce/Image/Modal/Linker/LinkModalBottom.png'
import linkerPage1Title from '../../../../assets/introduce/Image/Modal/Linker/LinkerPage1Title.png'
import linkerPage2Title from '../../../../assets/introduce/Image/Modal/Linker/LinkerPage2Title.png'
import linkerPage1SubTitle from '../../../../assets/introduce/Image/Modal/Linker/LinkerPage1SubTitle.png'
import linkerPage2SubTitle_1 from '../../../../assets/introduce/Image/Modal/Linker/LinkerPage2SubTitle_1.png'
import linkerPage2SubTitle_2 from '../../../../assets/introduce/Image/Modal/Linker/LinkerPage2SubTitle_2.png'
import styled from "styled-components";
import '../CustomizeStyles.css'

function NextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <ModalBackBtn className="next" onClick={onClick}>
      <FaChevronRight className={'CMobileFaChevronRight'} />
    </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <ModalBackBtn className="prev" onClick={onClick}>
      <FaChevronLeft className={'CMobileFaChevronLeft'}/>
    </ModalBackBtn>
  );
}

export const MobileLinkerModal = ({setClicked}: { setClicked: Function }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
    <ModalWrapper className="slider-container">
      <ModalProgressBar
        onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
        value={slideIndex}
        type="range"
        min={0}
        max={1}
      />
      <LinkerModalContent style={{backgroundImage: `url(${linkerModalBg})`}}>
        <ModalHeader>
          <ModalBackBtn onClick={() => setClicked('')}>
            <FaArrowLeft className={'CMobileFaArrowLeft'}/>
          </ModalBackBtn>
        </ModalHeader>
        <Slider
          ref={sliderRef}
          {...settings}
        >
          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='LinkerPage1'>
                <LinkerModalBadge>Linker</LinkerModalBadge>
                <LinkerTitleImg src={linkerPage1Title}/>
              </LinkerModalTitle>
              <LinkerModalDesc className='linker_page1'>
                <LinkerSubTitleImg src={linkerPage1SubTitle} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <LinkerModalBottomSection>
              <LinkerModalBottom>
                <LinkerConnectImg src={linkerPage1Bottom}/>
              </LinkerModalBottom>
              <LinkerBeforeAfterGIFImg src={Linker_B_A}/>
            </LinkerModalBottomSection>
          </LinkerModalDisplay>

          <LinkerModalDisplay >
            <LinkerModalTitleArea className='linker_page2'>
              <LinkerModalTitle className='linker_page2'>
                <LinkerModalBadge>Linker</LinkerModalBadge>
                <LinkerTitleImg src={linkerPage2Title}/>
              </LinkerModalTitle>
              <LinkerModalDesc className='linker_page2'>
                <LinkerSubTitleTwoImg src={linkerPage2SubTitle_1} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <LinkerModalBottomSecond>
              <LinkerSubTitleBottomImg src={linkerPage2SubTitle_2}/>

              <GifWrapper className='linker_page2'>
                <LinkerVideoImg src={'/Video/keyVisual_Linker_video.gif'}/>
                <a href='https://www.youtube.com/watch?v=2tYtKabCduM' target='blank'><Icon><FaYoutube/></Icon>SOLlink 로봇
                  간·인프라 간 연동 더보기</a>
              </GifWrapper>
            </LinkerModalBottomSecond>
          </LinkerModalDisplay>
        </Slider>
      </LinkerModalContent>
    </ModalWrapper>
  );
}

export default React.memo(MobileLinkerModal)

// ------------- 추가 스타일 코드 --------------------

const LinkerTitleImg = styled.img`
  width: 65.10vw;
`

const LinkerSubTitleImg = styled.img`
  width: 88.32vw;
`

const LinkerSubTitleTwoImg = styled.img`
  width: 87.24vw;
`

const LinkerConnectImg = styled.img`
  width: 71.61vw;
`

const LinkerBeforeAfterGIFImg = styled.img`
  width: 85.93vw;
  margin-top: 5.21vw;
`

const LinkerSubTitleBottomImg = styled.img`
  width: 85.42vw;
  height: 16.54vw;
  margin-top: 1.95vw;
`

const LinkerVideoImg = styled.img`
  width: 100.52vw;
  height: 56.37vw;
  margin-top: 1.95vw;
`



// ------------- 이하 기존의 스타일 코드 ---------------

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 5.98958vw;
  right: 22.1354vw;
  z-index: 9999;
  width: 6.51042vw;
  height: 2.1875vw;
  
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 6.51042vw;
  height: 6.51042vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;


  &.next {
    top: -5.59948vw;
    right: 0;
  }

  &.prev {
    top: -5.59948vw;
    right: 7.8125vw;
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 3.90625vw 5.20833vw;
  display: flex !important;
  flex-direction: column;
  gap: 5.85938vw;
  background-color: #000;
  
  
`
const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90.625vw;
  width: auto;
  margin-top: 4.40495vw;
  
`
const LinkerModalBadge = styled.div`
  width: 13.5417vw;
  height: 5.72917vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.5px solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(255, 202, 64, 0.72) 0%, rgba(255, 202, 64, 0.20) 55.21%, rgba(255, 202, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 40px #FFF;
  font-size: 3.64583vw;
  font-weight: 700;
  letter-spacing: -0.0729167vw;
  margin-bottom: 3.90625vw;
  
`
const LinkerModalDesc = styled.p`
  font-size: 3.90625vw;
  color: #fff;
  font-weight: 400;
  line-height: 3.64583vw;
  white-space: nowrap;
  margin-top: 4.16667vw;
`

const LinkerModalBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`

const LinkerModalBottom = styled.div`
  margin-top: 5.20833vw;
  
`
const LinkerModalBottomSecond = styled.div`
  display: flex;
  flex-direction: column;
`


const GifWrapper = styled.div`

  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 0.78125vw;
    font-size: 2.34375vw;
    line-height: 140%;
    
  }
  

`

