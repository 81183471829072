import { create } from "zustand";

interface DashboardStore {
  currentDate: string | null;
  oneYearAgoDate: string | null;
  oneMonthAgoDate: string | null;
  changeCurrentDate: (input: string) => void;
  changeOneYearAgoDate: (input: string) => void;
  changeOneMonthAgoDate: (input: string) => void;
}

const useDashboardStore = create<DashboardStore>()((set) => ({
  currentDate: null,
  oneYearAgoDate: null,
  oneMonthAgoDate: null,
  changeCurrentDate: (input) =>
    set((state) => ({
      currentDate: input,
    })),
  changeOneYearAgoDate: (input) =>
    set((state) => ({
      oneYearAgoDate: input,
    })),
  changeOneMonthAgoDate: (input) =>
    set((state) => ({
      oneMonthAgoDate: input,
    })),
}));

export default useDashboardStore;
