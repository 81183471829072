import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    debug: false,
    fallbackLng: 'ko',
    resources: {
      ko: {
        translation: {
          Time: '시간',
          real_time: '실시간',
          dashboard: '대시보드',
          robot_operation: '로봇운영',
          schedule: '스케줄',
          bookmark: '즐겨찾기',
          notification: '알림이력',
          real_time_solink_usage_status: '실시간 솔링크 이용 현황',
          last_30_minutes_count: '지난 30분 동안의 작업지시 (건)',
          per_minute_count: '분당 작업지시 건수',
          robots_introduced_count: '도입로봇 수',
          site: '사이트',
          full_registration_robot: '전체 등록 로봇',
          real_time_view: '실시간',
          unit: '대',
          robot_type_and_registration_status: '로봇 타입 및 등록 현황',
          total_cumulative_number_of_services: '연 서비스 건수',
          case: '건',
          total_average_number_of_services: '월 평균 서비스 건수',
          robot_with_the_most_errors: '최다 에러 발생 로봇',
          robot: '로봇',
          robot_1: '로봇1',
          robot_2: '로봇2',
          robot_9: '로봇9',
          floor_1: '1층',
          charging_station: '충전소',
          task_success_rate: '작업 성공률',
          average: '평균',
          number_of_services_by_time_slot: '시간대별 이용 건수 합계',
          most_used_time: '최다 이용 시간',
          hour: '시',
          most_used_robot: '최다 이용 로봇',
          least_used_robot: '최소 이용 로봇',
          most_used_departure_point: '최다 이용 출발지',
          most_popular_destinations: '최다 이용 목적지',
          select_date: '날짜 선택',
          data_download: '데이터 다운로드',
          real_time_robot_operating_status: '실시간 로봇 운영 상태',
          select_robot_type: '로봇 타입 선택',
          last_update: '마지막 업데이트',
          time_required: '소요 시간',
          task_name: '작업명',
          robot_type: '로봇타입',
          robot_name: '로봇명',
          alarm_classification: '알람 구분',
          details: '세부 내용',
          completion_status: '완료 여부',
          status: '상태',
          current_task: '현재 작업',
          general_map: '일반지도',
          satellite_map: '위성지도',
          mixed_map: '혼합지도',
          estimated_work_time: '작업 예상시간',
          second: '초',
          battery_level: '배터리 잔량',
          count: '수',
          time: '시간',
          number_of_cases: '건수',
          error: '에러',
          command: '명령',
          success: '성공',
          failure: '실패',
          work_success_rate: '성공률',
          place: '장소',
          starting_point: '출발지',
          destination: '도착지',
          greet: '안녕하세요',
          account: '계정 관리',
          sign_in: '로그인',
          sign_out: '로그아웃',
          sign_up: '회원가입',
          language: '언어 설정',
          nationwide: '전국',
          nationwide_solink_usage_status: '전국 솔링크 이용 현황',
          site_all: '사이트 전체',
          select_site: '사이트 선택',
          error_types_all: '에러 타입 전체',
          robot_types_all: '로봇 타입 전체',
          robot_name_searching: '로봇명 검색',
          my_page: '마이페이지',
          reset_password: '비밀번호 재설정',
          change_password: '비밀번호 변경',
          marosol: '마로솔',
          email: '이메일',
          password: '비밀번호',
          sign_in_now_and_use_solink: '지금 로그인하시고 솔링크를 이용해보세요',
          are_you_not_a_member_yet: '아직 회원이 아니신가요?',
          start_in_3_seconds_with_simple_sign_in:
            '간편 로그인으로 3초만에 시작해보세요',
          email_or_password_does_not_match:
            '이메일 혹은 비밀번호가 일치하지 않습니다.',
          this_email_is_already_in_use: '이미 사용 중인 이메일입니다.',
          network_connection_failed: '네트워크 연결에 실패 하였습니다.',
          invalid_email_format: '잘못된 이메일 형식입니다.',
          invalid_request: '잘못된 요청입니다.',
          sign_in_failed: '로그인에 실패 하였습니다.',
          this_account_has_not_been_authenticated_by_email:
            '이메일 인증이 완료되지 않은 계정입니다.',
          please_authenticate_your_email_to_use_the_service:
            '서비스 이용을 위해 이메일을 인증해주세요!',
          authenticate: '인증하기',
          managed_sites: '관리 사이트',
          managed_robot: '관리 로봇',
          your_mail_has_been_sent: '메일이 전송되었습니다.',
          today: '오늘',
          yesterday: '어제',
          currentMonth: '이번달',
          pastMonth: '지난달',
          please_select_a_period: '기간을 선택해주세요.',
          the_last: '지난',
          days: '일',
          new: '신규',
          confirm: '확인',
          eight_or_more_digits: '8자리 이상',
          passwords_dont_match: '비밀번호가 일치하지 않습니다',
          at_least_2_of_lowercase_letters_numbers_special_characters:
            '영어 소문자/숫자/특수문자 중 2가지 이상 포함',
          cancel: '취소',
          change: '변경',
          your_password_has_been_changed: '비밀번호가 변경되었습니다.',
          sign_up_and_sexperience_solink: '가입하고 솔링크를 이용해보세요',
          name: '이름',
          nickname: '홍길동',
          registration_completed: '가입 완료',
          registration_pending_approval: '가입 승인 중',
          send_authentication_mail: '인증메일 전송하기',
          to_use_Solink_services_please_verify_your_email:
            '솔링크 서비스 이용을 위해 이메일을 인증해주세요',
          verification_email_has_been_sent_Please_check:
            '인증 메일이 전송되었습니다. 확인해주세요 :)',
          verification_is_complete: '인증이 완료되었습니다',
          now_try_using_solink_services: '이제 솔링크 서비스를 이용해보세요 :)',
          navigate_to_the_solink_homepage: '솔링크 홈으로 이동',
          transmission_complete: '전송완료',
          the_email_is_already_registered: '이미 가입된 이메일입니다.',
          robotCount_robots_are_being_used_in_siteCount_across_the_country:
            '전국 {{siteCount}}개의 사이트에서 {{robotCount}}개의 로봇을 사용하고 있습니다.',
          date: '날짜',
          There_are_no_ongoing_works_at_the_moment:
            '현재 진행중인 작업이 없습니다',
          There_is_no_work_available_on_the_selected_site:
            '선택하신 사이트에는 작업이 존재하지 않습니다',
          Please_choose_a_site: '사이트를 선택해주세요',
          You_can_check_the_work_of_the_selected_site:
            '선택하신 사이트의 작업을 확인할 수 있습니다',
          workflowBuilder: '워크플로우',
          Current_Task: '현재 작업',
          Previous_Task: '이전 작업',
          Start_Time: '시작 시간',
          Elapsed_Time: '경과 시간',
          Recent_Update_Time: '최근 업데이트 시간',
          Elevator: '엘리베이터',
          Door_Opening: '문열림',
          Calling_Floor: '호출층',
          Moving_Floor: '이동층',
          Completed: '완료',
          Failed: '실패',
          No_work_order: '작업지시가 없습니다.',
          It_has_been_stopped: '중지되었습니다.',
          It_has_been_deleted: '삭제되었습니다.',
          Stop_schedule: '스케줄 중지',
          Do_you_want_to_stop_the_schedule: '스케줄을 중지하겠습니까?',
          This_action_cannot_be_undone: '이 작업은 되돌릴 수 없습니다.',
          If_you_wish_to_continue_running_the_schedule_please_press_Continue:
            "스케줄을 계속 실행하길 원하는 경우 '계속 실행'을 눌러주세요.",
          Continue: '계속 실행',
          Stop: '중지',
          Detailed_Information: '상세정보',
          Basic_Information: '기본정보',
          Type: '타입',
          Manufacturer: '제조사',
          Building_Name: '건물명',
          Designation: '명칭',
          Internal_Weight: '내부 중량',
          Data_Update_Time: '데이터 갱신 시간',
          Work_Order: '작업지시',
          Model_Name: '모델명',
          Waiting_Area: '대기장소',
          Charging_Station: '충전장소',
          Robots_Movement_Path: '로봇 이동 경로',
          Video_Call: '영상 통화',
          Currently_engaged_in_another_task: '현재 다른 작업을 수행 중입니다.',
          Task_request_has_been_completed: '작업 요청이 완료되었습니다.',
          Please_select_a_destination: '목적지를 선택하세요',
          Please_select_a_macro: '매크로를 선택하세요',
          Start: '시작',
          The_robot_will_return_to_the_previous_schedule:
            '로봇이 진행하던 스케줄로 복귀합니다.',
          Play_content: '콘텐츠 재생',
          Start_cleaning: '청소 시작',
          No_map_image_available: '지도 이미지가 없습니다.',
          Ongoing_Schedule: '진행중인 스케줄',
          Completed_Schedule: '완료된 스케줄',
          Ongoing_Work: '진행중인 작업',
          Completed_Work: '완료된 작업',
          Schedule_Settings: '스케줄 설정',
          Task_Creation: 'Task 생성',
          There_are_no_completed_works: '완료된 작업이 없습니다',
          The_selected_site_does_not_have_any_completed_works_during_the_specified_period:
            '선택하신 사이트에는 해당 기간동안 완료된 작업이 존재하지 않습니다',
          This_page_does_not_exist: '존재하지 않는 페이지입니다',
          Go_to_the_main_page: '메인페이지로 이동하기',
          Sorry_The_page_address_you_entered_is_incorrect_or_has_been_deleted_and_it_is_not_available_for_use:
            '죄송합니다. 입력하신 페이지 주소가 잘못되었거나 삭제되어 이용하실 수 없습니다.',
          Please_double_check_the_address:
            '주소를 다시 한 번 확인해 주시기 바랍니다.',
          A_temporary_error_has_occurred: '일시적인 오류가 발생했습니다.',
          Sorry_Please_try_again_later:
            '죄송합니다. 잠시 후에 다시 시도해주세요.',
          history: '작업이력',
          Multirobot_Work: '다중로봇 작업',
          Work_Detail: '작업 상세',
          Work_Status: '작업 상태',
          Put_Robot: '투입 로봇',
          Put_Robot_Info: '투입 로봇 정보',
          Device_Name: '디바이스명',
          Current_Status: '현재 상태',
          Execute_Task: '태스크 실행',
          Stop_Task: '태스크 중지',
          Input_Info: '정보 입력',
          No_bookmarks_found: '즐겨찾기가 없습니다.',
          Please_select_a_TASK: '태스크를 선택하세요',
          Save_Error_Log: '에러로그 저장',
          Error_Log_Save_Success: '에러로그를 저장했습니다.',
          Error_Log_Save_Fail: '에러로그 저장에 실패했습니다.',
          home: '홈',
        },
      },
      en: {
        translation: {
          Time: 'Time',
          real_time: 'Real Time',
          dashboard: 'Dashboard',
          robot_operation: 'Robot Operation',
          schedule: 'Schedule',
          bookmark: 'Bookmark',
          notification: 'Notification',
          real_time_solink_usage_status: 'Real-time SOLlink Usage Status',
          last_30_minutes_count: 'Number Of Work Orders In The Last 30 Minutes',
          per_minute_count: 'Number Of Work Orders Per Minute',
          robots_introduced_count: 'Number Of Robots Introduced',
          site: 'Site',
          full_registration_robot: 'Full Registration Robot',
          real_time_view: 'Real-time View',
          unit: 'Unit',
          robot_type_and_registration_status:
            'Robot Type And Registration Status',
          total_cumulative_number_of_services:
            'Total Cumulative Number Of Services',
          case: 'Case',
          total_average_number_of_services: 'Total Average Number Of Services',
          robot_with_the_most_errors: 'Robot With The Most Errors',
          robot: 'Robot',
          robot_1: 'Robot 1',
          robot_2: 'Robot 2',
          robot_9: 'Robot 9',
          floor_1: '1th Floor',
          charging_station: 'Charging Station',
          task_success_rate: 'Task Success Rate',
          average: 'Average',
          number_of_services_by_time_slot: 'Number of Services By Time Slot',
          most_used_time: 'Most Used Time',
          hour: 'Hour',
          most_used_robot: 'Most Used Robot',
          least_used_robot: 'Least Used Robot',
          most_used_departure_point: 'Most Used Departure Point',
          most_popular_destinations: 'Most Popular Destinations',
          select_date: 'Select Date',
          data_download: 'Data Download',
          real_time_robot_operating_status: 'Real-time Robot Operating Status',
          select_robot_type: 'Select Robot Type',
          last_update: 'Last Update',
          time_required: 'Time Required',
          task_name: 'Task Name',
          robot_type: 'Robot Type',
          robot_name: 'Robot Name',
          alarm_classification: 'Alarm Classification',
          details: 'Details',
          completion_status: 'Completion Status',
          status: 'Status',
          current_task: 'Current Task',
          general_map: 'General Map',
          satellite_map: 'Satellite Map',
          mixed_map: 'Mixed Map',
          estimated_work_time: 'Estimated Work Time',
          second: 'Second',
          battery_level: 'Battery Level',
          count: 'Count',
          time: 'Time',
          number_of_cases: 'Number Of Cases',
          error: 'Error',
          command: 'Command',
          success: 'Success',
          failure: 'Failure',
          work_success_rate: 'Work Success Rate',
          place: 'Place',
          starting_point: 'Starting Point',
          destination: 'Destination',
          greet: 'Hello',
          account: 'Account',
          sign_in: 'sign in',
          sign_out: 'Sign out',
          sign_up: 'Sign up',
          language: 'Language',
          nationwide: 'Nationwide',
          nationwide_solink_usage_status: 'Nationwide solink usage status',
          site_all: 'Site All',
          select_site: 'Select Site',
          error_types_all: 'Error type all',
          robot_types_all: 'Robot type all',
          robot_name_searching: 'Robot name searching',
          my_page: 'My Page',
          reset_password: 'Reset Password',
          change_password: 'Change Password',
          marosol: 'Marosol',
          email: 'Email',
          password: 'Password',
          sign_in_now_and_use_solink: 'Sign in now and use Solink',
          are_you_not_a_member_yet: 'Are you not a member yet?',
          start_in_3_seconds_with_simple_sign_in:
            'Start in 3 seconds with simple sign in',
          email_or_password_does_not_match: 'Email or password does not match.',
          this_email_is_already_in_use: 'This email is already in use.',
          network_connection_failed: 'Network connection failed.',
          invalid_email_format: 'Invalid email format.',
          invalid_request: 'Invalid request.',
          sign_in_failed: 'Sign in failed.',
          this_account_has_not_been_authenticated_by_email:
            'This account has not been authenticated by email.',
          please_authenticate_your_email_to_use_the_service:
            'Please authenticate your email to use the service!',
          authenticate: 'Authenticate',
          managed_sites: 'Managed Sites',
          managed_robot: 'Managed Robots',
          your_mail_has_been_sent: 'Your mail has been sent.',
          today: 'Today',
          yesterday: 'Yesterday',
          currentMonth: 'This months',
          pastMonth: 'Last months',
          please_select_a_period: 'Please select a period.',
          the_last: 'last',
          days: 'days',
          new: 'New',
          confirm: 'Confirm',
          eight_or_more_digits: '8 or more digits',
          passwords_dont_match: "Passwords don't match",
          at_least_2_of_lowercase_letters_numbers_special_characters:
            'At least 2 of: lowercase letters, numbers, special characters.',
          cancel: 'Cancel',
          change: 'Change',
          your_password_has_been_changed: 'Your password has been changed.',
          sign_up_and_sexperience_solink: 'Sign up and experience Solink',
          name: 'Name',
          nickname: 'John',
          registration_completed: 'Registration completed',
          registration_pending_approval: 'Registration pending approval',
          send_authentication_mail: 'Send authentication mail',
          to_use_Solink_services_please_verify_your_email:
            'To use Solink services, please verify your email.',
          verification_email_has_been_sent_Please_check:
            'Verification email has been sent. Please check :)',
          verification_is_complete: 'Verification is complete',
          now_try_using_solink_services: 'Now, try using Solink services :)',
          navigate_to_the_solink_homepage: 'Navigate to the Solink homepage',
          transmission_complete: 'Transmission complete',
          the_email_is_already_registered: 'The email is already registered.',
          robotCount_robots_are_being_used_in_siteCount_across_the_country:
            '{{robotCount}}robots are being used in {{siteCount}}across the country',
          date: 'Date',
          There_are_no_ongoing_works_at_the_moment:
            'There are no ongoing works at the moment',
          There_is_no_work_available_on_the_selected_site:
            'There is no work available on the selected site',
          Please_choose_a_site: 'Please choose a site',
          You_can_check_the_work_of_the_selected_site:
            'You can check the work of the selected site',
          workflowBuilder: 'Workflow Builder',
          Current_Task: 'Current task',
          Previous_Task: 'Previous task',
          Start_Time: 'Start time',
          Elapsed_Time: 'Elapsed time',
          Recent_Update_Time: 'Recent update time',
          Door_Opening: 'Door Opening',
          Calling_Floor: 'Calling Floor',
          Moving_Floor: 'Moving Floor',
          Elevator: 'Elevator',
          Completed: 'Completed',
          Failed: 'Failed',
          No_work_order: 'No work order',
          It_has_been_stopped: 'It has been stopped.',
          It_has_been_deleted: 'It has been deleted',
          Stop_schedule: 'Stop schedule',
          Do_you_want_to_stop_the_schedule: 'Do you want to stop the schedule?',
          This_action_cannot_be_undone: 'This action cannot be undone.',
          If_you_wish_to_continue_running_the_schedule_please_press_Continue:
            "If you wish to continue running the schedule, please press 'Continue'.",
          Continue: 'Continue',
          Stop: 'Stop',
          Detailed_Information: 'Detailed Information',
          Basic_Information: 'Basic Information',
          Type: 'Type',
          Manufacturer: 'Manufacturer',
          Building_Name: 'Building Name',
          Designation: 'Designation',
          Work_Order: 'Work Order',
          Internal_Weight: 'Internal Weight',
          Data_Update_Time: 'Data Update Time',
          Model_Name: 'Model Name',
          Waiting_Area: 'Waiting Area',
          Charging_Station: 'Charging Station',
          Robots_Movement_Path: "Robot's Movement Path",
          Video_Call: 'Video Call',
          Currently_engaged_in_another_task:
            'Currently engaged in another task.',
          Task_request_has_been_completed: 'Task request has been completed.',
          Please_select_a_destination: 'Please select a destination',
          Please_select_a_macro: 'Please select a macro',
          Start: 'Start',
          The_robot_will_return_to_the_previous_schedule:
            'The robot will return to the previous schedule.',
          Play_content: 'Play content',
          Start_cleaning: 'Start cleaning',
          No_map_image_available: 'No map image available.',
          Ongoing_Schedule: 'Ongoing Schedule',
          Completed_Schedule: 'Completed Schedule',
          Ongoing_Work: 'Ongoing Work',
          Completed_Work: 'Completed Work',
          Schedule_Settings: 'Schedule Settings',
          Task_Creation: 'Task Creation',
          There_are_no_completed_works: 'There are no completed works',
          The_selected_site_does_not_have_any_completed_works_during_the_specified_period:
            'The selected site does not have any completed works during the specified period',
          This_page_does_not_exist: 'This page does not exist',
          Go_to_the_main_page: 'Go to the main page',
          Sorry_The_page_address_you_entered_is_incorrect_or_has_been_deleted_and_it_is_not_available_for_use:
            'Sorry, The page address you entered is incorrect or has been deleted, and it is not available for use.',
          Please_double_check_the_address: 'Please double-check the address.',
          Unable_to_load_data: 'Unable to load data',
          Reload: 'Reload',
          A_temporary_error_has_occurred: 'A temporary error has occurred.',
          Sorry_Please_try_again_later: 'Sorry, Please try again later.',
          history: 'history',
          Multirobot_Work: 'Multirobot work',
          Work_Detail: 'Work Detail',
          Work_Status: 'Work Status',
          Put_Robot: 'Put Robots',
          Put_Robot_Info: 'Put Robots Info',
          Device_Name: 'Device Name',
          Current_Status: 'Current Status',
          Execute_Task: 'Execute Task',
          Stop_Task: 'Stop Task',
          Input_Info: 'Input Info',
          No_bookmarks_found: 'No Bookmarks Found.',
          Please_select_a_TASK: 'Please Select a Task',
          Save_Error_Log: 'Save Error Log',
          Error_Log_Save_Success: 'Saved the error log.',
          Error_Log_Save_Fail: 'Failed to save the error log.',
          home: 'HOME',
        },
      },
    },
  });
