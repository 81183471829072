import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import { FaArrowLeft, FaYoutube } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import builder_page1 from '../../../../assets/introduce/Image/builder_page1.gif'
import builder_page2 from '../../../../assets/introduce/Image/builder_page2.gif'
import { Icon } from '../../../../pages/IntroducePage';
import builderPage1_title from '../../../../assets/introduce/Image/Modal/Builder/builderPage1_title.png'
import builderPage1_desc from '../../../../assets/introduce/Image/Modal/Builder/builderPage1_desc.png'
import builderPage1_subTitle from '../../../../assets/introduce/Image/Modal/Builder/builderPage1_subTitle.png'
import builderPage2_title from '../../../../assets/introduce/Image/Modal/Builder/builderPage2_title.png'
import builderPage2_desc from '../../../../assets/introduce/Image/Modal/Builder/builderPage2_desc.png'
import builderPage2_subTitle from '../../../../assets/introduce/Image/Modal/Builder/builderPage2_subTitle.png'
import builderModalBg from '../../../../assets/introduce/Image/builderModalBg.png'
import styled from "styled-components";

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ModalBackBtn className="next" onClick={onClick}>
      <FaChevronRight className={'CMobileFaChevronRight'}/>
    </ModalBackBtn>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ModalBackBtn className="prev" onClick={onClick}>
      <FaChevronLeft className={'CMobileFaChevronLeft'}/>
    </ModalBackBtn>
  );
}

export const MobileBuilderModal = ({ setClicked }: {setClicked: Function}) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (event.deltaY > 0 && sliderRef.current) {
      sliderRef.current.slickNext();
    } else if (event.deltaY < 0 && sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <ModalWrapper className="slider-container">
      <ModalProgressBar
        onChange={(e) => sliderRef.current?.slickGoTo(parseInt(e.target.value))}
        value={slideIndex}
        type="range"
        min={0}
        max={1}
      />
      <LinkerModalContent className='builder'>
        <ModalHeader>
          <ModalBackBtn onClick={() => setClicked('')}>
            <FaArrowLeft className={'CMobileFaArrowLeft'}/>
          </ModalBackBtn>
        </ModalHeader>
        <Slider
          ref={sliderRef}
          {...settings}
        >
          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='builder_page1'>
                <BuilderModalBadge>Workflow Builder</BuilderModalBadge>
                <BuilerTitleImg src={builderPage1_title} />
              </LinkerModalTitle>
              <LinkerModalDesc className='builder_page1'>
                <BuilderDescImg src={builderPage1_desc} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <PlatformModalBottom className='builder_page1'>
              <BuilderSubtitleImg src={builderPage1_subTitle} />
            </PlatformModalBottom>
            <EngineGifWrapper className='builder_page1'>
              <BuilderGif src={builder_page1} />
              <a href='https://www.youtube.com/watch?v=IdclecFv2PU' target='blank'><Icon><FaYoutube /></Icon>Workflow Builder 기능 상세보기</a>
            </EngineGifWrapper>
          </LinkerModalDisplay>

          <LinkerModalDisplay>
            <LinkerModalTitleArea>
              <LinkerModalTitle className='builder_page2'>
                <BuilderModalBadge>Workflow Builder</BuilderModalBadge>
                <BuilerTitleTwoImg src={builderPage2_title} />
              </LinkerModalTitle>
              <LinkerModalDesc className='builder_page2'>
                <BuilderDescTwoImg src={builderPage2_desc} />
                <BuilderSubtitleTwoImg src={builderPage2_subTitle} />
              </LinkerModalDesc>
            </LinkerModalTitleArea>
            <EngineGifWrapper className='builder_page2'>
              <BuilderTwoGif src={builder_page2} />
            </EngineGifWrapper>
          </LinkerModalDisplay>
        </Slider>
      </LinkerModalContent>
    </ModalWrapper>
  );
}

export default React.memo(MobileBuilderModal)

const BuilerTitleImg = styled.img`
  width: 77.21vw;
  margin-top: 3.91vw;
`

const BuilderDescImg = styled.img`
  width: 49.61vw;
`

const BuilderSubtitleImg = styled.img`
  width: 74.87vw;
`

const BuilderGif = styled.img`
  width: 89.19vw;
  margin-top: 5.21vw;
`

const BuilerTitleTwoImg = styled.img`
  width: 57.29vw;
  margin-top: 3.91vw;
`

const BuilderDescTwoImg = styled.img`
  width: 80.21vw;
`

const BuilderSubtitleTwoImg = styled.img`
  width: 71.93vw;
  margin-top: 3.13vw;
`


const BuilderTwoGif = styled.img`
  width: 89.19vw;
  margin-top: 5.21vw;
`




const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  min-width: 350px;
  overflow: hidden;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const ModalProgressBar = styled.input`
  position: absolute;
  top: 5.98958vw;
  right: 22.1354vw;
  z-index: 9999;
  width: 6.51042vw;
  height: 2.1875vw;
  
`
const ModalBackBtn = styled.div`
  position: absolute;
  z-index: 9999;
  color: #fff;
  cursor: pointer;
  width: 6.51042vw;
  height: 6.51042vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0.06) 100%);
  top: 0;


  &.next {
    top: -5.59948vw;
    right: 0;
  }

  &.prev {
    top: -5.59948vw;
    right: 7.8125vw;
  }
`

const LinkerModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 3.90625vw 5.20833vw;
  display: flex !important;
  flex-direction: column;
  gap: 5.85938vw;
  background-color: #000;
  
  &.builder {
    background-image: url(${builderModalBg});
  }
  
`
const LinkerModalDisplay = styled.div`

`
const LinkerModalTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

`
const LinkerModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90.625vw;
  width: auto;
  margin-top: 4.40495vw;

`
const LinkerModalDesc = styled.p`
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  white-space: nowrap;
  margin-top: 36px;
  margin-bottom: 25px;
  
  
  &.builder_page2{
    margin-top: 26px;
    margin-bottom: 0;
    
  }
  
`

const PlatformModalBottom = styled.p`
  
`

const EngineGifWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  


  
  a {
    color: #fff;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    gap: 0.78125vw;
    font-size: 2.34375vw;
    line-height: 140%;
  }
  
  
`

const BuilderModalBadge = styled.div`
  width: 31.77vw;
  height: 5.72917vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 0.0651vw solid var(rgba(255, 255, 255, 0.20));
  background: radial-gradient(71.59% 71.59% at 50.61% 100%, rgba(125, 255, 64, 0.72) 0%, rgba(125, 255, 64, 0.20) 55.21%, rgba(125, 255, 64, 0.00) 100%), rgba(255, 255, 255, 0.08);
  color: #FFF;
  text-shadow: 0px 0px 5.20833vw #FFF;
  font-size: 3.64583vw;
  font-weight: 700;
  letter-spacing: -0.0729vw;
  
`

