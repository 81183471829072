import { create } from "zustand";

interface DataStore {
  robotDetailData: any;
  updateRobotDetailData: (inputData: any) => void;
}

const useDataStore = create<DataStore>()((set) => ({
  robotDetailData: null,
  updateRobotDetailData: (inputData) =>
    set((state) => ({
      robotDetailData: inputData,
    })),
}));

export default useDataStore;
