import { useCallback } from "react";
import loadExternalJS from "../../../util/loadExternalJS";

const DasomVideo = () => {
  const callHandler = useCallback(() => {
    loadExternalJS();
  }, []);
  return (
    <div className="video-connection homecare-robot">
      <div className="content-container">
        <div className="video-screen">
          <div className="title-container">
            <div className="mrs-logo"></div>
            <div className="title-box">
              <h1>마로솔 통합 관제시스템</h1>
              <p>Bigwave Robotics Inc. All Rights Reserved</p>
            </div>
          </div>
          <div className="calling-container">
            <div className="calling-title-box">
              <h1>화상통화가 시작됩니다</h1>
              <p>연결이 시작되기 전 마이크와 카메라를 확인해주세요</p>
            </div>
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
            <div className="user-profile-box">
              <div>다솜이</div>
              <span>다솜이</span>
            </div>
          </div>
          <div className="called-container">
            <div className="called-title-box">
              <h1>통화가 종료되었습니다</h1>
            </div>
            <div className="none-user-profile-box">
              <div>다솜이</div>
              <span>다솜이</span>
            </div>
          </div>
          <video
            id="mainVideo"
            className="frames__main_v qb-video_source"
            autoPlay
            playsInline
          />
          <div className="pip">
            <video
              id="localVideo"
              className="frames__main_v qb-video_source"
              autoPlay
              playsInline
            />
          </div>
          <div className="btn btn-container">
            <div className="lds-grid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <button
              id="callSelf"
              className="btn-call connection"
              onClick={callHandler}
            >
              <i></i>
              <span>연결</span>
            </button>
            <button id="closeSelf" className="btn-call disconnect">
              <i></i>
              <span>끊기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DasomVideo;
